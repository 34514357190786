<div class="forecast-catalog-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addCatalog
            [width]="850"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #catalogForm
               id="catalogForm"
               validationGroup="clockData"
               [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)"
               colCount="auto"
               [(formData)]="model"
               [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="description" editorType="dxTextArea" [height]="120">
              <dxo-label text="{{ 'SHARED.DESCRIPTION' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.DESCRIPTION' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="auctionId"
                      editorType="dxSelectBox"
                      [editorOptions]="{
              items: auctions,
              valueExpr:'auctionId',
              displayExpr:'name'}">
              <dxo-label text="{{ 'SHARED.AUCTION' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'CATALOG.AUCTION_VALIDATION' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="productId"
                      editorType="dxSelectBox"
                      [editorOptions]="{
                items: products,
                valueExpr:'productId',
                displayExpr:'productNameText'}">
              <dxo-label text="{{ 'SHARED.PRODUCT' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.PRODUCT' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
          </dxi-tab>
        </dxi-item>
      </dx-form>
      <br /><br />
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnCatalogSubmit" disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
