<div class="lot-multi-edit-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-select-box name="{{ name }}"
                 [dataSource]="dataSource"
                 valueExpr="value"
                 searchTimeout="200"
                 #masterDataDropdown
                 [(value)]="selectedRowId"
                 displayExpr="label"
                 searchMode="Contains"
                 [searchEnabled]="true"
                 [showClearButton]="true"
                 (onFocusOut)="focusOut($event);"
                 (onFocusIn)="focusIn($event)"
                 [tabIndex]="tabIndex"
                 (onValueChanged)="valueChanged($event)"
                 (onClear)="selectedRowId = null"
                 placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                 class="filter-lot-select focusable">
    <dx-validator *ngIf="required">
      <dxi-validation-rule type="required" message=""></dxi-validation-rule>
    </dx-validator>
  </dx-select-box>
  <span class="tooltip master-data-tooltip" *ngIf="(masterDataDropdown.touched || masterDataDropdown.dirty) && masterDataDropdown.invalid">
    <clr-tooltip>
      <clr-icon shape="exclamation-circle" class="is-error" size="22"></clr-icon>
      <!--<clr-tooltip-content class="tooltip-content" clrPosition="bottom-left" clrSize="md">
        <span *ngIf="masterDataErrors[i]!==''">{{masterDataErrors[i]}}</span>
        <span *ngIf="!masterDataErrors[i] || masterDataErrors[i]===''">{{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}</span>
      </clr-tooltip-content>-->
    </clr-tooltip>
  </span>
 </div>
