import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

// general services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { WebApiService } from '../../shared/services/web-api.service';

// components
import { ContentComponent } from '../../shared/content/content.component';

// services
import { AuctionClusterService } from '../../buyer/shared/services/auction-cluster.service';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';
import { ApplicationSettings } from '../../shared/models/application-settings';

@Component({
  selector: 'buyer-content.component',
  templateUrl: './buyer-content.component.html',
  styleUrls: ['./buyer-content.component.scss']
})
export class BuyerContentComponent extends ContentComponent implements OnInit, OnDestroy {

  auctionClusterService: AuctionClusterService;

  sidebarLogo = null;
  myRoute: any;
  navigationSubscription: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    protected appSettings: ApplicationSettings,
    route: ActivatedRoute,
    protected webApiService: WebApiService
  ) {
    super(injector);
    this.getServices(route, appSettings, webApiService);

    this.myRoute = route;
    //this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //  // If it is a NavigationEnd event re-initalise the component
    //  if (e instanceof NavigationEnd) {
    //    this.initialise();
    //  }
    //});

    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  initialise(){
    this.auctionClusterService.getAuctionClustersPerLevel().subscribe(acs => {
      this.sidebarLogo = null;
      if (acs && acs.length > 0) {
        localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }

      const acImage = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');

      if (acImage) {
        this.sidebarLogo = acImage;
      }
    });
  }

  ngOnInit() {
    this.initialise();

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined) {
          result.forEach(item => {
              if (item.accessLevels.length > 0) {
                  item.accessLevels.forEach(_ => {
                      if (_ === AccessLevel.BUYER) {
                          if (item.submenuId !== null) {
                              this.subMenuItems.push(item);
                          } else this.menuItems.push(item);
                      }
                  })
              }
          })

          this.menuItems.sort((a, b) => {
              if (a.orderNumber < b.orderNumber) {
                  return -1;
              }
              if (a.orderNumber > b.orderNumber) {
                  return 1;
              }
              return 0;
          })

          this.subMenuItems.sort((a, b) => {
              if (a.orderNumber < b.orderNumber) {
                  return -1;
              }
              if (a.orderNumber > b.orderNumber) {
                  return 1;
              }
              return 0;
          })
      }
  },
      error => {
          this.errorService.show(error);
      });
}

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialise()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
 }
