import { Auction } from "../../../auction/shared/models/auction";
import { AuctionClusterLanguage } from "../../../shared/models/language";
import { MasterDataGridSelection } from "../../../shared/models/masterdatagridselection";

export class AuctionCluster {
  auctionClusterId: number;
  name: string;
  administratorId: number;
  image: string;
  webServiceUrl: string;
  isActive: boolean;
  currencyId: number;
  currencyFactor: number;
  defaultAuctioneerProfileId: number;
  defaultUserProfileId: number;
  supplierBuyerId: number;
  financiallyResponsible: number;
  userLicenses: number;
  auctions: Array<number> = [];
  buyers: Array<number> = [];
  suppliers: Array<number> = [];
  locations: Array<number> = [];
  products: Array<number> = [];
  auction: Array<Auction> = [];
  languages: Array<AuctionClusterLanguage> = [];
  masterDataGridSelections: Array<MasterDataGridSelection>;
  auctionClusterDefaultLanguage: number;
  mfaTypeId: number;
  timeZone: string;
  masterDataList: Array<any> = [];
  secondRestrictionMasterDataId: number;
  buyerSecondRestrictionMasterDataRowIds: Array<number> = [];
  code: string;
  sampleRate: number;
  channelCount: number;
  bitRate: number;
  minimumBufferSize: number;
  maximumBufferSize: number;
  frameDuration: number;
}
