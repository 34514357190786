import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductService } from '../../../shared/interfaces/product';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { BuyerProduct } from '../models/buyer-product';
import { GridProperty, ProductProperty } from '../../../auction/shared/models/product';

@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;
  private buyerId: number;

  setId(buyerId: number)
  {
    this.buyerId = buyerId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getProductsClocks(): Observable<Array<BuyerProduct>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/product/clock`);
  }

  getPrebidOnProductProperties(auctionClusterId: number, productId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/product/${productId}/prebidonproductproperties`);
  }

  //IProductService

  getProductForReports(auctionClusterId: number, productId: number)
  {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }

  getProductProperties(productId: number): Observable<Array<ProductProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/productproperties`);
  }

  getReportProperties(productId: number, reportDesignId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/reportproperties/${reportDesignId}`);
  }
}
