import { Injectable } from "@angular/core";
import { DateTimeAdapter } from "ng-pick-datetime";

export const CUSTOM_TIME_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'L',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11ylabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
