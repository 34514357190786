import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuyerContentComponent } from './content/buyer-content.component';
import { BuyerHomeComponent } from './home/buyer-home.component';
import { BuyerClocksComponent } from './clocks/buyer-clocks.component';

import { AuthGuard } from '../shared/services/auth-guard.service';
import { BrowserGuard } from '../shared/services/browser-guard.service';
//import { ReportComponent } from '../shared/components/reporting/report.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ReportExportComponent } from './reports/report-export.component';
import { BuyerUsersComponent } from './users/buyer-users.component';
import { BuyerPrebidsComponent } from './prebid/buyer-prebids.component';
import { BuyerPrebidLotsComponent } from './prebid/buyer-prebid-lots.component';
import { ShoppingListsComponent } from './shopping-lists/shopping-lists.component';
import { ShoppingListLotsComponent } from './shopping-lists/shopping-list-lots.component';
import { PublicSupplyForecastComponent } from '../shared/components/public-supply-forecast/public-supply-forecast.component';
import { BuyerSupplyMonitorComponent } from './supply-monitor/buyer-supply-monitor.component';
import { BuyerSupplyMatrixComponent } from './supply-matrix/buyer-supply-matrix.component';
import { PublicSupplyForecastURKComponent } from '../shared/components/public-supply-forecast-urk/public-supply-forecast-urk.component';
import { AdditionalMenuItemsContainerComponent } from '../shared/components/additional-menu-items-container/additional-menu-items-container.component';
import { BuyerTransactionMonitorComponent } from './transaction-monitor/buyer-transaction-monitor.component';


const appRoutes: Routes = [
  {
    path: 'buyer',
    component: BuyerContentComponent,
    canActivateChild: [AuthGuard, BrowserGuard],
    data: { level: 'buyer' },
    children: [
      {
        path: 'clocks/:id',
        component: BuyerClocksComponent,
        data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.4', 'BuyerPermissions.6'] },
        runGuardsAndResolvers: 'paramsChange'
      },
      {
        path: 'reports/:id',
        children: [
          {
            path: '',
            component: ReportsComponent,
            data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
          },
          {
            path: 'ac/:auctionClusterId/report/:reportId',
            component: ReportEditorComponent,
            data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
          },
          {
            path: 'ac/:auctionClusterId/report/:reportId/export',
            component: ReportExportComponent,
            data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
          }
        ],
        data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
      },
      {
        path: 'users/:id',
        component: BuyerUsersComponent,
        data: { permission: ['BuyerPermissions.4'] }
      },
      {
        path: 'supplyforecast/:id',
        component: PublicSupplyForecastURKComponent
      },
      {
        path: 'prebids/:id',
        children: [
          {
            path: '',
            component: BuyerPrebidsComponent
          },
          {
            path: 'lots',
            children: [
              { path: ':catalogId', component: BuyerPrebidLotsComponent }
            ]
          }
        ]
      },
      {
        path: 'shoppinglists/:id',
        children: [
          {
            path: '',
            component: ShoppingListsComponent,
          },
          {
            path: 'lots/:catalogId',
            component: ShoppingListLotsComponent
          }
        ]
      },
      {
        path: 'supplymonitor/:id',
        component: BuyerSupplyMonitorComponent,
        data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
      },
      {
        path: 'transactionmonitor/:id',
        component: BuyerTransactionMonitorComponent,
        data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
      },
      {
        path: 'supplymatrix/:id',
        component: BuyerSupplyMatrixComponent,
        data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
      },
      {
        path: ':id/menu-item/:menuId', component: AdditionalMenuItemsContainerComponent
      }

      /*{
        path: 'reporting/:id/product/:productId/report/:reportId', component: ReportComponent, data: { permission: ['BuyerPermissions.1', 'BuyerPermissions.2', 'BuyerPermissions.3', 'BuyerPermissions.4'] }
      },*/
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class BuyerRoutingModule { }
