import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ProductBuyButton } from '../models/product';

@Injectable()
export class ProductBuyButtonService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'product';
  }

  getProductBuyButtons(productId: number): Observable<Array<ProductBuyButton>> {
    return this.webApiService.getList(this.apiPath + '/' + productId + '/productbuybutton');
  }

  getProductBuyButton(productId: number, buyerId: number): Observable<ProductBuyButton> {
    return this.webApiService.get(this.apiPath + '/' + productId + '/productbuybutton', buyerId);
  }

  save(buyButton: ProductBuyButton): Observable<any> {
    return this.webApiService.save<ProductBuyButton>(this.apiPath + '/' + buyButton.productId + '/productbuybutton', buyButton);
  }

  edit(buyButton: ProductBuyButton): Observable<any> {
    return this.webApiService.edit<ProductBuyButton>(this.apiPath + '/' + buyButton.productId + '/productbuybutton', buyButton.productBuyButtonId, buyButton);
  }

  delete(productId: number, productBuyButtonId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + productId + '/productbuybutton', productBuyButtonId);
  }
}
