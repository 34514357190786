import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../shared/services/title.service';
import { ErrorService } from '../../shared/services/error.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'account-not-found-component',
  templateUrl: 'account-not-found.component.html',
  styleUrls: ['./account-not-found.component.scss']
})
export class AccountNotFoundComponent implements OnInit {

  constructor(private titleService: TitleService,
    private errorService: ErrorService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.titleService.set('HOME.TITLE');

    this.translate.get('ERRORS.ACCOUNT_NOT_FOUND').subscribe((res: string) => {
      this.errorService.show(res);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('ERRORS.ACCOUNT_NOT_FOUND').subscribe((res: string) => {
        this.errorService.show(res);
      });
    });
  }
}
