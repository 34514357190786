import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { MasterData, MasterDataListField, MasterDataValue, MasterDataRow, FieldType } from '../../shared/models/master-data';
import { IMasterDataService } from '../../../shared/interfaces/master-data';


@Injectable()
export class MasterDataService implements IMasterDataService {
  masterDataLists: Array<MasterData> = [];
  masterDataListFields: Array<MasterDataListField> = [];

  private apiPath: string;
  private supplierId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  setId(id: number){
    this.supplierId = id;
  }

  getMasterDataList(masterDataListId: number, supplierId: number, auctionClusterId: number): Observable<MasterData> {
    const url = this.apiPath + '/' + supplierId + '/auctioncluster/' + auctionClusterId + '/masterdatalist/' + masterDataListId;
    return this.webApiService.getList(url);
  }

  getMasterDataForReports(auctionClusterId: number, masterDataListId: number, includeRows: boolean): Observable<MasterData> {
    var url = this.apiPath + '/' + this.supplierId + '/auctioncluster/' + auctionClusterId + '/masterdatalist/' + masterDataListId ;
    if (includeRows) {
      url += '/reports';
    }
    else {
      url += '/withoutrows';
    }
    return this.webApiService.getList(url);
  }
}
