import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrTabs } from '@clr/angular';
import { DxPopupComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { ApplicationSettings } from '../../shared/models/application-settings';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { ClockConfigInfo } from '../shared/models/clock-config-info';

// models
import { NetworkLatencyTiming } from '../shared/models/network-latency-timing';
import { SystemReportDesignTypes } from '../../shared/models/report';

// services
import { LineMonitorService } from '../shared/services/line-monitor.service';
import { AuctionClusterReportService } from '../shared/services/report.service';

// Refresh interval for connected users in ms
//const refreshInterval = 5000;

@Component({
  selector: 'line-monitor-component',
  templateUrl: 'line-monitor.component.html',
  styleUrls: ['./line-monitor.component.scss']
})
export class LineMonitorComponent extends ItemDetailsComponent<NetworkLatencyTiming> implements OnInit, OnDestroy {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  private auctionClusterId: number;
  private clockId: number;
  private intervalId: any;
  private latencyValueExcellent = 150;
  private latencyValueAverage = 200;
  private latencyValuePoor = 250;
  private latencyValueBad = 300;
  pageSize = 20;
  reportId = 0;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  private _clockConfigSubscription: Subscription;
  private _networkLatencyUpdateSubscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private lineMonitorService: LineMonitorService,
    private route: ActivatedRoute,
    private router: Router,
    private reportService: AuctionClusterReportService,
    private appSettings: ApplicationSettings,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new NetworkLatencyTiming();

    this._clockConfigSubscription = this.lineMonitorService.clockConfigInfo.subscribe((config: ClockConfigInfo) => {
      if (this.clockId !== config.clockId) {
        return;
      }

      this.latencyValueExcellent = config.connectionValues.excellent;
      this.latencyValueAverage = config.connectionValues.average;
      this.latencyValuePoor = config.connectionValues.poor;
      this.latencyValueBad = config.connectionValues.bad;
    });

    this._networkLatencyUpdateSubscription = this.lineMonitorService.networkLatencyUpdate.subscribe((latencies: NetworkLatencyTiming) => {
      if (this.clockId !== latencies.clockId) {
        return;
      }

      this.model = latencies;
    });

    this.auctionClusterId = +this.route.snapshot.params['id'];

    // get line monitor report design id
    this.getReportDesignId();
  }

  ngOnDestroy() {
    if (this._subscription)
      this._subscription.unsubscribe();

    if (this._clockConfigSubscription)
      this._clockConfigSubscription.unsubscribe();

    if (this._networkLatencyUpdateSubscription)
      this._networkLatencyUpdateSubscription.unsubscribe();

    this.lineMonitorService.stop();
  }

  open(clockId: number) {
    this.clockId = clockId;
    this.model = new NetworkLatencyTiming();

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.isEditMode = false;
    this.isOpened = true;

    this.lineMonitorService.init(this.auctionClusterId).subscribe(_ => {
      this.lineMonitorService.start(this.clockId);      
    });

    //this.intervalId = setInterval(() => this.lineMonitorService.refreshNetworkLatencies(this.clockId), refreshInterval);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() { } // tslint:disable-line:no-empty

  onCancel() {
    clearInterval(this.intervalId);
    super.onCancel();
  }

  onHidden() {
    // Close line monitor connection on popup close
    this.lineMonitorService.stop();
  }

  kickOutLineMonitorBuyer = (e: any) => {
    this.lineMonitorService.kickOutLineMonitorBuyer(this.clockId, e.row.data.buyerId != null ? e.row.data.buyerId : 0, e.row.data.userId);
  }

  getReportDesignId() {
    this.reportService.getReportBySystemReportDesignType(this.auctionClusterId, SystemReportDesignTypes.LineTimeReport).subscribe(report => {
      this.reportId = report.reportId;
    });
  }

  openReport = (e: any) => {
    let url = '/#/auction/reports/' + this.auctionClusterId + '/report/' + this.reportId + '?clockId=' + this.clockId + '&userId=' + e.row.data.userId;
    window.open(url, '_blank');
  }

  isExcellent(networkLatencyTiming) {
    if (networkLatencyTiming <= this.latencyValueExcellent) {
      return true;
    }
    return false;
  }

  isAverage(networkLatencyTiming) {
    if (networkLatencyTiming > this.latencyValueExcellent && networkLatencyTiming <= this.latencyValuePoor) {
      return true;
    }
    return false;
  }

  isPoor(networkLatencyTiming) {
    if (networkLatencyTiming > this.latencyValuePoor && networkLatencyTiming <= this.latencyValueBad) {
      return true;
    }
    return false;
  }

  isBad(networkLatencyTiming) {
    if (networkLatencyTiming > this.latencyValueBad) {
      return true;
    }
    return false;
  }
}
