import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';

// models
import { Language } from '../../shared/models/language';
import { SubBuyerComponent } from './sub-buyer.component';
import { AuctionClusterBuyer, SubBuyer, SubBuyerSelection } from '../shared/models/auction-cluster-buyer';

@Component({
  selector: 'sub-buyers-component',
  templateUrl: 'sub-buyers.component.html'
})
export class SubBuyersComponent extends SimpleListComponent implements OnInit {

  items: Array<SubBuyer> = [];
  buyers: Array<AuctionClusterBuyer> = [];
  filteredBuyers: Array<AuctionClusterBuyer> = [];
  languages: Array<Language> = [];
  editingIndex: number;
  pageSize = 50;

  @Input('buyers')
  set buyer(value: Array<AuctionClusterBuyer>) {
    this.buyers = value;
    this.filteredBuyers = this.buyers;
    this.matchSubBuyers();
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Input('data')
  set data(value: Array<SubBuyer>) {
    this.items = value;
    this.matchSubBuyers();
  }
  get data(): Array<SubBuyer> {
    return this.items;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(SubBuyerComponent) detailsComponent: SubBuyerComponent;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('BUYERS');
  }

  add() {
    this.filterBuyersAdd();
    this.detailsComponent.modalTitle = this.translations.SUB_BUYER;
    this.detailsComponent.open(this.items, this.filteredBuyers, new SubBuyer(), this.languages);
  }

  editSubBuyer(subBuyer: SubBuyer, event: Event) {
    this.filterBuyersEdit(subBuyer.buyerId);
    this.detailsComponent.modalTitle = this.translations.SUB_BUYER;
    const clone = { ...subBuyer };
    this.detailsComponent.open(this.items, this.filteredBuyers, clone, this.languages);
  }

  onSubBuyerAdded(subBuyer: SubBuyer) {
    this.items.push(subBuyer);
    this.matchSubBuyers();
    this.filterBuyersAdd();
    this.dataChanged.emit();
  }

  onSubBuyerUpdated(subBuyer: SubBuyer) {
    const updatedIndex = this.items.findIndex(sb => sb.buyerId === subBuyer.buyerId);
    this.items[updatedIndex] = subBuyer;
    this.matchSubBuyers();
    this.dataChanged.emit();
  }

  matchSubBuyers() {
    if (this.items) {
      this.items.forEach(subBuyer => {
        const buyer = this.buyers.find(b => b.buyerId === subBuyer.buyerId);
        subBuyer.name = buyer.name;
      });
    }
  }

  filterBuyersAdd() {
    // if (this.buyers) {
    //   this.filteredBuyers = this.buyers.map((currElem, index, arr) => {
    //     if (this.items.findIndex(sb => sb.buyerId === currElem.buyerId) !== -1) {
    //       return null;
    //     } else {
    //       return currElem;
    //     }
    //   }).filter(fb => fb !== null);
    // }
    this.filteredBuyers = this.buyers;
  }

  filterBuyersEdit(buyerId: number) {
    // if (this.buyers) {
    //   this.filteredBuyers = this.buyers.map((currElem, index, arr) => {
    //     if (currElem.buyerId !== buyerId && this.items.findIndex(sb => sb.buyerId === currElem.buyerId) !== -1) {
    //       return null;
    //     } else {
    //       return currElem;
    //     }
    //   }).filter(fb => fb !== null);
    // }
    this.filteredBuyers = this.buyers;
  }

  delete(subBuyer: SubBuyer) {
    this.items.splice(this.items.findIndex(sb => sb.orderNumber === subBuyer.orderNumber), 1);
    this.dataChanged.emit();
  }
}
