import { Component, Injector, OnInit } from '@angular/core';
import { TitleService } from '../../shared/services/title.service';
import { AuthService } from '../../shared/services/auth.service';
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';
import { AdditionalMenuItemsService } from '../../shared/services/additional-menu-items.service';
import { ErrorService } from '../../shared/services/error.service';

@Component({
    selector: 'home-component',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  menuItems: Array<AdditionalMenuItem> = [];

  additionalMenuItemsService: AdditionalMenuItemsService;
  public errorService: ErrorService;

  constructor(private titleService: TitleService,
    private authenticationService: AuthService,
    private injector: Injector) { 
      this.additionalMenuItemsService = this.injector.get(AdditionalMenuItemsService);
      this.errorService = this.injector.get(ErrorService);
    }

  ngOnInit() {
    this.titleService.set('HOME.TITLE');

    if (!this.authenticationService.useAuth0) {
      this.getMenuItemsAndNavigateToStartPage();
    }
  }

  getMenuItemsAndNavigateToStartPage() {
    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {
      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.PUBLIC) {
                if (item.submenuId === null) {
                  this.menuItems.push(item);
                } 
              }
            })
          }
        })
      }

      let startPageApp = this.menuItems.find(_ => _.setAsStartPage);
      let startPageRoute;
      if (startPageApp) {
        startPageRoute = '/core/menu-item/' + startPageApp.externalAppId;
      } else {
        startPageRoute = '/core/login';
      }

      this.authenticationService.goToStartPage(startPageRoute);
    },
      error => {
        this.errorService.show(error);
      });
  }
}
