import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

import { UserReportTemplate } from '../models/user-report-template';

@Injectable()
export class UserReportTemplateService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getAll(productId: number, reportId: number): Observable<Array<UserReportTemplate>> {
    return this.webApiService.getList(this.apiPath + 'user/userreporttemplate/product/' + productId + '/report/' + reportId);
  }

  create(userReportTemplate: UserReportTemplate): Observable<UserReportTemplate> {
    return this.webApiService.save(this.apiPath + 'user/userreporttemplate/', userReportTemplate);
  }

  edit(userReportTemplate: UserReportTemplate): Observable<UserReportTemplate> {
    return this.webApiService.editSingle(this.apiPath + 'user/userreporttemplate/', userReportTemplate);
  }

  delete(userReportTemplateId: number) {
    return this.webApiService.delete(this.apiPath + 'user/userreporttemplate', userReportTemplateId);
  }
}
