import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '../models/location';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class LocationService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  getLocations(supplierId: number, auctionclusterId: number): Observable<Array<Location>> {
    return this.webApiService.getList(this.apiPath + '/' + supplierId + '/auctioncluster/' + auctionclusterId + '/location');
  }
}
