import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';
import { SupplierRole } from '../models/supplier-role';

@Injectable()
export class SupplierRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierrole';
  }

  getSupplierRoles(): Observable<Array<SupplierRole>> {
    return this.webApiService.getList(this.apiPath);
  }

  getSupplierRolesForAuctionCluster(auctionClusterId: number): Observable<Array<SupplierRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'auctioncluster/' + auctionClusterId + '/supplierrole');
  }

  getSupplierRolesForAuctionClusterSupplier(auctionClusterId: number, supplierId: number): Observable<Array<SupplierRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'auctioncluster/' + auctionClusterId + `/supplierrole/${supplierId}`);
  }

  getSupplierRole(supplierRoleId: number): Observable<SupplierRole> {
    return this.webApiService.get(this.apiPath, supplierRoleId);
  }

  getSupplierRolesForSupplierPlatform(supplierId: number): Observable<Array<SupplierRole>> {
    return this.webApiService.getList(this.apiPath + `/supplier/${supplierId}`);
  }

  getSupplierRolesForSupplier(supplierId: number): Observable<Array<SupplierRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + `supplierlevel/${supplierId}/supplierrole`);
  }

  save(supplierRole: SupplierRole): Observable<any> {
    return this.webApiService.save<SupplierRole>(this.apiPath, supplierRole);
  }

  edit(supplierRole: SupplierRole): Observable<any> {
    return this.webApiService.edit<SupplierRole>(this.apiPath, supplierRole.supplierRoleId, supplierRole);
  }

  delete(supplierRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, supplierRoleId);
  }
}
