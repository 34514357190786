import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../shared/services/language.service';

@Component({
  selector: 'confirmation-component',
  templateUrl: 'confirmation.component.html',
  providers: []
})
export class ConfirmationComponent implements OnDestroy {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('buttonCloseText') buttonCloseText = 'SHARED.CLOSE';
  @Input('buttonCancelText') buttonCancelText = 'SHARED.CANCEL';
  @Input('cancellable') cancellable: boolean;
  @Input('errorMessage') errorMessage: string;

  @Output() close = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  opened = false;

  constructor (
    private languageService: LanguageService
  ) {
      this._subscription = this.languageService.direction.subscribe(dir => {
        this.rtlEnabled = dir;
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
