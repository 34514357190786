import { Component, OnInit, OnDestroy, Injector, ViewChild} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { LocationComponent } from './location.component';

// services
import { LocationService } from '../shared/services/location.service';

// models
import { Location } from '../shared/models/location';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'locations-component',
  templateUrl: 'locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent extends FullListComponent<Location, LocationComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: LocationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: LocationService,
  ) {
    super(injector, Location);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('LOCATIONS.TITLE');
    this.setTranslations('LOCATIONS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getLocations(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  edit = (e: any) => {
    const locationId = e.row !== undefined ? e.row.data.locationId : e.data.locationId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, locationId, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.id);
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('name', this.translate.instant('SHARED.NAME'));
    name.required = true;

    const address1 = new ImportField('address1', this.translate.instant('SHARED.ADDRESS1'));
    address1.required = true;

    const address2 = new ImportField('address2', this.translate.instant('SHARED.ADDRESS2'));
    address2.required = true;

    const zip = new ImportField('zip', this.translate.instant('SHARED.ZIP'));
    zip.required = true;

    const city = new ImportField('city', this.translate.instant('SHARED.CITY'));
    city.required = true;

    const country = new ImportField('country', this.translate.instant('SHARED.COUNTRY'));
    country.required = true;

    schema.fields.push(name, address1, address2, zip, city, country
    );

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.locationId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe(_ => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }
}
