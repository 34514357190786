import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { ProductPropertyComponent } from './product-property.component';

// models
import { ProductProperty, ProductPropertyType } from '../shared/models/product';
import { Language } from '../../shared/models/language';
import { MasterData } from '../shared/models/master-data';
import { SystemProductProperty } from '../shared/models/system-product-property';

@Component({
  selector: 'product-properties-component',
  templateUrl: 'product-properties.component.html'
})
export class ProductPropertiesComponent extends SimpleListComponent implements OnInit {

  items: Array<ProductProperty> = [];
  languages: Array<Language> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  editingIndex: number;
  masterData: Array<MasterData> = [];
  pageSize = 50;
  systemProductProperties: Array<SystemProductProperty> = [];
  productId: number;

  @Input('data')
  set data(value: Array<ProductProperty>) {
    this.items = value;
    this.translateProductProperties();
    this.matchTypeNames();
    this.matchSystemFieldTypes();
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchTypeNames();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
  }

  @Input('productId')
  set productIdSetter(value: number) {
    this.productId = value;
  }

  @Input('systemProductProperties')
  set systemProductProperty(value: Array<SystemProductProperty>) {
    this.systemProductProperties = value;
    this.matchSystemFieldTypes();
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(ProductPropertyComponent) detailsComponent: ProductPropertyComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  edit(productProperty: ProductProperty, event: Event) {
    this.editingIndex = this.items.indexOf(productProperty);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...productProperty };
    this.detailsComponent.open(this.items, clone, this.productId, this.languages,
      this.propertyTypes, this.masterData, this.systemProductProperties);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.detailsComponent.open(this.items, new ProductProperty(), this.productId, this.languages,
      this.propertyTypes, this.masterData, this.systemProductProperties);
  }

  onProductPropertyAdded(productProperty: ProductProperty) {
    this.items.push(productProperty);
    this.translateProductProperties();
    this.matchTypeNames();
    this.matchSystemFieldTypes();
    this.dataChanged.emit();
  }

  onProductPropertyUpdated(productProperty: ProductProperty) {
    this.items[this.editingIndex] = productProperty;
    this.translateProductProperties();
    this.matchTypeNames();
    this.matchSystemFieldTypes();
    this.dataChanged.emit();
  }

  delete(productProperty: ProductProperty) {
    this.items.splice(this.items.indexOf(productProperty), 1);
    this.dataChanged.emit();
  }

  translateProductProperties() {
    this.items.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  matchTypeNames() {
    this.items.forEach(productProperty => {
      if (this.propertyTypes.length !== 0) {
        const propertyType = this.propertyTypes.find(f => f.propertyTypeId === productProperty.propertyTypeId);
        if (propertyType)
          productProperty.productPropertyTypeText = propertyType.name;
      }
    });
  }

  matchSystemFieldTypes() {
    this.items.forEach(productProperty => {
      const prop = this.systemProductProperties.find(spp => spp.systemProductPropertyId === productProperty.systemProductPropertyType); // tslint:disable-line:max-line-length
      if (prop) {
        productProperty.systemFieldTypeText = prop.name;
      }
    });
  }
}
