import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// global services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { BuyerComponent } from './buyer.component';

import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';

// services
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ClockService } from '../../shared/services/clock.service';
import { CurrencyService } from '../../shared/services/currency.service';
import { MasterDataService } from '../shared/services/master-data.service';


// models
import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Auction } from '../shared/models/auction';
import { Clock } from '../../shared/models/clock';
import { Currency } from '../../shared/models/currency';
import CustomStore from 'devextreme/data/custom_store';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { AuctionCluster } from '../shared/models/auction-cluster';

@Component({
  selector: 'buyers-component',
  templateUrl: 'buyers.component.html',
  styleUrls: ['./buyers.component.scss']
})
export class BuyersComponent extends FullListComponent<AuctionClusterBuyer, BuyerComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: BuyerComponent;

  auctions: Array<Auction> = [];
  clocks: Array<Clock> = [];
  currencies: Array<Currency> = [];
  buyerToDisable: AuctionClusterBuyer;
  customDataSource: CustomStore;
  dataGridInstance: DataGrid;
  allBuyers: Array<AuctionClusterBuyer> = [];
  auctionCluster: AuctionCluster;
  secondRestrictionMasterDataList: any;
  secondRestrictionMasterDataName: string;
  masterDataListValues: any = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterBuyerService,
    private auctionService: AuctionClusterAuctionService,
    private clockService: ClockService,
    private currrencyService: CurrencyService,
    private languageService: LanguageService,
    private auctionClusterService: AuctionClusterService,
    private masterDataService: MasterDataService
  ) {
    super(injector, AuctionClusterBuyer);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.customDataSource = new CustomStore({
      key: 'buyerId',
      load: (loadOptions) => {

        const observables = [];
        observables.push(this.dataService.getBuyersPaged(this.id, loadOptions));
          

        return new Promise((resolve, reject) => {
          forkJoin(observables).subscribe(result => {
            this.items = result[0]['data'];
            this.items.forEach(i => {
              if (!isNaN(Number(i.buyerNumber))) {
                i.buyerNumberNumeric = Number(i.buyerNumber);
              }
            });
            this.items.sort((a, b) => {
              if (a.buyerNumberNumeric < b.buyerNumberNumeric) {
                return -1;
              }
              if (a.buyerNumberNumeric > b.buyerNumberNumeric) {
                return 1;
              }
              return 0;
            });

            this.getAllBuyers();
            this.buildClocksString();

            resolve({
              data: result[0]['data'],
              totalCount: result[0]['totalCount']
            });
          }, error => {
            reject(error);
          });
        });
      }
    });
  }

  ngOnInit() {
    this.title.set('SHARED.BUYER_MANAGEMENT');
    this.setTranslations("BUYER");
    this.getAllBuyers();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();

    forkJoin(
      //this.dataService.getBuyers(this.id),
      this.auctionService.getAuctions(this.id),
      this.clockService.getAllClusterClocks(this.id),
      this.currrencyService.getCurrencies(),
      this.auctionClusterService.getAuctionCluster(this.id),
    ).subscribe(result => {
      this.auctions = result[0];
      this.clocks = result[1];
      this.currencies = result[2];
      this.auctionCluster = result[3];

      this.secondRestrictionMasterDataList = this.auctionCluster.masterDataList.find(_ => _.masterDataListId === this.auctionCluster.secondRestrictionMasterDataId);
      if (this.secondRestrictionMasterDataList) {
        this.secondRestrictionMasterDataName = this.languageService.getTranslatableText(this.secondRestrictionMasterDataList.name)
        let masterDataListId = this.secondRestrictionMasterDataList.masterDataListId;
        
        this.masterDataService.getMasterDataListValues(masterDataListId, this.id)
          .subscribe(res => {
            this.masterDataListValues = res;
          },
            error => {
              this.errorService.show(error);
              this.spinner.hide();
            });
      }
      
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }
  // tslint:enable:no-magic-numbers

  getAllBuyers() {
    this.spinner.show();

    this.dataService.getBuyers(this.id)
      .subscribe(result => {
        this.allBuyers = result;

        this.spinner.hide();
      }, error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    const buyerId = e.row !== undefined ? e.row.data.buyerId : e.data.buyerId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.allBuyers, buyerId, this.auctions, this.clocks, this.id, this.currencies, this.secondRestrictionMasterDataName, this.masterDataListValues);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.allBuyers, null, this.auctions, this.clocks, this.id, this.currencies, this.secondRestrictionMasterDataName, this.masterDataListValues);
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.dataGridInstance.getDataSource().reload();
    }
  }

  buildClocksString() {
    this.items.forEach(buyer => {
      const clockNames: Array<string> = [];
      if (buyer.accessToAllAuctionsAndClocks) {
        if (this.translations) {
          buyer.clocks = this.translations.ALL_CLOCKS;
        }
      }
      else {
        buyer.buyerAuctionClocks.forEach(auctionClock => {
          const auction = this.auctions.find(a => a.auctionId == auctionClock.auctionId);

          const clock = this.clocks.find(f => f.clockId === auctionClock.clockId);
          if (clock) {
            clockNames.push(auction.name + ' (' + clock.name + ')');
          }
          else {
            if (this.translations) {
              clockNames.push(auction.name + ' (' + this.translations.ALL_CLOCKS + ')');
            }
          }
        });
        buyer.clocks = clockNames.join(', ');
      }
    });
  }

  calculateRemainingLimitCellValue(rowData) {
    return rowData.creditLimit  - rowData.backOfficeCreditSpent;
  }

  enableDisable = (e: any) => {
    this.dataService.enableDisable(this.id, e.row.data).subscribe(() => {
      this.dataGridInstance.getDataSource().reload();
    },
      error => {
        this.errorService.show(error);
      });
  }

  enableDisableBuyer = (e: any) => {
    const buyer: AuctionClusterBuyer = e.row.data;
    this.buyerToDisable = buyer;
    this.dataService.enableDisable(this.id, this.buyerToDisable).subscribe(() => {
      this.dataGridInstance.getDataSource().reload();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActiveOnAuctionCluster) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActiveOnAuctionCluster) {
      return true;
    } else {
      return false;
    }
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.buyerId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete).subscribe((buyers) => {
      this.dataGridInstance.getDataSource().reload();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const isActiveOnAuctionCluster = new ImportField('isActiveOnAuctionCluster', this.translate.instant('SHARED.IS_ACTIVE'));
    isActiveOnAuctionCluster.required = true;

    const name = new ImportField('name', this.translate.instant('SHARED.NAME'));
    name.required = true;

    const address1 = new ImportField('address1', this.translate.instant('SHARED.ADDRESS1'));
    address1.required = true;

    const zip = new ImportField('zip', this.translate.instant('SHARED.ZIP'));
    zip.required = true;

    const city = new ImportField('city', this.translate.instant('SHARED.CITY'));
    city.required = true;

    const country = new ImportField('country', this.translate.instant('SHARED.COUNTRY'));
    country.required = true;

    const telNumber = new ImportField('telNumber', this.translate.instant('SHARED.TEL_NUMBER'));
    telNumber.required = true;

    const email = new ImportField('email', this.translate.instant('SHARED.EMAIL'));
    email.required = true;

    schema.fields.push(isActiveOnAuctionCluster, name, address1, zip, city, country, telNumber, email
    );

    this.schema = schema;
  }
}
