import {Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// general services
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from '../../shared/services/title.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { BuyerService } from '../../shared/services/buyer.service';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { SupplierService } from '../../shared/services/supplier.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';
import { LanguageService } from '../../shared/services/language.service';
import { PublicDataService } from '../../shared/services/public-data-service';

// models
import { User, UserBuyerRole, UserSupplierRole } from '../../shared/models/user';
import { Buyer } from '../../shared/models/buyer';
import { Supplier } from '../../shared/models/supplier';
import { AccountType, DesiredAccess, RegisterAssignAccount } from '../shared/models/register-assign-account';
import { BuyerRole } from '../../shared/models/buyer-role';
import { SupplierRole } from '../../shared/models/supplier-role';
import { PublicAuctionCluster } from '../../shared/models/public-auction-cluster';
import { VatService } from '../../shared/services/vat.service';
import { NgForm } from '@angular/forms';
import { AuctionClusterService } from '../../platform/shared/services/auction-cluster.service';

@Component({
  selector: 'register-assign-account-component',
  templateUrl: './register-assign-account.component.html',
  styleUrls: ['./register-assign-account.component.scss']
})
export class RegisterAssignAccountComponent implements OnInit {

  model: RegisterAssignAccount = RegisterAssignAccount.default();
  DesiredAccess = DesiredAccess;
  AccountType = AccountType;

  user: User = new User();
  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];
  filteredBuyerRoles: Array<BuyerRole> = [];
  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];
  auctionClusters: Array<PublicAuctionCluster> = [];
  filteredSupplierRoles: Array<SupplierRole> = [];
  translations: any; // make readonly

  message: string;
  vatCountryCode: string;
  vatNumber: string;

  @ViewChild('registerAssignAccountForm') registerAssignAccountForm: NgForm;

  constructor(
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private titleService: TitleService,
    private errorService: ErrorService,
    private spinner: SpinnerService,
    private buyerService: BuyerService,
    private buyerRoleService: BuyerRoleService,
    private supplierService: SupplierService,
    private supplierRoleService: SupplierRoleService,
    private languageService: LanguageService,
    public vatService: VatService
  ) {

  }

  ngOnInit() {
    this.resetVatField();
    this.titleService.set('REGISTER.TITLE');
    this.getData();

    this.translate.get('LOG_IN').subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('LOG_IN').subscribe((res: string) => {
        this.translations = res;
      });
    });
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.userService.getCurrentUser(),
      this.buyerRoleService.getBuyerRoles(),
      this.supplierRoleService.getSupplierRoles()
    ).subscribe(
      ([user, buyerRoles, supplierRoles]) => {
        this.user = user;
        this.buyerRoles = buyerRoles;
        this.supplierRoles = supplierRoles;
        this.spinner.hide();
      }, error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  registerAssignAccount() {
    if (this.shouldDisableSubmit()) {
      return;
    }

    if (this.model.desiredAccess === DesiredAccess.BUYER && this.model.buyerAccountType === AccountType.EXISTING) {
      this.user.existingSupplierVat = '';
      this.saveUser();
    } else if (this.model.desiredAccess === DesiredAccess.BUYER && this.model.buyerAccountType === AccountType.NEW) {
      const buyer = new Buyer();
      buyer.name = this.model.buyerName;
      buyer.address1 = this.model.buyerAddress1;
      buyer.address2 = this.model.buyerAddress2;
      buyer.zip = this.model.buyerZip;
      buyer.city = this.model.buyerCity;
      buyer.country = this.model.buyerCountry;
      buyer.vat = this.model.buyerVat;
      buyer.telNumber = this.model.buyerTelNumber;
      buyer.faxNumber = this.model.buyerFaxNumber;
      buyer.email = this.model.buyerEmail;
      buyer.url = this.model.buyerUrl;
      buyer.isPending = true;
      buyer.isActive = false;
      this.user.existingBuyerVat = this.model.buyerVat;
      this.saveBuyer(buyer);
    } else if (this.model.desiredAccess === DesiredAccess.SUPPLIER && this.model.supplierAccountType === AccountType.EXISTING) {
      this.user.existingBuyerVat = '';
      this.saveUser();
    } else if (this.model.desiredAccess === DesiredAccess.SUPPLIER && this.model.supplierAccountType === AccountType.NEW) {
      const supplier = new Supplier();
      supplier.name = this.model.supplierName;
      supplier.address1 = this.model.supplierAddress1;
      supplier.address2 = this.model.supplierAddress2;
      supplier.zip = this.model.supplierZip;
      supplier.city = this.model.supplierCity;
      supplier.country = this.model.supplierCountry;
      supplier.vat = this.model.supplierVat;
      supplier.telNumber = this.model.supplierTelNumber;
      supplier.faxNumber = this.model.supplierFaxNumber;
      supplier.email = this.model.supplierEmail;
      supplier.url = this.model.supplierUrl;
      supplier.isPending = true;
      supplier.isActive = false;
      this.user.existingSupplierVat = this.model.supplierVat;
      this.saveSupplier(supplier);
    }
  }

  private setBuyerIdAndRole() {
      const userBuyerRole = new UserBuyerRole();
      userBuyerRole.buyerId = this.model.buyerId;
      userBuyerRole.buyerRoleId = this.model.buyerRoleId;
      this.user.buyerRoles = [userBuyerRole];
  }

  private setSupplierIdAndRole() {
      const userSupplierRole = new UserSupplierRole();
      userSupplierRole.supplierId = this.model.supplierId;
      userSupplierRole.supplierRoleId = this.model.supplierRoleId;
      this.user.supplierRoles = [userSupplierRole];
  }

  saveBuyer(buyer: Buyer) {
    this.buyerService.saveWithAuctionClusterId(buyer, this.user.existingAuctionClusterId)
      .subscribe(result => {
        if (result) {
          if (result.buyerId != 0) {
            this.model.buyerId = result.buyerId;

            forkJoin(
              this.buyerRoleService.getBuyerRoles()
            ).subscribe(
              buyerRoles => {
                this.buyerRoles = buyerRoles[0];
                this.filterBuyerItem();
                this.model.buyerRoleId = this.filteredBuyerRoles[0].buyerRoleId;
                this.setBuyerIdAndRole();
                this.saveUser();
                this.spinner.hide();
              }, error => {
                this.errorService.show(error);
                this.spinner.hide();
              });
          }
          else {
            this.saveUser();
            this.spinner.hide();
          }
        } else {
          // edit failed
          this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
        }
      }, error => {
        this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
      });
  }

  saveSupplier(supplier: Supplier) {
    this.supplierService.save(supplier)
      .subscribe(result => {
        if (result) {
          this.model.supplierId = result.supplierId;

          forkJoin(
            this.supplierRoleService.getSupplierRoles()
          ).subscribe(
            supplierRoles => {
              this.supplierRoles = supplierRoles[0];
              this.filterSupplierItem();
              this.model.supplierRoleId = this.filteredSupplierRoles[0].supplierRoleId;
              this.setSupplierIdAndRole();
              this.saveUser();
              this.spinner.hide();
            }, error => {
              this.errorService.show(error);
              this.spinner.hide();
            });

        } else {
          // edit failed
          this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
        }
      }, error => {
        this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
      });
  }

  saveUser() {
    this.userService.edit(this.user)
      .subscribe(result => {
        if (result) {
          this.router.navigate(['/core/register-awaiting-approval/'], { queryParamsHandling: 'merge' });
        } else {
          // edit failed
          this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
        }
      }, error => {
        this.message = this.translations.INCORRECT_USERNAME_OR_PASSWORD;
      });
  }

  changeBuyerAccountType(buyerAccountType: AccountType) {
    this.model.buyerAccountType = buyerAccountType;
    this.model.supplierAccountType = buyerAccountType;
    this.user.existingSupplierVat = '';
    this.resetVatField();
  }

  changeSupplierAccountType(supplierAccountType: AccountType) {
    this.model.supplierAccountType = supplierAccountType;
    this.model.buyerAccountType = supplierAccountType;
    this.user.existingBuyerVat = '';
    this.resetVatField();
  }

  private resetVatField() {

    forkJoin(
      this.languageService.getLanguages(),
    ).subscribe(
      results => {
        var languages = results[0];
        var currentLanguage = this.languageService.getCurrentLanguage(languages);

        this.vatCountryCode = currentLanguage.code.substr(currentLanguage.code.length - 2);
        this.vatNumber = '';
      },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      }
    );
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  filterBuyerItem() {
    this.filteredBuyerRoles = this.buyerRoles.filter(f => f.buyerId === this.model.buyerId);
  }

  filterSupplierItem() {
    this.filteredSupplierRoles = this.supplierRoles.filter(f => f.supplierId === this.model.supplierId);
  }

  shouldDisableSubmit() {
    return (
      !this.vatService.validate(this.vatCountryCode, this.vatNumber)
    );
  }
}
