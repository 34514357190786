export class UserPermissions {

  // tslint:disable:variable-name
  CorePermission: string;
  PlatformPermissions: Array<PlatformPermissionEnum> = [];
  AuctionClusterPermissions: Array<PermissionListArray<AuctionClusterPermissionEnum>> = [];
  AuctionPermissions: Array<PermissionListArray<AuctionClusterPermissionEnum>> = [];
  BuyerPermissions: Array<PermissionListArray<BuyerPermissionEnum>> = [];
  SupplierPermissions: Array<PermissionListArray<SupplierPermissionEnum>> = [];
  AuctionAuctionClusters: Array<PermissionList<any>> = [];
  AcceptTermsOfUseAuctionClusters: Array<number> = [];
}

export class PermissionList<T> {
  Key: number;
  Value: T;
}
export class PermissionListArray<T> {
  Key: number;
  Value: Array<T>;
}
// tslint:enable:variable-name

export enum CorePermissionEnum {
  Profile,
  AssignAccount,
  AwaitingApproval
}

export enum PlatformPermissionEnum {
  UserManagement = 1,
  PlatformConfiguration = 2,
  AuctionClusterManagement = 3,
  BuyerManagement = 4,
  SupplierManagement = 5,
  Reporting = 6,
  ExternalApplicationManagement = 7,
  RoleManagement = 8,
  Administrator = 9,
}

export enum AuctionClusterPermissionEnum {
  UserManagement = 1,
  Administrator = 2,
  ClockManagement = 3,
  SupplyCatalogManagement = 4,
  LocationManagement = 5,
  MasterdataManagement = 6,
  LookuptableManagement = 7,
  BuyerConfiguration = 8,
  SupplierConfiguration = 9,
  Auctioneer = 10,
  Reporting = 11,
  SupplyMonitor = 12,
  PrinterManagement = 13,
  CleanUp = 14,
  TransactionMonitor = 15,
  RoleManagement = 16,
  ZoneManagement = 17,
  SupplyForecastCatalogManagement = 18,
  SalesOrderSupplierManagement = 19,
  ExternalManagement = 20,
  TermsOfUse = 21,
  GalleryManagement = 22,
  Notifications = 23
}

export enum BuyerPermissionEnum {
  ViewSales = 1,
  BuyOnClock = 2,
  Reporting = 3,
  UserManagement = 4,
  ShoppingListEdit = 5,
  Projection = 6

}

export enum SupplierPermissionEnum {
  FillCatalog = 1,
  Reporting = 2,
  UserManagement = 3,
  ClockViewer = 4
}
