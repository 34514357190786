import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';
import { ProductPropertyStyling } from '../models/product-property-styling';

@Injectable()
export class ProductPropertyStylingService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'productpropertystyling';
  }

  getProductPropertyStylings(): Observable<Array<ProductPropertyStyling>> {
    return this.webApiService.getList(this.apiPath);
  }

  getProductPropertyStyling(productPropertyStylingId: number): Observable<ProductPropertyStyling> {
    return this.webApiService.get(this.apiPath, productPropertyStylingId);
  }

  save(productPropertyStyling: ProductPropertyStyling): Observable<any> {
    return this.webApiService.save<ProductPropertyStyling>(this.apiPath, productPropertyStyling);
  }

  edit(productPropertyStyling: ProductPropertyStyling) {
    return this.webApiService.edit<ProductPropertyStyling>(this.apiPath, productPropertyStyling.productPropertyStylingId, productPropertyStyling);
  }

  delete(productPropertyStylingId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, productPropertyStylingId);
  }
}
