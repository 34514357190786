import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

import { ApplicationSettings } from '../../shared/models/application-settings';
import { WebApiService } from '../../shared/services/web-api.service';
import { MailTypeEnum } from '../models/mailTypeEnum';

@Injectable()
export class MailService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  sendUserLicenseExceededMail(auctionclusterId: number): Observable<any> {
    var url = this.apiPath + '/' + auctionclusterId + '/userlicenseexceeded';
    return this.webApiService.getSingle(url);
  }
}
