import { Component, OnInit } from '@angular/core';
import { Version } from '../../models/version';
import { VersionService } from '../../services/version.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'version-component',
  templateUrl: 'version.component.html',
  styleUrls: ['./version.component.scss'],
  providers: [VersionService]
})
export class VersionComponent implements OnInit {

  // versionModalOpened = false;
  version: Version;

  constructor(private versionService: VersionService) {
    // Initialize version with default values
    this.version = new Version();
    this.version.adminUIVersion = environment.version;
  }

  ngOnInit() {
    // this.versionService.getVersions().subscribe(version => {
    //   this.version = version;
    // });
  }

  // openVersions() {
  //   this.versionModalOpened = true;
  // }
}
