import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router, Route } from '@angular/router';

import { TokenService } from './token.service';
import { UserPermissions, PermissionList, CorePermissionEnum } from '../models/user-permissions';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../constants/cookies';
import { AdditionalMenuItemsService } from './additional-menu-items.service';
import { AccessLevel, AdditionalMenuItem } from '../models/additional-menu-item';

@Injectable()
export class DynamicRoutingService {

  @Output() doLogout = new EventEmitter();

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private cookies: CookieService,
    private additionalMenuItemsService: AdditionalMenuItemsService) { }

  // called from app.module - used for refreshing (we don't need additional menu items for this)
  updateDefaultRoutesWithoutRerouting() {
    this.tokenService.refreshToken();

    const routes = this.router.config;

    const permissions = this.tokenService.getCurrentUserPermissions();
    if (!permissions) {
      return true;
    }

    if (!permissions.AcceptTermsOfUseAuctionClusters
      || !permissions.PlatformPermissions
      || !permissions.AuctionClusterPermissions
      || !permissions.BuyerPermissions
      || !permissions.SupplierPermissions) {
      return this.doLogout.emit();
    }

    let rejectedTOS = this.cookies.get(Cookies.REJECTED_TOS).split(',').map(id => +id).filter(id => id !== 0);
    for (var i = 0; i < permissions.AcceptTermsOfUseAuctionClusters.length; i++) {
      let auctionId = permissions.AcceptTermsOfUseAuctionClusters[i];
      if (rejectedTOS.indexOf(auctionId) === -1) {
        this.router.navigate(['/core/terms-and-conditions/' + auctionId], { queryParamsHandling: 'merge' });
        return false;
      }
    }

    // set the default route per module
    let firstParentRoute: string;
    if (permissions.PlatformPermissions.length > 0) { // platform user
      firstParentRoute = this.getFirstAllowedRouteForLevelWithoutRerouting(permissions, 'platform', null, true); // auction (cluster) user
    } else if (permissions.AuctionClusterPermissions.length > 0 || permissions.AuctionPermissions.length > 0) {
      firstParentRoute = this.getFirstAllowedRouteForLevelWithoutRerouting(permissions, 'auction', null, true);
    } else if (permissions.BuyerPermissions.length > 0) { // buyer user
      firstParentRoute = this.getFirstAllowedRouteForLevelWithoutRerouting(permissions, 'buyer', null, true);
    } else if (permissions.SupplierPermissions.length > 0) { // supplier user
      firstParentRoute = this.getFirstAllowedRouteForLevelWithoutRerouting(permissions, 'supplier', null, true);
    } else if (permissions.CorePermission) {
      // Assume that user had rejected all ternms and conditions for every auction cluster
      if (rejectedTOS.length !== 0) {
        // Do logout
        this.doLogout.emit();
        return false;
      }    

      // if (permissions.CorePermission == "Profile") {
      firstParentRoute = 'core/register-profile';
      // } else if (permissions.CorePermission == "AssignAccount") {
      //  firstParentRoute = 'core/register-assign-account';
      // } else if (permissions.CorePermission == "AwaitingApproval") {
      //  firstParentRoute = 'core/register-awaiting-approval';
      // }
    }

    if (!firstParentRoute) {
      firstParentRoute = 'core/home'; // temporary fallback route before login
    }

    // set the default route for the whole application
    let defaultRoot = routes.find((r: any) => r.redirectTo !== undefined) as any;
    if (defaultRoot) {
      defaultRoot.redirectTo = firstParentRoute;
    } else {
      defaultRoot = {
        path: '',
        redirectTo: firstParentRoute,
        pathMatch: 'full',
        
      };
      routes.push(defaultRoot);
    }

    this.router.resetConfig(routes); // resets the angular routing system (works only for non-lazy loaded modules!)

    return true;
  }

  // used for routing from every other place
  updateDefaultRoutes(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.tokenService.refreshToken();
  
      const routes = this.router.config;
  
      const permissions = this.tokenService.getCurrentUserPermissions();
      if (!permissions) {
        resolve(true);
        return;
      }
  
      if (!permissions.AcceptTermsOfUseAuctionClusters ||
          !permissions.PlatformPermissions ||
          !permissions.AuctionClusterPermissions ||
          !permissions.BuyerPermissions ||
          !permissions.SupplierPermissions) {
            this.doLogout.emit();
        resolve(false);

        return;
      }
  
      let rejectedTOS = this.cookies.get(Cookies.REJECTED_TOS).split(',').map(id => +id).filter(id => id !== 0);
      for (let i = 0; i < permissions.AcceptTermsOfUseAuctionClusters.length; i++) {
        let auctionId = permissions.AcceptTermsOfUseAuctionClusters[i];
        if (rejectedTOS.indexOf(auctionId) === -1) {
          this.router.navigate(['/core/terms-and-conditions/' + auctionId], { queryParamsHandling: 'merge' });
          resolve(false);
          return;
        }
      }
  
      let firstParentRoute: string;
      const permissionsArray = [        
        { key: 'supplier', permissions: permissions.SupplierPermissions },
        { key: 'buyer', permissions: permissions.BuyerPermissions },
        { key: 'auction', permissions: permissions.AuctionClusterPermissions || permissions.AuctionPermissions },
        { key: 'platform', permissions: permissions.PlatformPermissions }
      ];

      const promises = permissionsArray.map(permissionItem => {
        if (permissionItem.permissions && permissionItem.permissions.length > 0) {
          return this.getFirstAllowedRouteForLevel(permissions, permissionItem.key, null, true)
            .then(result => {
              firstParentRoute = result;
            });
        }
      });
  
      Promise.all(promises).then(() => {
        if (!firstParentRoute) {
          firstParentRoute = permissions.CorePermission ? 'core/register-profile' : 'core/home';
        }
  
        let defaultRoot = routes.find((r: any) => r.redirectTo !== undefined) as any;
        if (defaultRoot) {
          defaultRoot.redirectTo = firstParentRoute;
        } else {
          defaultRoot = {
            path: '',
            redirectTo: firstParentRoute,
            pathMatch: 'full',
          };
          routes.push(defaultRoot);
        }
  
        this.router.resetConfig(routes);
        resolve(true);
      }).catch(error => reject(error));
    });
  }

  // used only for getting routes without rerouting
  getFirstAllowedRouteForLevelWithoutRerouting(permissions: UserPermissions, routeLevel: string, preferId?: number, changeDefaultRoute?: boolean): string {
    return this.findFirstAllowedRouteForLevel(permissions, routeLevel, null, preferId, changeDefaultRoute);
  }

  // used for getting routes with rerouting
  // returns promise because it needs to wait for additional menu items routes
  getFirstAllowedRouteForLevel(permissions: UserPermissions, routeLevel: string, preferId?: number, changeDefaultRoute?: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {    
      this.getStartPageFromAdditionalMenu(routeLevel).then(startPageRoute => {
        resolve(this.findFirstAllowedRouteForLevel(permissions, routeLevel, startPageRoute, preferId, changeDefaultRoute));
      }).catch(error => {
        reject(error);
      });
  });
  }

  // finds first valid route for each level - startPageRoute is additional menu item we set as a start page
  findFirstAllowedRouteForLevel(permissions: UserPermissions, routeLevel: string, startPageRoute: string, preferId?: number, changeDefaultRoute?: boolean) {

    const routes = this.router.config;
    const moduleRoute = routes.find(r => r.data && r.data['level'] === routeLevel);

    // get the first route (set the default route) per module
    let firstValidRoute: string;   
      for (let i = 0, len = moduleRoute.children.length; i < len; i++) {
        const route = moduleRoute.children[i] as any; // this represents module routing
        if (route.data && route.data.permission) { // this is the route with permissions defined
          if (route.path.indexOf(':id') > -1) {

            let permissionsFiltered: Array<PermissionList<any>> = [];

            switch (routeLevel) {
              //In comment because on platform level there are no route's with ":id"
              //case 'platform':
              //  permissionsFiltered = permissions.PlatformPermissions.filter(p => 'PlatformPermissions.' + p === route.data.permission);
              //  break;
              case 'auction':
                const clusterPermissions = [];
                // tslint:disable-next-line:max-line-length
                permissions.AuctionClusterPermissions
                  .filter(pList => {
                    if (route.data.permission instanceof Array) {
                      route.data.permission.forEach(permission => {
                        pList.Value.filter(p => 'AuctionClusterPermissions.' + p === permission || 'AuctionPermissions.' + p === permission).forEach(perm => {
                          clusterPermissions.push({ Key: pList.Key, Value: perm });
                        });
                      });
                    }
                    else {
                      pList.Value.filter(p => 'AuctionClusterPermissions.' + p === route.data.permission || 'AuctionPermissions.' + p === route.data.permission).forEach(perm => {
                        clusterPermissions.push({ Key: pList.Key, Value: perm });
                      });
                    }
                  });

                const auctionPermissions = [];
                // tslint:disable-next-line:max-line-length
                permissions.AuctionPermissions
                  .filter(pList => {
                    if (route.data.permission instanceof Array) {
                      route.data.permission.forEach(permission => {
                        pList.Value.filter(p => 'AuctionPermissions.' + p === permission).forEach(perm => {
                          auctionPermissions.push({ Key: pList.Key, Value: perm });
                        });
                      });
                    }
                    else {
                      pList.Value.filter(p => 'AuctionPermissions.' + p === route.data.permission).forEach(perm => {
                        auctionPermissions.push({ Key: pList.Key, Value: perm });
                      });
                    }
                  });
                permissionsFiltered = [...clusterPermissions, ...auctionPermissions]; // tslint:disable-line:max-line-length
                break;
              case 'buyer':
                permissionsFiltered = [];

                permissions.BuyerPermissions
                  .filter(pList => {
                    if (route.data.permission instanceof Array) {
                      route.data.permission.forEach(permission => {
                        pList.Value.filter(p => 'BuyerPermissions.' + p === permission).forEach(perm => {
                          permissionsFiltered.push({ Key: pList.Key, Value: perm });
                        });
                      });
                    }
                    else {
                      pList.Value.filter(p => 'BuyerPermissions.' + p === route.data.permission).forEach(perm => {
                        permissionsFiltered.push({ Key: pList.Key, Value: perm });
                      });
                    }
                  });
                break;
              case 'supplier':
                permissionsFiltered = [];

                permissions.SupplierPermissions
                  .filter(pList => {
                    if (route.data.permission instanceof Array) {
                      route.data.permission.forEach(permission => {
                        pList.Value.filter(p => 'SupplierPermissions.' + p === permission).forEach(perm => {
                          permissionsFiltered.push({ Key: pList.Key, Value: perm });
                        });
                      });
                    }
                    else {
                      pList.Value.filter(p => 'SupplierPermissions.' + p === route.data.permission).forEach(perm => {
                        permissionsFiltered.push({ Key: pList.Key, Value: perm });
                      });
                    }
                  });
                break;
              default:
                break;
            }

            if (preferId) {
              permissionsFiltered = permissionsFiltered.filter(p => p.Key === preferId);
            }
            let hasPermission = false;
            if (route.data.permission instanceof Array && permissionsFiltered.length > 0) {
              hasPermission = this.tokenService.getDeebValuesWithIds(permissions, route.data.permission, permissionsFiltered[0].Key);
            } else if (permissionsFiltered.length > 0) {
              hasPermission = this.tokenService.getDeepValueWithId(permissions, route.data.permission, permissionsFiltered[0].Key);
            }
            if (permissionsFiltered.length > 0 && hasPermission) {
              let id = permissionsFiltered[0].Key;
              if (routeLevel === 'auction' && permissions.AuctionClusterPermissions.length === 0) {
                id = this.getAuctionClusterId(permissions);
              }
              
            if (startPageRoute !== '' && startPageRoute !== null && startPageRoute !== undefined) {
              firstValidRoute = id + startPageRoute;
              break;
            } else { 
              firstValidRoute = route.path.replace(':id', id);
              break;
              }
            }
          } else {
            if (this.tokenService.getDeepValue(permissions, route.data.permission)) {
              if (startPageRoute !== '' && startPageRoute !== null && startPageRoute !== undefined) {
                firstValidRoute = startPageRoute;
                break;
              } else { 
                firstValidRoute = route.path;
                break;
                }
            }
          }
        } else if (!firstValidRoute) {
          if (preferId && route.path.indexOf(':id') > -1) {
            route.path = route.path.replace(':id', preferId);
          }
          firstValidRoute = route.path;
        }
      }    

    if (changeDefaultRoute) {
      return this.setDefaultModuleRoute(moduleRoute, firstValidRoute);
    } else {
      return firstValidRoute;
    }
  }

  getAuctionClusterId(permissions) {
    const auctionId = permissions.AuctionPermissions[0].Key;

    const ac = permissions.AuctionAuctionClusters.find(a => a.Key === auctionId);

    if (ac) {
      return ac.Value;
    }

    return null;
  }

  setDefaultModuleRoute(moduleRoute: Route, firstValidRoute: string): string {

    let firstParentRoute: string;
    if (firstValidRoute) {
      let defaultRoute = moduleRoute.children.find((r: any) => r.redirectTo !== undefined) as any;
      if (defaultRoute) {
        defaultRoute.redirectTo = firstValidRoute;
      } else {
        defaultRoute = {
          path: '',
          redirectTo: firstValidRoute,
          pathMatch: 'full'
        };
        moduleRoute.children.push(defaultRoute);
      }
      if (firstValidRoute.startsWith('/')) {
        firstParentRoute = '/' + (moduleRoute as any).path + firstValidRoute;
      }
      else {
        firstParentRoute = '/' + (moduleRoute as any).path + '/' + firstValidRoute;
      }      
    }

    return firstParentRoute;
  }

  getStartPageFromAdditionalMenu(routeLevel: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let menuItems: Array<AdditionalMenuItem> = [];
      let accessLevel: AccessLevel;

      switch(routeLevel) {
        case 'platform': 
          accessLevel = AccessLevel.PLATFORM;
          break;
        case 'auction': 
          accessLevel = AccessLevel.AUCTION_CLUSTER;
          break;
        case 'buyer': 
          accessLevel = AccessLevel.BUYER;
          break;
        case 'supplier': 
          accessLevel = AccessLevel.SUPPLIER;
          break;        
      }

      let foundAdditionalMenuItem: AdditionalMenuItem | undefined;
      const subscription = this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {
        if (result !== undefined){          
          let minAccessLevelsCount = 10; // there are five access levels

          result.forEach(item => {
            if (item.accessLevels.includes(accessLevel) && item.submenuId === null && item.setAsStartPage) {
              if (item.accessLevels.length < minAccessLevelsCount) {
                minAccessLevelsCount = item.accessLevels.length;
                foundAdditionalMenuItem = item;
              }
            }
          });
        }
  
        if (foundAdditionalMenuItem) {
          const startPageRoute = '/menu-item/' + foundAdditionalMenuItem.externalAppId;
          subscription.unsubscribe();
          resolve(startPageRoute);
        } else {
          subscription.unsubscribe(); 
          resolve('');;
        }
      },
      error => {
        subscription.unsubscribe(); 
        resolve('');
      });
    });
  }
}
