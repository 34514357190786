import { MasterDataGridSelectionTypeEnum } from "./masterdatagridselectionTypeEnum";

export class MasterDataGridSelection {
  masterDataGridSelectionId: number;
  auctionClusterId: number;
  masterDataGridSelectionType: MasterDataGridSelectionTypeEnum;
  masterDataListFieldId: number;
  orderNumber: number;
  name: string;
}
