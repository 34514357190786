import { Component, OnInit, Injector, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { TicketPrinterService } from '../shared/services/ticket-printer.service';

// models
import { Language } from '../../shared/models/language';
import { PrinterStatus, TicketPrinter, TicketPrinterTypes } from '../shared/models/ticket-printer';
import { Clock } from '../../shared/models/clock';
import { Catalog } from '../shared/models/catalog';
import { Location } from '../shared/models/location';
import { DxFormComponent } from 'devextreme-angular';


@Component({
  selector: 'ticket-printer-component',
  templateUrl: 'ticket-printer.component.html',
  styleUrls: ['./ticket-printer.component.scss']
})
export class TicketPrinterComponent extends ItemDetailsComponent<TicketPrinter> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;

  auctionClusterId: number;
  ticketPrinterTypes: any = TicketPrinterTypes;
  locations: Array<Location> = [];
  clocks: Array<Clock> = [];
  catalogs: Array<Catalog> = [];
  translations: any;
  ticketTypes: any;
  printerStatussen = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  @ViewChild('ticketPrinterForm', { read: DxFormComponent }) ticketPrinterForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: TicketPrinterService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.ticketPrinterTypes = Object.keys(this.ticketPrinterTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.ticketPrinterTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get([
      'SUPPLY_TICKET',
      'TRANSACTION_TICKET'
    ]).subscribe((res: string) => {
      this.ticketTypes = res;
    });
    this.translate.get(['TICKET_PRINTER.DISCONNECTED', 'TICKET_PRINTER.CONNECTED', 'TICKET_PRINTER.OUTOFPAPER', 'TICKET_PRINTER.PRINTHEADOPEN', 'TICKET_PRINTER.PRINTHEADERROR']).subscribe((res: string) => {
      this.printerStatussen = [
        { value: 0, name: res['TICKET_PRINTER.DISCONNECTED'] },
        { value: 1, name: res['TICKET_PRINTER.CONNECTED'] },
        { value: 2, name: res['TICKET_PRINTER.UNKNOWN'] },
        { value: 3, name: res['TICKET_PRINTER.OUTOFPAPER'] },
        { value: 4, name: res['TICKET_PRINTER.PRINTHEADOPEN'] },
        { value: 5, name: res['TICKET_PRINTER.PRINTHEADERROR'] },
        { value: 6, name: res['TICKET_PRINTER.SENSORERROR'] },
        { value: 7, name: res['TICKET_PRINTER.CUTTERERROR'] },
        { value: 8, name: res['TICKET_PRINTER.OUTOFMEMORY'] },
        { value: 9, name: res['TICKET_PRINTER.NETWORKERROR'] },
        { value: 10, name: res['TICKET_PRINTER.RIBBONERROR'] }
      ];
    });
    }

  ngOnInit() {
    this.model = new TicketPrinter();
    this.auctionClusterId = +this.route.snapshot.params['id'];

    this.setTranslations('TICKET_PRINTER');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(ticketPrinters: Array<TicketPrinter>, ticketPrinterId: number,
    clocks: Array<Clock>, catalogs: Array<Catalog>, locations: Array<Location>) {
    this.allItems = ticketPrinters;
    this.clocks = clocks;
    this.catalogs = catalogs;
    this.locations = locations;


    // EDIT
    if (ticketPrinterId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getTicketPrinter(this.auctionClusterId, ticketPrinterId)
        .subscribe((res: TicketPrinter) => {
          this.model = res;
          // this.onProductChanged();

          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
      // NEW
    } else {
      this.model = new TicketPrinter();
      this.isEditMode = false;
      this.isOpened = true;
    }

    // this.ticketPrinterName.elementFocus();
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new TicketPrinter();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new TicketPrinter();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  public onTestTicket() {
    this.spinner.show();
    this.model.auctionClusterId = this.auctionClusterId;

    this.dataService.testTicket(this.model)
      .subscribe((res: any) => {
        this.model = new TicketPrinter();
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  public onCancel() {
    super.onCancel();
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnTicketPrinterSubmit')[0].setAttribute('disabled', 'disabled');
        document.getElementsByName('btnTestTicket')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnTicketPrinterSubmit')[0].removeAttribute('disabled');
        document.getElementsByName('btnTestTicket')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnTicketPrinterSubmit')[0].removeAttribute('disabled');
        document.getElementsByName('btnTestTicket')[0].removeAttribute('disabled');
      }
    }
  }

  translateField = (item: any) => {
    if (item !== null) {
      const label = this.ticketTypes[item.name];
      return label;
    } else {
      return null;
    }
  }

  translateStatus = (status: any) => {
    if (status || status == 0) {
      const label = this.printerStatussen[status].name;
      return label;
    }
  }

  isDisconnected = (status: any) => {
    return status == PrinterStatus.DISCONNECTED;
  }

  isConnected = (status: any) => {
    return status == PrinterStatus.CONNECTED;
  }

  isError(status) {
    return status > PrinterStatus.CONNECTED;
  }
}
