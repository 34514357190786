import { Component, OnInit } from '@angular/core';

// general services
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'register-awaiting-approval-component',
  templateUrl: './register-awaiting-approval.component.html',
  styleUrls: ['./register-awaiting-approval.component.scss']
})
export class RegisterAwaitingApprovalComponent implements OnInit {
  constructor(
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.set('REGISTER.TITLE');
  }
}
