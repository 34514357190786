import { Component, Injector, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { NotificationManagerService } from '../../services/notification-manager.service';
import { LanguageService } from '../../services/language.service';
import { Language } from '../../models/language';
import { forkJoin, Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UserNotification } from '../../models/user-notification';
import { NotificationChannelType } from '../../models/notificationChannelTypeEnum';

@Component({
  selector: 'edit-user-notification-channels-component',
  templateUrl: './edit-user-notification-channels.component.html',
  styleUrls: ['./edit-user-notification-channels.component.scss']
})
export class EditUserNotificationChannelsComponent extends ItemDetailsComponent<UserNotification> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  languages: Array<Language> = [];
  formDirty: boolean = false;
  notificationName: string;

  @ViewChild('editUserNotificationChannelsForm', { read: DxFormComponent}) editUserNotificationChannelsForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private notificationManagerService: NotificationManagerService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new UserNotification();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }
  
  open(userNotification: UserNotification, languages: Array<Language>) {
    this.isOpened = true;
    this.languages = languages;
    this.model = userNotification;
    forkJoin(
      this.notificationManagerService.getUserNotificationChannels(userNotification.userNotificationId)
    ).subscribe(result => {
      userNotification.userNotificationChannels = result[0];
      result[0].forEach(element => {
        if(element.auctionClusterChannel.notificationChannelType == NotificationChannelType.Email) {
          this.model.channelEmail = element;
        }
        else if(element.auctionClusterChannel.notificationChannelType == NotificationChannelType.SMS) {
          this.model.channelSMS = element;
        }
        else if(element.auctionClusterChannel.notificationChannelType == NotificationChannelType.Push) {
          this.model.channelPush = element;
        }
        this.notificationName = userNotification.auctionClusterNotification.nameText;
      });
    });
    this.spinner.hide();
  }
  
  save() {
    this.spinner.show();
    if(this.formDirty) {
      this.model.userNotificationChannels.forEach(channel => {
        this.notificationManagerService.enableDisableUserNotificationChannel(channel, channel.isActive).subscribe(() => {}, error => {
          this.errorService.show(error);
        });
      });
    }
    this.spinner.hide();
    this.close(true);
  }
  
  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup'){
      if (!this.formDirty) this.formDirty = true;
    }
  }
}
