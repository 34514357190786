import { Component, Output, OnInit, Injector, ViewChild, EventEmitter } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { SaleUnit } from '../shared/models/product';
import { Language } from '../../shared/models/language';
import { PriceUnit } from '../shared/models/price-unit';

@Component({
  selector: 'sale-unit-component',
  templateUrl: 'sale-unit.component.html',
  styleUrls: ['./sale-unit.component.scss']
})
export class SaleUnitComponent extends ItemDetailsComponent<SaleUnit> implements OnInit {

  auctionClusterId: number;
  languages: Array<Language> = [];

  @ViewChild('saleUnitName') saleUnitName;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<SaleUnit>();
  @Output() onUpdated = new EventEmitter<SaleUnit>();
  // tslint:enable:no-output-on-prefix

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new SaleUnit();
  }

  open(saleUnits: Array<SaleUnit>, saleUnit: SaleUnit, priceUnit: PriceUnit, languages: Array<Language>) {

    this.allItems = saleUnits;
    this.languages = languages;

    if (saleUnit.name !== null && saleUnit.name !== undefined) {
      this.isEditMode = true;
      this.model = saleUnit;
      this.isOpened = true;
    } else {
      this.model = saleUnit;
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = JSON.stringify(emptyTranslation);
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.saleUnitName.data;

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new SaleUnit();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  public onCancel() {
    this.saleUnitName.resetLanguageButtons();
    super.onCancel();
  }
}
