import { Component, Injector, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { forkJoin, timer, Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { AuctionClusterSupplier } from '../shared/models/auction-cluster-supplier';
import { AuctionClusterSupplierService } from '../shared/services/auction-cluster.supplier.service';
import { ActivatedRoute } from '@angular/router';

export class SupplierPublish {
  suppliers: Array<number> = [];
}

@Component({
  selector: 'publish-lot-component',
  templateUrl: 'publish-lot.component.html',
  styleUrls: ['./publish-lot.component.scss'],
})
export class PublishLotComponent extends ItemDetailsComponent<SupplierPublish> implements OnInit, OnDestroy {
  @Output() publish = new EventEmitter<any>();

  suppliers: Array<AuctionClusterSupplier> = [];
  auctionId: number;
  catalogId: number;
  requiredSuppliers: Array<number> = [];
  requiredSuppliersGrid = [];
  isForecastCatalog = true;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private catalogService: CatalogService,
    private supplierService: AuctionClusterSupplierService,
    private route: ActivatedRoute,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new SupplierPublish();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(auctionId: number, catalogId: number, isForecastCatalog: boolean = true) {

    // this.suppliers = suppliers;
    this.auctionId = auctionId;
    this.catalogId = catalogId;
    this.isForecastCatalog = isForecastCatalog;

    forkJoin(
      this.supplierService.getSuppliersForCatalog(+this.route.snapshot.params['id'], catalogId),
      this.supplierService.getSuppliersForCatalogPublished(+this.route.snapshot.params['id'], catalogId)
    ).subscribe(result => {
      this.requiredSuppliersGrid = result[1];

      this.suppliers = result[0];

      if (isForecastCatalog) {
        this.suppliers = this.suppliers.concat(result[1]);
      }

      this.suppliers.sort((a, b) => a.supplierNumber.localeCompare(b.supplierNumber));
      this.suppliers = this.suppliers.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.supplierId).indexOf(obj.supplierId) === pos;
      });

      this.suppliers.forEach(supplier => {
        if (isForecastCatalog && result[1].some(o => o.supplierId == supplier.supplierId) && result[0].some(o => o.supplierId === supplier.supplierId)) {
          supplier.displayName = `*  ${supplier.supplierNumber} - ${supplier.name}`;
        }
        else {
          supplier.displayName = `${supplier.supplierNumber} - ${supplier.name}`;
        }
        this.requiredSuppliers.push(supplier.supplierId);
      });
    });
    this.isOpened = true;
  }

  save() {

    this.model.suppliers = this.requiredSuppliersGrid.map(s => {
      return s.supplierId;
    });

    this.spinner.show();
    this.catalogService.publishSupplierLots(this.auctionId, this.catalogId, this.model).subscribe(() => {
      this.spinner.hide();
      this.onCancel();
      this.publish.emit();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }
}
