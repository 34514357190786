import { Component, OnInit, Injector } from '@angular/core';
import { forkJoin } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ReportComponent } from './report.component';

// services
import { PlatformReportService } from '../shared/services/platform-report.service';

// models
import { Language } from '../../shared/models/language';
import { Report } from '../../shared/models/report';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Product } from '../../auction/shared/models/product';
import { MasterData } from '../../auction/shared/models/master-data';

import * as _moment from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment; 

@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html'
})
export class ReportsComponent extends FullListComponent<Report, ReportComponent> implements OnInit {

  languages: Array<Language> = [];
  products: Array<Product> = [];
  masterDatas: Array<MasterData> = [];
  reports: Array<Report> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: PlatformReportService
  ) {
    super(injector, Report);
    this.title.set('REPORTS.TITLE');
  }

  ngOnInit() {
    this.setTranslations('REPORTS');

    this.getData();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getReports(),
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => {
        if (a.reportDesign.name < b.reportDesign.name) {
          return -1;
        }
        if (a.reportDesign.name > b.reportDesign.name) {
          return 1;
        }
        return 0;
      });
      this.languages = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  openReport(reportId: number, auctionClusterId: number) {
      // tslint:disable: no-floating-promises
    if(auctionClusterId !== null) {
      this.router.navigate(['/platform/reports/ac/' + auctionClusterId + '/report/' + reportId]);
    } else {
      this.router.navigate(['/platform/reports/report/' + reportId]);
    }
      // tslint:enable: no-floating-promises
    }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((users: Array<Report>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('Name', this.translate.instant('SHARED.NAME'));
    name.required = true;

    schema.fields.push(name);

    this.schema = schema;
  }

  edit(itemId: number, event: Event) { }

  add() { }
}
