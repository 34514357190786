import { Component, OnInit, Injector, OnDestroy } from '@angular/core';

// components
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

// services
import { LanguageService } from '../../shared/services/language.service';
import { ConfigurationParameterService } from '../shared/services/configuration-parameter.service';

// models
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { ConfigurationParameter } from '../shared/models/configuration-parameter';



@Component({
  selector: 'configuration-parameter-component',
  templateUrl: 'configuration-parameter.component.html',
  styleUrls: ['./configuration-parameter.component.scss']
})
export class ConfigurationParameterComponent extends ItemListComponent<ConfigurationParameter> implements OnInit, OnDestroy {

  createDataSchema(): void { }


  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  typeOfEnvironment = [
    {
      value: 0,
      name: 'Dev'
    },
    {
      value: 1,
      name: 'Test'
    },
    {
      value: 2,
      name: 'Demo'
    },
    {
      value: 3,
      name: 'Prod'
    }
  ];

  typeOfPlatform = [
    {
      value: 0,
      name: 'On prem'
    },
    {
      value: 1,
      name: 'Cloud'
    }
  ];

  constructor(
    protected injector: Injector,
    private configurationParameterService: ConfigurationParameterService,
    private languageService: LanguageService
  ) {
    super(injector, ConfigurationParameter);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('CONFIGURATION_PARAMETERS.TITLE');
    this.setTranslations('CONFIGURATION_PARAMETERS');
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    this.configurationParameterService.getConfigurationParameter(1)
      .subscribe((result: ConfigurationParameter) => {
        this.model = result;
        this.spinner.hide();
      }, error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  save() {
    this.spinner.show();
    this.configurationParameterService.edit(this.model)
      .subscribe((res: any) => {
        this.spinner.hide();
        notify('Saved successfully', 'success', 2000);
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }


  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnConfigurationParameterSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnConfigurationParameterSubmit')[0].removeAttribute('disabled');
      }
    }
  }

}
