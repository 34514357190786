import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, timer, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { AuctionClusterReportingRoleComponent } from './auction-cluster-reporting-role.component';

// services
import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';
import { AuctionClusterReportService } from '../shared/services/report.service';

// models
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { ReportDesign } from '../../shared/models/report';

@Component({
  selector: 'auction-cluster-reporting-roles.component',
  templateUrl: 'auction-cluster-reporting-roles.component.html',
  styleUrls: ['./auction-cluster-reporting-roles.component.scss']
})
export class AuctionClusterReportingRolesComponent extends FullListComponent<AuctionClusterReportingRole, AuctionClusterReportingRoleComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterReportingRoleComponent;

  reportDesigns: Array<ReportDesign> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterReportingRoleService,
    private reportService: AuctionClusterReportService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {
    super(injector, AuctionClusterReportingRole);
    this.title.set('AUCTION.REPORTING_ROLES');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }
  
  getData() {
    this.spinner.show();
    forkJoin(
      this.reportService.getAllAuctionClusterDesigns(this.id, this.translateService.currentLang),
      this.dataService.getAuctionClusterReportingRoles(this.id, this.translateService.currentLang),
    ).subscribe(result => {
      this.reportDesigns = result[0];
      this.items = result[1];
      
      this.items.forEach(role => {
        role.permissionString = this.buildPermissionsString(role.reportDesigns);
      });
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.auctionClusterReportingRoleId;

    this.dataService.checkIfRoleHasUsers(this.id, this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        this.errorService.show(this.errorService.translations.ROLE_HAS_USERS);
      }
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((result: boolean) => {
        this.items = this.items.filter(r => r.auctionClusterReportingRoleId != this.itemIdToDelete);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  edit(roleId: number, event: Event) {
    event.stopPropagation();
    this.detailsComponent.open(this.items, roleId, this.reportDesigns, this.id);
  }

  editItem = (e: any) => {
    const auctionClusterReportingRoleId = e.row !== undefined ? e.row.data.auctionClusterReportingRoleId : e.data.auctionClusterReportingRoleId;
    this.detailsComponent.open(this.items, auctionClusterReportingRoleId, this.reportDesigns, this.id);
  }

  add() {
    this.detailsComponent.open(this.items, null, this.reportDesigns, this.id);
  }

  private buildPermissionsString(permissions: Array<number>) {
    const permissionNames: Array<string> = [];
    permissions.forEach(item => {
      const reportDesign = this.reportDesigns.find(p => p.reportDesignId === item);
      if(reportDesign !== undefined)
      {
        const permissionName = reportDesign.name;
        permissionNames.push(this.languageService.getTranslatableText(permissionName));
      }
      
    });
    return permissionNames.join(', ');
  } 
}