import { Injectable } from '@angular/core';
import { empty, Observable } from 'rxjs';
import { BuyerUser } from '../models/buyer-user';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { IUserService } from '../../../shared/interfaces/user';

class UserFilter {
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
}

@Injectable()
export class UserService implements IUserService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(buyerId: number) {
    this.buyerId = buyerId;
  }

  getUsersFiltered(userFilter: UserFilter): Observable<Array<BuyerUser>> {

    let url: string;

    if (!userFilter) {
      return empty();
    }

    // build url on applied filter:
    if (userFilter.showActive !== null) {
      url = this.appendToUrl(url, 'ShowActive', userFilter.showActive);
    }
    if (userFilter.showInactive !== null) {
      url = this.appendToUrl(url, 'ShowInactive', userFilter.showInactive);
    }
    if (userFilter.showBlocked !== null) {
      url = this.appendToUrl(url, 'ShowBlocked', userFilter.showBlocked);
    }
    if (userFilter.showPending !== null) {
      url = this.appendToUrl(url, 'ShowPending', userFilter.showPending);
    }

    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/user/filter/${url}`);
  }

  getUser(userId: number): Observable<BuyerUser> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/user/${userId}`);
  }

  edit(user: BuyerUser) {
    return this.webApiService.editSingle<BuyerUser>(`${this.apiPath}/${this.buyerId}/user`, user);
  }

  delete(userId: number): Observable<any> {
    return this.webApiService.delete(`${this.apiPath}/${this.buyerId}/user`, userId);
  }

  private appendToUrl(url: string, field: string, value: any): string {
    if (url) {
      url += `&${field}=${value}`;
    } else {
      url = `?${field}=${value}`;
    }
    return url;
  }

}
