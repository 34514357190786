import { Component, Output, OnInit, Injector, EventEmitter, Input } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductProperty, ProductPropertyType, ProductPropertyTypes, NonGroupingSelection, UrlTypes } from '../shared/models/product';
import { MasterData, MasterDataListField } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';

enum GroupingActionOnProperty {
  TAKE_FIRST = 0,
  FIXED_VALUE = 1,
  AGGREGATE = 2,
  CONCATENATE = 3,
  LEAVE_EMPTY = 4,
  CONCATENATE_DISTINCT = 5,
  GROUP_NAME = 6,
  ALL = 7
}

enum AggregationTypes {
  MIN = 0,
  MAX = 1,
  SUM = 2
}

@Component({
  selector: 'non-grouping-property-component',
  templateUrl: 'non-grouping-property.component.html',
  styleUrls: ['./non-grouping-property.component.scss']
})
export class NonGroupingPropertyComponent extends ItemDetailsComponent<NonGroupingSelection> implements OnInit {

  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isSupplyData: boolean;
  isMasterData: boolean;
  masterDataListFields: Array<MasterDataListField> = [];
  auctionClusterId: number;
  groupingActions: any = GroupingActionOnProperty;
  aggregationTypes: any = AggregationTypes;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<NonGroupingSelection>();
  @Output() onUpdated = new EventEmitter<NonGroupingSelection>();
  // tslint:enable:no-output-on-prefix

  @Input('isSupplyData')
  set supplyData(value: boolean) {
    this.isSupplyData = value;
  }

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.groupingActions = Object.keys(this.groupingActions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.aggregationTypes = Object.keys(this.aggregationTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.aggregationTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
  }

  ngOnInit() {
    this.model = new NonGroupingSelection();
  }

  open(properties: Array<NonGroupingSelection>, property: NonGroupingSelection, productProperties: Array<ProductProperty>, productPropertyTypes: Array<ProductPropertyType>, masterDataList: Array<MasterData>) { // tslint:disable-line:max-line-length

    this.allItems = properties;
    this.productProperties = productProperties;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes;

    this.translateProductProperties();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    if (property !== null) {
      this.isEditMode = true;
      this.model = property;
      this.filterMasterDataFields();
      this.isOpened = true;
    } else {
      this.model = new NonGroupingSelection();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  isAgregateVisible() {
    const pp = this.productProperties.find(p => p.productPropertyId === this.model.productPropertyId);
    if (pp) {
      if (pp.propertyTypeId === ProductPropertyTypes.DECIMAL
          || pp.propertyTypeId === ProductPropertyTypes.NUMBER
          || pp.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
        return true;
      }
      if (pp.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        const mdf = this.masterDataListFields.find(m => m.masterDataListFieldId === this.model.masterDataListFieldId);

        if (mdf) {
          if (mdf.propertyTypeId === ProductPropertyTypes.DECIMAL
              || mdf.propertyTypeId === ProductPropertyTypes.NUMBER
              || mdf.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isImage()
  {
    const pp = this.productProperties.find(p => p.productPropertyId === this.model.productPropertyId);
    if (pp) {
      if (pp.propertyTypeId === ProductPropertyTypes.URL && pp.propertyTypeFormatId === UrlTypes.IMAGE_URL) {
        return true;
      }
    }
    return false;
  }

  save() {
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new NonGroupingSelection();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  onMasterDataChanged() {
    this.filterMasterDataFields();
    this.model.masterDataListFieldId = null;
  }

  filterMasterDataFields() {
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
        this.masterDataListFields = mdl.fields;
        this.isMasterData = true;
      });
    } else {
      this.isMasterData = false;
    }
  }
}
