import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Currency } from '../models/currency';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';

@Injectable()
export class CurrencyService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getCurrencies(): Observable<Array<Currency>> {
    return this.webApiService.getList(this.apiPath  + 'currency');
  }
}
