import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

import { ItemListComponent } from '../../shared/components/item-list/item-list.component';
import { LanguageService } from '../../shared/services/language.service';
import { AuctionClusterImport } from '../shared/models/auction-cluster-import';
// models
import { Difference } from '../shared/models/difference';
// services
import { AuctionClusterService } from '../shared/services/auction-cluster.service';

@Component({
  selector: 'import-auctioncluster.component',
  templateUrl: 'import-auctioncluster.component.html',
  styleUrls: ['./import-auctioncluster.component.scss']
})
export class ImportAuctionClusterComponent extends ItemListComponent<Difference> implements OnInit, OnDestroy {
  auctionclusterImport: AuctionClusterImport;
  selectedFile: File;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private auctionClusterService: AuctionClusterService,
    private languageService: LanguageService
  ) {
    super(injector, Difference);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('IMPORT_AUCTIONCLUSTER.TITLE');

    this.auctionclusterImport = new AuctionClusterImport();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getData() {
  }

  fileChangeEvent(event: any): void {
    this.selectedFile = null;
    this.auctionclusterImport = new AuctionClusterImport();

    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, "UTF-8");
    fileReader.onload = () => {
      this.auctionclusterImport = <AuctionClusterImport>JSON.parse(fileReader.result.toString());
    }
    fileReader.onerror = (error) => {
      console.log(error);
    }
  }

  handleCompare() {
    this.spinner.show();

    this.auctionClusterService.compare(this.auctionclusterImport)
      .subscribe((res: any) => {
        this.items = res;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.errorService.show(error);
        });

  }

  handleImport() {
    this.spinner.show();


    this.auctionClusterService.import(this.auctionclusterImport)
      .subscribe((res: any) => {
        this.items = null;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.errorService.show(error);
        });

  }

  getSysName1(rowData) {
    var result = "";
    if (rowData.parentObject1 && rowData.parentObject1.sysName) {
      result = rowData.parentObject1.sysName;
    }
    else if (rowData.object1 && rowData.object1.sysName) {
      result = rowData.object1.sysName;
    }
    return result;
  }

  getSysName2(rowData) {
    var result = "";
    if (rowData.parentObject2 && rowData.parentObject2.sysName) {
      result = rowData.parentObject2.sysName;
    }
    else if (rowData.object2 && rowData.object2.sysName) {
      result = rowData.object2.sysName;
    }
    return result;
  }

  createDataSchema() {
  }
}
