import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as _moment from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

import notify from 'devextreme/ui/notify';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ForecastCatalogComponent } from './forecast-catalog.component';
import { CleanupConfirmationComponent } from '../shared/components/cleanup-confirmation.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ProductService } from '../shared/services/product.service';
import { DateTimeService } from '../../shared/services/datetime.service';

// models
import { Catalog } from '../shared/models/catalog';
import { Auction } from '../shared/models/auction';
import { Product } from '../shared/models/product';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { CheckCleanupResult } from '../shared/models/checkcleanupresult';


@Component({
  selector: 'forecast-catalogs-component',
  templateUrl: 'forecast-catalogs.component.html',
  styleUrls: ['./forecast-catalogs.component.scss']
})
export class ForecastCatalogsComponent extends FullListComponent<Catalog, ForecastCatalogComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ForecastCatalogComponent;
  @ViewChild('confirmationCleanup') cleanupConfirmation: CleanupConfirmationComponent;
  @ViewChild('confirmationCleanupDialog') confirmationCleanupDialog: ConfirmationComponent;
  @ViewChild('confirmationEmptyBuffer') confirmationEmptyBuffer: ConfirmationComponent;
  @ViewChild('cleanupDoneMessage') cleanupDoneMessage: ConfirmationComponent;

  auctions: Array<Auction> = [];
  products: Array<Product> = [];
  selectedAuction: number = -1;
  catalogToCleanup: Catalog;
  catalogCleanupDate = new Date();
  confirmationErrorMessage: string;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: CatalogService,
    private auctionService: AuctionClusterAuctionService,
    private productService: ProductService,
    private languageService: LanguageService,
    private tokenService: TokenService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Catalog);
    this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('CATALOGS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getAllForecastCatalogs(this.id),
      this.auctionService.getAuctionsForPermissions(this.id, [AuctionClusterPermissionEnum.SupplyForecastCatalogManagement]),
      this.productService.getProducts(this.id, null)
    ).subscribe(result => {
      this.items = result[0];
      this.auctions = result[1];
      this.products = result[2];
      this.spinner.hide();
      this.items.forEach(item => {
        item.auctionName = this.matchAuctionName(item.auctionId);
        item.productName = this.matchProductName(item.productId);
      });
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.catalogId, this.products, this.auctions, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.products, this.auctions, this.id);
  }

  private matchProductName(productId: number) {
    const found = this.products.find(f => f.productId === productId);
    return found ? this.languageService.getTranslatableText(found.name) : '';
  }

  private matchAuctionName(auctionId: number) {
    const found = this.auctions.find(f => f.auctionId === auctionId);
    return found ? found.name : '';
  }

  getSelectedAuctions() {
    if (this.selectedAuction === null) {
      this.getData();
    } else {
      this.dataService.getAuctionForecastCatalog(this.selectedAuction).subscribe(catalogs => {
        this.items = catalogs;
        this.items.forEach(item => {
          item.auctionName = this.matchAuctionName(item.auctionId);
          item.productName = this.matchProductName(item.productId);
        });
      });
    }
  }

  deleteSelected() {
    this.spinner.show();
    const auctionId = this.items.find(f => f.catalogId === this.itemIdToDelete).auctionId;
    this.dataService.delete(auctionId, this.itemIdToDelete)
      .subscribe((catalogs: Array<Catalog>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  isMasterDataSetup(catalogId) {
    const catalog = this.items.find(i => i.catalogId === catalogId);

    if (catalog) {
      const product = this.products.find(p => p.productId === catalog.productId);

      if (product) {
        return product.hasForecastMasterDetails;
      }
    }


    return false;
  }

  openLots(catalogId: number, event: Event) {
    event.stopPropagation();
    if (this.isMasterDataSetup(catalogId)) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/forecastmasterdetails/' + catalogId]);
    } else {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + catalogId]);
    }
  }

  openCleanupConfirmation(catalog: Catalog, event: Event) {
    this.confirmationErrorMessage = null;
    event.stopPropagation();

    this.catalogToCleanup = catalog;
    this.cleanupConfirmation.opened = true;
  }

  catalogCleanupDateChanged(date: Date) {
    this.catalogCleanupDate = date;
  }

  cleanupCatalog() {
    this.confirmationErrorMessage = null;
    this.spinner.show();
    this.dataService.checkCleanup(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
      .subscribe(res => {
        this.spinner.hide();
        switch (+res) {
          case CheckCleanupResult.Ok:
            this.confirmationCleanupDialog.opened = true;
            break;
          case CheckCleanupResult.TransactionsInBuffer:
            this.confirmationEmptyBuffer.opened = true;
            break;
          case CheckCleanupResult.TransactionsPendingConfirmation:
            this.errorService.show(this.errorService.translations.CLEANUP_TRANSACTIONS_PENDINGCONFIRMATION);
            break;
        }
      }, error => {
        // error message show
        this.spinner.hide();
      });
  }

  cleanupConfirmed() {
    this.confirmationErrorMessage = null;
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.catalogToCleanup.auctionId)) {
      this.spinner.show();
      this.dataService.cleanupCatalog(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.cleanupDoneMessage.opened = true;
        });
    }

    this.cleanupConfirmation.opened = false;
  }

  emptyBufferConfirmed() {
    this.confirmationErrorMessage = null;
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.catalogToCleanup.auctionId)) {
      this.spinner.show();
      this.dataService.emptyTransactionBuffer(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
        .subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.confirmationCleanupDialog.opened = true;
          } else {
            this.confirmationErrorMessage = this.translations['CLEANUP_EMPTY_BUFFER_ERROR'];
            this.confirmationCleanupDialog.opened = true;
          }
        });
    }

    this.cleanupConfirmation.opened = false;
  }

  getDate() {
    return this.dateTimeService.getDateStringByFormatAny(moment(this.catalogCleanupDate), 14);
  }

  emptyBufferCanceled() {
    this.confirmationCleanupDialog.opened = true;
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const name = new ImportField('name', this.translate.instant('SHARED.NAME'));
    name.required = true;

    const auctionName = new ImportField('auctionName', this.translate.instant('SHARED.AUCTION'));
    auctionName.required = true;

    const description = new ImportField('description', this.translate.instant('SHARED.DESCRIPTION'));
    description.required = true;

    const productName = new ImportField('productName', this.translate.instant('SHARED.PRODUCT'));
    productName.required = true;

    schema.fields.push(name, auctionName, description, productName
    );

    this.schema = schema;
  }

  checkCatalogPermission = (e: any) => {
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.id)) {
      return true;
    }
    return false;
  }

  onRowClick = (e: any) => {
    this.openLots(e.data.catalogId, new Event('click'));
  }

  clickOpenLots = (e: any) => {
    this.openLots(e.row.data.catalogId, new Event('click'));
  }

  refreshCatalog = (e: any) => {
    this.openCleanupConfirmation(e.row.data, new Event('click'));
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.catalogId;
    this.confirmation.opened = true;
  }
}
