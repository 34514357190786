import { NotificationTypeEnum } from "./notificationTypeEnum";

export class AuctionClusterNotification {
    auctionClusterId: number;
    auctionClusterNotificationId: number;
    reportId: number;
    notificationType: NotificationTypeEnum;
    name: string;
    title: string;
    description: string;
    body: string;
    isEnabled: boolean;
    isActive: boolean;
    lastTimeSent: Date;
    activeAuctionClusterNotificationChannelIds: Array<number> = [];
    defaultAuctionClusterNotificationChannelIds: Array<number> = [];

    //Displayable fields
    nameText: string;
    defaultName: string;
    descriptionText: string;
    titleText: string;
    reportName: string;
    activeChannelsString: string;
}