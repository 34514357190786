import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { MaskComponent } from './mask.component';

// services
import { MaskService } from '../shared/services/mask.service';

// models
import { Mask, MaskTypes } from '../shared/models/mask';

@Component({
  selector: 'masks-component',
  templateUrl: 'masks.component.html',
  styleUrls: ['./masks.component.scss']
})
export class MasksComponent extends FullListComponent<Mask, MaskComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: MaskComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: MaskService,
    private translateService: TranslateService,
  ) {
    super(injector, Mask);
    this.title.set('MASK.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('MASK');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getMasks(this.id, this.translateService.currentLang)
    ).subscribe(result => {
      this.items = result[0];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.maskId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<Mask>) => {
        this.items = this.items.filter(r => r.maskId != this.itemIdToDelete);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  edit = (maskId: number) => {
    this.detailsComponent.open(this.items, maskId);
  }

  onRowClick = (e: any) => {
    this.edit(e.data.maskId);
  }

  editItem = (e: any) => {
    this.edit(e.row.data.maskId);
  }

  add() {
    this.detailsComponent.open(this.items, null);
  }

  getMaskSourceTypeName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case MaskTypes.SUPPLY_FORECAST_MASK_TYPE:
        return this.translations.SUPPLY_FORECAST_MASK_TYPE;
      case MaskTypes.SUPPLY_MASK_TYPE:
        return this.translations.SUPPLY_MASK_TYPE;
    }
  }
}