import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { CatalogService } from '../shared/services/catalog.service';

// models
import { Catalog } from '../shared/models/catalog';
import { Auction } from '../shared/models/auction';
import { Product } from '../shared/models/product';

import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'forecast-catalog-component',
    templateUrl: 'forecast-catalog.component.html',
    styleUrls: ['./forecast-catalog.component.scss']
})
export class ForecastCatalogComponent extends ItemDetailsComponent<Catalog> implements OnInit, OnDestroy {

    catalog: Catalog;
    auctions: Array<Auction> = [];
    products: Array<Product> = [];
    auctionClusterId: number;

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

    constructor(
        protected injector: Injector,
        private dataService: CatalogService,
        private languageService: LanguageService,
    ) {
      super(injector);
      this._subscription = this.languageService.direction.subscribe(dir => {
        this.rtlEnabled = dir;
      });
    }

    ngOnInit() {
      this.model = new Catalog();
    }
  
    ngOnDestroy() {
      this._subscription.unsubscribe();
    }

  open(catalogs: Array<Catalog>, catalogId: number, products: Array<Product>, auctions: Array<Auction>, auctionClusterId: number) {

    this.allItems = catalogs;
    this.products = products;
    this.auctions = auctions;
    this.auctionClusterId = auctionClusterId;


    this.products.forEach(item => {
      item.productNameText = this.languageService.getTranslatableText(item.name);
    });

    if (catalogId !== null) {
      this.catalog = catalogs.find(f => f.catalogId === catalogId);
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getAuctionCatalog(this.catalog.auctionId, catalogId)
        .subscribe((res: Catalog) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Catalog();
      this.isEditMode = false;
      this.isOpened = true;
    }
    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

    getTranslation(value: string) {
        return this.languageService.getTranslatableText(value);
    }

  save() {

    this.model.isForecast = true;

    this.model.auctionClusterId = this.auctionClusterId;
    if (this.isEditMode) {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.edit(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new Catalog();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new Catalog();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }  

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnCatalogSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnCatalogSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnCatalogSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
