import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { GroupingPropertyComponent } from './grouping-property.component';
import { NonGroupingPropertyComponent } from './non-grouping-property.component';

// models
import { ProductProperty, ProductPropertyType, GroupingSelection, NonGroupingSelection, GridProperty } from '../shared/models/product';
import { MasterData } from '../shared/models/master-data';
import { ProductPropertyTypes } from '../shared/models/product-property-types';
import { MasterDataService } from '../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'grouping-properties-component',
  templateUrl: 'grouping-properties.component.html',
  styleUrls: ['./grouping-properties.component.scss']
})
export class GroupingPropertiesComponent extends SimpleListComponent implements OnInit {

  editingIndex: number;
  masterData: Array<MasterData> = [];
  pageSize = 50;
  groupingProperties: Array<GroupingSelection> = [];
  nonGroupingProperties: Array<NonGroupingSelection> = [];
  productProperties: Array<ProductProperty> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  filteredProductProperties: Array<ProductProperty> = [];
  filteredPropertiesForGroupEdit: Array<ProductProperty> = [];
  filteredPropertiesForNonGroupEdit: Array<ProductProperty> = [];
  isSupplyData: boolean;
  auctionClusterId: number;
  showFilterProperties = false;
  filterProperties: Array<GridProperty> = [];

  @Input('groupingProperties')
  set groupProperty(value: Array<GroupingSelection>) {
    this.groupingProperties = value;
    this.groupingProperties.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.matchProductPropertiesNames();
  }

  @Input('nonGroupingProperties')
  set nonGroupProperty(value: Array<NonGroupingSelection>) {
    this.nonGroupingProperties = value;
    this.matchProductPropertiesNames();
    this.matchTypeNames();
  }

  @Input('filterProperties')
  set filterPropertiesValues(value: Array<GridProperty>) {
    this.filterProperties = value;
    this.matchProductPropertiesNames();
    this.matchTypeNames();
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchProductPropertiesNames();
    this.matchTypeNames();
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
    this.filteredProductProperties = value;
    this.matchProductPropertiesNames();
    this.matchTypeNames();
    this.translateProperties();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.matchProductPropertiesNames();
    this.matchTypeNames();
  }

  @Input('isSupplyData')
  set supplyData(value: boolean) {
    this.isSupplyData = value;
  }

  @Input('showFilterProperties')
  set filterPropertiesShowing(value: boolean) {
    this.showFilterProperties = value;
  }

  @Output() groupingPropertiesChanged = new EventEmitter<Array<GroupingSelection>>();
  @Output() nonGroupingPropertiesChanged = new EventEmitter<Array<NonGroupingSelection>>();
  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(GroupingPropertyComponent) groupingComponent: GroupingPropertyComponent;
  @ViewChild(NonGroupingPropertyComponent) nonGroupingComponent: NonGroupingPropertyComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  editGroupProperties(property: GroupingSelection, event: Event) {
    this.editingIndex = this.groupingProperties.indexOf(property);
    event.stopPropagation();
    this.filterPropertiesForGroupEdit();
    this.groupingComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.groupingComponent.open(this.groupingProperties, clone, this.filteredPropertiesForGroupEdit, this.propertyTypes, this.masterData);
  }

  filterPropertiesForGroupEdit() {
    this.filteredPropertiesForGroupEdit = [];
    this.groupingProperties.forEach(gp => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === gp.productPropertyId);
      if (prop) {
        this.filteredPropertiesForGroupEdit.push(prop);
      }
    });
  }

  filterPropertiesForNonGroupEdit() {
    this.filteredPropertiesForNonGroupEdit = [];
    this.nonGroupingProperties.forEach(gp => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === gp.productPropertyId);
      if (prop) {
        this.filteredPropertiesForNonGroupEdit.push(prop);
      }
    });
  }

  filterAddedProperties(excludeMasterData: boolean) {
    this.filteredProductProperties = this.productProperties.map((currElem, index, arr) => {
      if (currElem.propertyTypeId === ProductPropertyTypes.MASTER_DATA) {
        return currElem;
      } else {
        const productProp = this.nonGroupingProperties.find(ngp => ngp.productPropertyId === currElem.productPropertyId);
        if (productProp) {
          return null;
        } else {
          return currElem;
        }
      }
    }).filter(fp => fp !== null);

    this.filteredProductProperties = this.filteredProductProperties.map((currElem, index, arr) => {
      const nonGroupingProp = this.nonGroupingProperties.find(ngp => ngp.productPropertyId === currElem.productPropertyId);
      if (nonGroupingProp) {
        if (currElem.propertyTypeId === ProductPropertyTypes.MASTER_DATA) {
          return null;
        }
      }
      const groupingProp = this.groupingProperties.find(gp => gp.productPropertyId === currElem.productPropertyId);
      if (groupingProp) {
        return null;
      } else {
        return currElem;
      }
    }).filter(fp => fp !== null);
  }

  addGroupProperty() {
    this.filterAddedProperties(true);
    this.groupingComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.groupingComponent.open(this.groupingProperties, null, this.filteredProductProperties, this.propertyTypes, this.masterData);
  }

  addNonGroupProperty() {
    this.filterAddedProperties(false);
    this.nonGroupingComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.nonGroupingComponent.open(this.nonGroupingProperties, null, this.productProperties, this.propertyTypes, this.masterData);
  }

  editNonGroupProperties(property: NonGroupingSelection, event: Event) {
    this.editingIndex = this.nonGroupingProperties.indexOf(property);
    event.stopPropagation();
    this.filterPropertiesForNonGroupEdit();
    this.nonGroupingComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.nonGroupingComponent.open(this.nonGroupingProperties, clone, this.filteredPropertiesForNonGroupEdit, this.propertyTypes, this.masterData); // tslint:disable-line:max-line-length
  }

  onGroupingPropertyAdded(property: GroupingSelection) {
    this.groupingProperties.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.groupingPropertiesChanged.emit(this.groupingProperties);
    this.dataChanged.emit();
  }

  onGroupingPropertyUpdated(property: GroupingSelection) {
    this.groupingProperties[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.groupingPropertiesChanged.emit(this.groupingProperties);
    this.dataChanged.emit();
  }

  onNonGroupingPropertyAdded(property: NonGroupingSelection) {
    this.nonGroupingProperties.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.nonGroupingPropertiesChanged.emit(this.nonGroupingProperties);
    this.dataChanged.emit();
  }

  onNonGroupingPropertyUpdated(property: NonGroupingSelection) {
    this.nonGroupingProperties[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.nonGroupingPropertiesChanged.emit(this.nonGroupingProperties);
    this.dataChanged.emit();
  }

  onFilterPropertiesUpdated() {
    this.dataChanged.emit();
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.groupingProperties);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.groupingProperties);
  }

  deleteGroupingProperty(property: GroupingSelection) {
    this.groupingProperties.splice(this.groupingProperties.indexOf(property), 1);
    this.dataChanged.emit();
  }

  deleteNonGroupingProperty(property: NonGroupingSelection) {
    this.nonGroupingProperties.splice(this.nonGroupingProperties.indexOf(property), 1);
    this.dataChanged.emit();
  }

  private matchTypeNames() {
    this.groupingProperties.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const propertyType = this.propertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          item.productPropertyTypeName = propertyType.name;
      }
    });

    this.nonGroupingProperties.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const propertyType = this.propertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          item.productPropertyTypeName = propertyType.name;
      }
    });
  }

  private matchMasterDataFieldNames() {
    this.nonGroupingProperties.forEach(item => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === item.productPropertyId);
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          if (masterDataListFields) {
            const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === item.masterDataListFieldId);
            if (masterDataListField) {
              item.masterDataFieldName = masterDataListField.name;
            }
          }
        });
      }
    });
  }

  private matchProductPropertiesNames() {
    this.groupingProperties.forEach(property => {
      const prop = this.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = prop.name;
      }
    });

    this.nonGroupingProperties.forEach(property => {
      const prop = this.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = prop.name;
      }
    });
  }

  private translateProperties() {
    this.groupingProperties.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });

    this.nonGroupingProperties.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });
  }
}
