import { Component, Injector, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxPopupComponent, DxFormComponent } from 'devextreme-angular';

// components
import { ItemDetailsComponent } from '../item-details/item-details.component';

// services
import { UserService } from '../../services/user.service';
import { LanguageService } from '../../services/language.service';

// models
import notify from 'devextreme/ui/notify';
import { VerifyMyPassword } from '../../models/verify-my-password';


@Component({
  selector: 'verify-my-password-component',
  templateUrl: './verify-my-password.component.html',
  providers: []
})
export class VerifyMyPasswordComponent extends ItemDetailsComponent<VerifyMyPassword> implements OnInit, OnDestroy {

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild(DxFormComponent) verifyMyPasswordForm: DxFormComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  private userId: number;
  private auctionClusterId: number;

  constructor(
    protected injector: Injector,
    private dataService: UserService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new VerifyMyPassword();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(userId: number, auctionClusterId: number) {
    this.userId = userId;
    this.auctionClusterId = auctionClusterId;
    this.isOpened = true;
  }

  async save() {
    this.spinner.show();
    this.dataService.verifyMyPassword(this.model).subscribe((result: boolean) => {
      if (result) {
        this.dataService.resetMFASecret(this.userId, this.auctionClusterId, new Date()).subscribe((result: boolean) => {
          this.isOpened = false;
          this.close(true);
          notify('MFA has been reset successfully', 'success', 2000);
        }, error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
      } else {
        notify('Bad password', 'error', 1000);
      }
    this.spinner.hide();
    },
    error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });
  }

  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnVerifyMyPasswordSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnVerifyMyPasswordSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
