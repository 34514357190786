<dx-switch class="switch-button" 
           *ngIf="isMultiEditorModeEnabled"
           [value]="!isTouchMode"
           switchedOffText="Touch"
           switchedOnText="Desktop"
           [width]="80"
           (onValueChanged)="switchEditorMode()"
           hint="{{ 'LOTS.CHANGE_SUPPLY_EDITOR_MODE' | translate }}"></dx-switch>

<div class="item-list-component lots-component-touch" [ngClass]="{'dx-rtl': rtlEnabled}"
     dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="editor" [style.width]="'100%'" [style.padding]="'0px'">
    <div class="card">
      <form #lotForm="ngForm" class="input-station-form-wrapper" *ngIf="lot">
        <div class="card-body">
          <div class="properties-wrapper">
            <div *ngFor="let property of lotProperties; let i = index" (click)="selectProperty(property, $event)"
                 [ngClass]="{ 'property-disabled': property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled
                                                    || (property.propertyTypeId === 2 && property.isWeighingScaleOn),
                                'property-selected': property.productPropertyId == selectedProductPropertyId, 'hidden': !property.productPropertyEditorSelectionDetails.isVisible,
                                'required-warning': property.displayRequiredWarning }">

              <div class="quick-select-container">
                <div class="quick-select-container-label">
                  <button type="button" class="btn btn-primary btn-add"
                          [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled
                                      || (property.propertyTypeId === 2 && property.isWeighingScaleOn)"
                          [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required }">
                    <span>{{ getTranslation(property.name) }}</span>
                  </button>
                </div>
                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 5">
                  <label class="property-value">{{ getMasterDataLabelForLotProperty(property) }}</label>
                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 1">
                  <label class="property-value center">{{ property.intValue }}</label>
                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 2">
                  <label class="property-value center decimal-value"
                         [ngClass]="{'weighing-scale-field-stable': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 1),
                                      'weighing-scale-field-unstable': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 2),
                                      'weighing-scale-field-error': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 3)}">{{ property.decimalValue }}</label>
                  <dx-switch *ngIf="property.propertyTypeId === 2 && property.productPropertyEditorSelectionDetails.useWeighingScale"
                              class="switch-button-decimal"
                              switchedOffText="Off"
                              switchedOnText="On"
                              (onInitialized)="setWeighingScaleState(property)"
                              [value]="property.isWeighingScaleOn"
                              (onValueChanged)="changeWeighingScaleState(property, $event)"
                              [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly"
                              hint="{{ 'LOTS.WEIGHING_SCALE' | translate }}">
                    </dx-switch>
                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 3">
                  <label class="property-value" *ngIf="!property.translatable">{{ property.stringValue }}</label>
                  <label class="property-value"
                         *ngIf="property.translatable">{{ getTranslation(property.stringValue) }}</label>
                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 8 && property.propertyTypeFormatId === 17">
                  <div class="photo-thumbnail">
                      <label for="thumbnail-input" id="thumbnail-label">
                          <img [src]="property.displayUrlImageValue && property.displayUrlImageValue !== this.emptyPictureSource ? property.displayUrlImageValue : this.cameraPictureSource" (click)="openCamera();"/>
                      </label>
                      <input *ngIf="!isWindowsOS" id="thumbnail-input" type="file" accept=".png, .jpg, .jpeg, image/*" (input)="onImageChanged($event)"/>
                  </div>   
                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 4">
                  <label *ngIf="property.propertyTypeFormatId === 11" for="dateValueProp" aria-haspopup="true"
                         role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                         [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required)}">
                    Date4
                    <dx-date-box ngDefaultControl class="dt-input" [attr.name]="property.productPropertyId"
                                 [(value)]="property.dateTimeValue" (onValueChanged)="dateChanged(property, $event);"
                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                 type="datetime">
                    </dx-date-box>
                    <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                      {{dateErrorMessage}}
                    </span>
                    <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </span>
                  </label>

                  <label *ngIf="property.propertyTypeFormatId === 12" for="dateValueProp" aria-haspopup="true"
                         role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                         [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required)}">
                    <dx-date-box ngDefaultControl class="dt-input" [attr.name]="property.productPropertyId"
                                 [(value)]="property.dateTimeValue" (onValueChanged)="dateChanged(property, $event);"
                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                 [min]="property.systemProductPropertyType === 12 ? currentDate: null">
                    </dx-date-box>
                    <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                      {{dateErrorMessage}}
                    </span>
                    <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </span>
                  </label>

                  <label *ngIf="property.propertyTypeFormatId === 13" for="dateValueProp" aria-haspopup="true"
                         role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                         [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required)}">
                    <dx-date-box ngDefaultControl class="dt-input" [attr.name]="property.productPropertyId"
                                 [(value)]="property.dateTimeValue" (onValueChanged)="dateChanged(property, $event);"
                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                 type="time">
                    </dx-date-box>
                    <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                      {{dateErrorMessage}}
                    </span>
                    <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </span>
                  </label>

                  <label *ngIf="property.propertyTypeFormatId === 14" for="dateValueProp" aria-haspopup="true"
                         role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                         [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required)}">
                    <dx-date-box ngDefaultControl class="dt-input" [attr.name]="property.productPropertyId" #dateBox
                                 [(value)]="property.dateTimeValue" (onValueChanged)="dateChanged(property, $event);"
                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                 [min]="property.systemProductPropertyType === 12 ? currentDate: null">
                    </dx-date-box>
                    <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                      {{dateErrorMessage}}
                    </span>
                    <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </span>
                  </label>

                  <label *ngIf="property.propertyTypeFormatId === 15" for="dateValueProp" aria-haspopup="true"
                         role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                         [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required)}">
                    <dx-date-box ngDefaultControl class="dt-input" [attr.name]="property.productPropertyId"
                                 [(value)]="property.dateTimeValue" (onValueChanged)="dateChanged(property, $event);"
                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                 type="time">
                    </dx-date-box>
                    <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                      {{dateErrorMessage}}
                    </span>
                    <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </span>
                  </label>

                </div>

                <div class="quick-select-container-value" *ngIf="property.propertyTypeId === 7">
                  <dx-check-box ngDefaultControl name="{{'booleanValueProp' + i}}"
                                [attr.name]="'booleanValueProp' + i" class="focusable dx-input" #booleanValueProp="ngModel"
                                [(ngModel)]="property.booleanValue" (ngModelChange)="property.changed = true" [attr.tabindex]="-1"
                                #el="ngModel" (blur)="elementBlur(property, booleanValueProp);"
                                (change)="validate(property, booleanValueProp);focusNextElement();"
                                [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                  </dx-check-box>
                </div>            
              </div>
            </div>
          </div>

          <dx-popup #popUp [width]="'auto'" [height]="'auto'" [showTitle]="true" title="{{ 'LOTS.TAKE_PICTURE' | translate }}" [dragEnabled]="false" [showCloseButton]="true" [(visible)]="popUpShowed" (onHiding)="closePopUp()">
            <div class="au-dx-popup-padder">
              <video (loadeddata)="onVideoLoad($event)" [class.show]="!isCaptured" [class.hide]="isCaptured" #camera id="camera"></video>
              <canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #imageTaken id="imageTaken"></canvas>
              <div *ngIf="videoError">
                {{ videoError }}
              </div>
              <br>
              <button type="button" class="btn btn-primary" (click)="takePhoto()">
                {{ 'LOTS.TAKE_PHOTO' | translate }}
              </button>
              </div>
          </dx-popup>

          <div class="input-wrapper">
            <div *ngIf="selectedLotProperty">
              <div *ngIf="selectedLotProperty.propertyTypeId === 1 || selectedLotProperty.propertyTypeId === 2">
                <label for="numberValueProp" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                       [ngClass]="{'invalid': (numberValueProp.touched || numberValueProp.dirty) && numberValueProp.invalid}">
                  <input clrInput
                         type="number" name="{{'numberValueProp'}}" class="focusable number" id="input-filter"
                         [attr.name]="'numberValueProp'" [(ngModel)]="propertyValue"
                         (change)="filterSuggestedValues(); confirmSetValue()" #numberValueProp="ngModel"
                         [required]="selectedLotProperty.productPropertyEditorSelectionDetails.required ? '' : null"
                         [readonly]="selectedLotProperty.productPropertyEditorSelectionDetails.isReadOnly || selectedLotProperty.readOnlyOnceSelected || selectedLotProperty.manuallyDisabled 
                                    || (selectedLotProperty.propertyTypeId === 2 && selectedLotProperty.isWeighingScaleOn)" />
                  <span class="tooltip-content" *ngIf="numberErrorMessage!==''">
                    {{numberErrorMessage}}
                  </span>
                  <span class="tooltip-content" *ngIf="numberErrorMessage===''">
                    <!-- Required text -->
                    <ng-container *ngIf="selectedLotProperty.productPropertyEditorSelectionDetails.required">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </ng-container>
                    <!-- Min/max validation text -->
                    <ng-container *ngIf="selectedLotProperty.minValue !== undefined && selectedLotProperty.maxValue !== undefined">
                      {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN_MAX' | translate: { minValue: selectedLotProperty.minValue, maxValue: selectedLotProperty.maxValue } }}
                    </ng-container>
                    <!-- Min validation text -->
                    <ng-container *ngIf="selectedLotProperty.minValue !== undefined && selectedLotProperty.maxValue === undefined">
                      {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN' | translate: { minValue: selectedLotProperty.minValue } }}
                    </ng-container>
                    <!-- Max validation text -->
                    <ng-container *ngIf="selectedLotProperty.minValue === undefined && selectedLotProperty.maxValue !== undefined">
                      {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MAX' | translate: { maxValue: selectedLotProperty.maxValue } }}
                    </ng-container>
                  </span>
                </label>
                <button type="button" class="btn btn-primary" (click)="clear()" [disabled]="selectedLotProperty.propertyTypeId === 2 && selectedLotProperty.isWeighingScaleOn">X</button>
              </div>

              <div *ngIf="selectedLotProperty.propertyTypeId === 3">
                <label for="stringValueProp" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                       [ngClass]="{'invalid': (stringValueProp.touched || stringValueProp.dirty) && stringValueProp.invalid}">
                  <input clrInput
                         type="text" name="{{'stringValueProp'}}" class="focusable" id="input-filter"
                         [attr.name]="'stringValueProp'" [(ngModel)]="propertyValue"
                         (change)="filterSuggestedValues(); confirmSetValue()" #stringValueProp="ngModel"
                         [required]="selectedLotProperty.productPropertyEditorSelectionDetails.required ? '' : null"
                         [readonly]="selectedLotProperty.productPropertyEditorSelectionDetails.isReadOnly || selectedLotProperty.readOnlyOnceSelected || selectedLotProperty.manuallyDisabled" />
                  <span class="tooltip-content" *ngIf="textErrorMessage!==''">
                    {{textErrorMessage}}
                  </span>
                  <span class="tooltip-content" *ngIf="textErrorMessage===''">
                    <!-- Required text -->
                    <ng-container *ngIf="selectedLotProperty.productPropertyEditorSelectionDetails.required">
                      {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                    </ng-container>
                    <!-- Length validation text -->
                    <ng-container *ngIf="selectedLotProperty.maxLength !== undefined">
                      {{ 'LOTS.TEXT_FIELD_VALIDATION_LENGTH' | translate: { maxLength: selectedLotProperty.maxLength } }}
                    </ng-container>
                  </span>
                </label>
                <button type="button" class="btn btn-primary" (click)="clear()">X</button>
              </div>

              <div *ngIf="selectedLotProperty.propertyTypeId === 5">
                <label for="masterDataProp" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                  <input clrInput
                         type="text" name="{{'masterDataPropFilter'}}" class="focusable" id="input-filter"
                         [attr.name]="'masterDataPropFilter'" (change)="filterMasterData();"
                         #masterDataPropFilter="ngModel" [(ngModel)]="masterDataFilter"
                         (ngModelChange)="filterMasterData()"
                         [required]="selectedLotProperty.productPropertyEditorSelectionDetails.required ? '' : null"
                         [readonly]="selectedLotProperty.productPropertyEditorSelectionDetails.isReadOnly || selectedLotProperty.readOnlyOnceSelected || selectedLotProperty.manuallyDisabled" />

                  <!--<span class="tooltip master-data-tooltip" *ngIf="(masterDataProp.touched || masterDataProp.dirty) && masterDataProp.invalid">
                    <clr-tooltip>
                      <clr-icon shape="exclamation-circle" class="is-error" size="22"></clr-icon>
                      <clr-tooltip-content class="tooltip-content" clrPosition="bottom-left" clrSize="md">
                        <span *ngIf="masterDataErrors[i]!==''">{{masterDataErrors[i]}}</span>
                        <span *ngIf="!masterDataErrors[i] || masterDataErrors[i]===''">{{ 'LOTS.REQUIRED_FIELD_VALIDATION' | translate }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </span>-->
                </label>
                <button type="button" class="btn btn-primary" (click)="clear()">X</button>
              </div>

              <div *ngIf="selectedLotProperty.propertyTypeId === 8 && selectedLotProperty.propertyTypeFormatId === 17">
                <label for="imageValueProp" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                       <input clrInput
                              type="text" name="{{'imageValueProp'}}" class="focusable" id="input-filter"
                              [attr.name]="'imageValueProp'" #imageValueProp="ngModel" [ngModel]="selectedLotProperty.displayUrlTextValue"
                              [readonly]="true" />
                </label>
                <button type="button" class="btn btn-primary" (click)="clear(); clearImageProperty();">X</button>
              </div>
            </div>
          </div>

          <div class="filter-wrapper">
            <!-- Display lot image  -->
            <div *ngIf="selectedLotProperty && selectedLotProperty.propertyTypeId === 8 && selectedLotProperty.propertyTypeFormatId === 17">
              <div class="image-upload">
                <label id="image-label" for="image-upload">
                    <img [src]="selectedLotProperty.displayUrlImageValue ? selectedLotProperty.displayUrlImageValue.replace('-thumbnail', '') : this.emptyPictureSource" (click)="openCamera();"/>
                </label>
                <input *ngIf="!isWindowsOS" id="image-upload" type="file" accept=".png, .jpg, .jpeg, image/*" (input)="onImageChanged($event);"/>
              </div>
            </div>
            <!-- Display master data values -->
            <div *ngIf="masterDataValues.length !== 0">
              <button type="button" class="btn btn-default btn-add" *ngFor="let masterData of masterDataValues"
                      (click)="setMasterDataValue(masterData.value)">
                {{ masterData.label }}
              </button>
            </div>

            <!-- Display suggested values if there are no master data values -->
            <div *ngIf="masterDataValues.length === 0 && suggestedValues.length !== 0 && (selectedLotProperty.propertyTypeId === 1 || selectedLotProperty.propertyTypeId === 2)">
              <div *ngFor="let suggestedValue of suggestedValues" class="number-suggestions">
                <button type="button" class="btn btn-default btn-add"
                        (click)="setSuggestedValue(suggestedValue)">
                  {{ suggestedValue }}
                </button>
              </div>
            </div>

            <div *ngIf="masterDataValues.length === 0 && suggestedValues.length !== 0 && (selectedLotProperty.propertyTypeId === 3 || selectedLotProperty.propertyTypeId === 4 || selectedLotProperty.propertyTypeId === 5 || selectedLotProperty.propertyTypeId === 6 || selectedLotProperty.propertyTypeId === 7)">
                <button type="button" class="btn btn-default btn-add" *ngFor="let suggestedValue of suggestedValues"
                        (click)="setSuggestedValue(suggestedValue)">
                  {{ suggestedValue }}
                </button>
            </div>
          </div>

          <div class="input-station-footer-buttons">
            <button type="submit" class="btn btn-primary btn-add" (click)="addNew = false; onSubmit()"
                    *ngIf="lot.inEditMode"
                    [disabled]="savePending || lotAddingInProgress || !isDataValid">
              {{ 'LOTS.SAVE_LOT' | translate }}
            </button>
            <button type="submit" class="btn btn-primary btn-add" (click)="addNew = true; onSubmit()"
                    [disabled]="savePending || lotAddingInProgress || !isDataValid">
              {{ 'SHARED.SAVE_AS_NEW' | translate }}
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="resetEdit()">
              {{ 'LOTS.DELETE_LOT' | translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="back()">{{ 'SHARED.BACK' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
