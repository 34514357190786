import { Component, Injector, ViewChild, OnInit, OnDestroy, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { DxPopupComponent, DxFormComponent, DxValidatorModule } from 'devextreme-angular';
import { Directive } from '@angular/core';
import notify from 'devextreme/ui/notify';

// components
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { VerifyMyPasswordComponent } from '../verify-my-password/verify-my-password.component';

// services
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { LanguageService } from '../../services/language.service';

// models
import { ChangeUserPassword } from '../../models/change-user-password';

@Component({
  selector: 'authentication-component',
  templateUrl: 'authentication.component.html',
  styleUrls: ['authentication.component.scss'],
  providers: []
})
export class AuthenticationComponent extends ItemDetailsComponent<ChangeUserPassword> implements OnInit, OnDestroy {

  @ViewChild('newPasswordInput', { read: ElementRef }) newPasswordInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild(DxFormComponent) changeUserPasswordForm: DxFormComponent;

  auctionClusterId: number;
  bannedPasswords: Array<string>;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  isCurrentPasswordRequired: boolean = false;
  isMFAtabVisible: boolean = false;

  public showPassword: boolean = false;
  public showRepeatPassword: boolean = false;
  public showCurrentPassword: boolean = false;
  capsOn;

  @ViewChild('verifyMyPassword') verifyMyPasswordComponent: VerifyMyPasswordComponent;

  constructor(
    protected injector: Injector,
    private dataService: UserService,
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new ChangeUserPassword();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(userId: number, auctionClusterId: number, isMFAtabVisible: boolean = false, isCurrentPasswordRequired: boolean = false) {
    this.spinner.show();
    this.isEditMode = false;
    this.model = new ChangeUserPassword();    
    this.auctionClusterId = auctionClusterId;
    this.isMFAtabVisible = isMFAtabVisible;
    this.model.userId = userId;
    this.isCurrentPasswordRequired = isCurrentPasswordRequired;
    forkJoin(
      this.authService.getBannedPasswords()
    ).subscribe((result: Array<any>) => {
      this.bannedPasswords = result[0];
    });
    this.isOpened = true; 
    this.spinner.hide();
  }

  passwordComparison = () => {
    return this.model.newPassword;
  };

  resetForm =() => {

  };

  resetMFASecret() {
    this.verifyMyPasswordComponent.open(this.model.userId, this.auctionClusterId);
  }

  async save() {
    this.errorMessage = null;
    if (this.model.newPassword !== this.model.repeatNewPassword) {
      this.errorMessage = this.errorService.translations.REPEAT_PASSWORD_VALIDATION;
    }
    else {
      var regExp = /(.*[A-Z].*)/;
      if (!regExp.test(this.model.newPassword)) {
        this.errorMessage = this.errorService.translations.UPPERCASE_PASSWORD;
      }
      var regExp = /(.*[a-z].*)/;
      if (!regExp.test(this.model.newPassword)) {
        if (!this.errorMessage)
          this.errorMessage = this.errorService.translations.LOWERCASE_PASSWORD;
        else
          this.errorMessage = this.errorService.translations.LOWERCASE_PASSWORD_REPEAT;
      }
      var regExp = /(.*\d.*)/;
      if (!regExp.test(this.model.newPassword)) {
        if (!this.errorMessage)
          this.errorMessage = this.errorService.translations.DIGIT_PASSWORD;
        else
          this.errorMessage = this.errorService.translations.DIGIT_PASSWORD_REPEAT;
      }
      var regExp = /(.{8,}$)/;
      if (!regExp.test(this.model.newPassword)) {
        if (!this.errorMessage)
          this.errorMessage = this.errorService.translations.SHORT_PASSWORD;
        else
          this.errorMessage = this.errorService.translations.SHORT_PASSWORD_REPEAT;
      }
      var regExp = /^(?!.*(.)\1{2})/s;
      if (!regExp.test(this.model.newPassword)) {
        if (!this.errorMessage)
          this.errorMessage = this.errorService.translations.THREE_SYMBOLS_PASSWORD;
        else
          this.errorMessage = this.errorService.translations.THREE_SYMBOLS_PASSWORD_REPEAT;
      }
    }

    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
      return;
    }
    
    this.spinner.show();
    this.dataService.changeUserPassword(this.model, this.auctionClusterId, this.isCurrentPasswordRequired)
      .subscribe((res: any) => {
        this.model = new ChangeUserPassword();
        // this.detailsForm.reset();
        this.close(true);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnChangeUserPasswordSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnChangeUserPasswordSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnChangeUserPasswordSubmit')[0].removeAttribute('disabled');        
      }
    }
  }

  validateBannedPasswords = (e) => {
    for(let i = 0; i < this.bannedPasswords.length; i++)
      if(this.bannedPasswords[i] === e.value)
        return false;
    return true;
  }
}

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
}
