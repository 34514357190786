import { Directive } from '@angular/core';
import { ItemListComponent } from '../item-list/item-list.component';

import { EditableList } from '../../interfaces/item-list';
import { ItemDetails } from '../../interfaces/item-details';

@Directive()
export abstract class EditableListComponent<M, C extends ItemDetails<M>> extends ItemListComponent<M> implements EditableList<M, C> {

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.getData();
    }
  }

  abstract edit(itemId: number, event: Event);
}
