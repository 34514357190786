import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { ProductService } from '../../auction/shared/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductPropertyTypes } from '../../auction/shared/models/product';
import { LanguageService } from '../../shared/services/language.service';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { AuctionCluster } from '../shared/models/auction-cluster';
import { BuyerService } from '../../shared/services/buyer.service';
import { Buyer } from '../../shared/models/buyer';
import { ReportComponent } from '../../shared/components/reporting/report.component';
import { WebApiService } from '../../shared/services/web-api.service';
import { Auction } from '../../auction/shared/models/auction';
import { Catalog } from '../../auction/shared/models/catalog';

// general services
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { AuthService } from '../../shared/services/auth.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
    selector: 'report-editor.component',
    templateUrl: 'report-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['report-editor.component.scss'],
    providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})

export class ReportEditorComponent extends ReportComponent{
  @ViewChild('filterForm') filterForm: NgForm;

  constructor(
    appSettings: ApplicationSettings,
    route: ActivatedRoute,
    //private productService: ProductService,
    titleService: TitleService,
    languageService: LanguageService,
    router: Router,
    errorService: ErrorService,
    private auctionClusterService: AuctionClusterService,
    buyerService: BuyerService,
    spinner: SpinnerService,
    translateService: TranslateService, dateTimeAdapter: DateTimeAdapter<any>,
    webApiService: WebApiService,
    authenticationService: AuthService,) {
    super(appSettings, route, titleService, languageService, router, errorService, buyerService, spinner, translateService, dateTimeAdapter, webApiService, authenticationService);
    route.params.subscribe(val => {
      this.init();
    });
  }

  init(){
    super.init();
  }

  SpecificHandlingAfterFetchingServices():void {
  }

  getFilterForServerFixedFiltering(filterForServer) {

  }

  navigate(report) {
    this.router.navigate(['/platform/reports/report/' + report.reportId]);
  }
}
