<div class="item-list-component prebid-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #prebitOnLotList
            width="98%"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-data-grid #prebidOnLotGrid
                      id="printSection"
                      [rtlEnabled]="rtlEnabled"
                      [showBorders]="true"
                      (onInitialized)="initColumn($event)"
                      [allowColumnReordering]="true"
                      [hoverStateEnabled]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true">
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxi-column [editorOptions]="{ showClearButton: true }"
                      cellTemplate="templatePrebidAmount"
                      [visible]="allowPartialLotPrebid && prebidsEnabled"
                      caption="{{ 'SUPPLY_MONITOR.PREBID_AMOUNT' | translate }}"
                      [fixed]="true"
                      fixedPosition="right">
          </dxi-column>
          <dxi-column [editorOptions]="{ showClearButton: true }"
                      cellTemplate="templatePrebidPrice"
                      [visible]="allowPartialLotPrebid  && prebidsEnabled"
                      caption="{{ 'SHARED.PREBID_PRICE' | translate }}"
                      [fixed]="true"
                      fixedPosition="right">
          </dxi-column>
          <dxi-column [editorOptions]="{ showClearButton: true }"
                      cellTemplate="templatePrebidSubbuyer"
                      [visible]="allowPartialLotPrebid  && prebidsEnabled"
                      caption="{{ 'SHARED.PREBID_SUBBUYER' | translate }}"
                      [fixed]="true"
                      fixedPosition="right">
          </dxi-column>
          <dxi-column [editorOptions]="{ showClearButton: true }"
                      cellTemplate="templateAllOrNothing"
                      [visible]="allowPartialLotPrebid  && prebidsEnabled"
                      caption="{{ 'SUPPLY_MONITOR.ALL_OR_NOTHING' | translate }}"
                      [fixed]="true"
                      fixedPosition="right">
          </dxi-column>

          <div *dxTemplate="let d of 'templateAllOrNothing'" class="checkboxTemplate">
            <dx-check-box [disabled]="prebidForAll"
                          [(value)]="d.data.__item__.allOrNothing"
                          (onValueChanged)="prebidSettings($event)"></dx-check-box>
          </div>

          <div *dxTemplate="let d of 'templatePrebidAmount'">
            <dx-number-box [(value)]="d.data.__item__.amount"
                           (onValueChanged)="lotAmountChanged(d.data.__item__, $event)"
                           [disabled]="prebidForAll || !allowPartialLotPrebid"></dx-number-box>
            <a (click)="removeSubLot(d.data.__item__, $event)"
               class="dx-link removeLink">
              <i title="{{ 'SUPPLY_MONITOR.PREBID_SUBLOT_DELETE' | translate }}" class="dx-icon-trash"></i>
            </a>
          </div>

          <div *dxTemplate="let d of 'templatePrebidPrice'">
            <dx-number-box [(value)]="d.data.__item__.price"
                           [disabled]="prebidForAll"></dx-number-box>
          </div>

          <div *dxTemplate="let d of 'templatePrebidSubbuyer'">
            <dx-select-box [dataSource]="subbuyers"
                           valueExpr="subNumber"
                           [displayExpr]="fieldName"
                           [(value)]="d.data.__item__.subNumber"
                           [disabled]="prebidForAll"
                           showClearButton="true">
            </dx-select-box>
          </div>
          <div *dxTemplate="let cell of 'cellTemplate'">
            <span [innerHtml]="cell.text | format | safeHtml"></span>
          </div>
          <div *dxTemplate="let cell of 'textCellTemplate'">
            <span [innerHtml]="cell.text | safeHtml"></span>
          </div>
          <div *dxTemplate="let data of 'imageCellTemplate'" class="image-column">
            <img *ngIf="doesImageCalculationExist(data.value)" class="thumbnail-image" [src]="getImage(data.value)" (click)="showLargerImage(data.value);" />
          </div>
        </dx-data-grid>
        <br /><br />
        <dx-form #prebidOnLotForm
                 id="prebidOnLotForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [(formData)]="prebid"
                 [width]="500">
          <dxi-item itemType="group">
            <dxi-item dataField="prebidForAll" [visible]="allowPartialLotPrebid  && prebidsEnabled" [template]="'templatePrebidForAll'">
              <dxo-label text="{{ 'SUPPLY_MONITOR.PREBID_FOR_ALL' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="price" [visible]="prebidForAll  && prebidsEnabled" [template]="'templateFormPrebidPrice'">
              <dxo-label text="{{ 'SHARED.PREBID_PRICE' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="subbuyerId" [visible]="prebidForAll  && prebidsEnabled" [template]="'templateFormPrebidSubbuyer'">
              <dxo-label text="{{ 'SHARED.PREBID_SUBBUYER' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="allOrNothing" [visible]="prebidForAll && allowPartialLotPrebid  && prebidsEnabled" [template]="'templateFormAllOrNothing'">
              <dxo-label text="{{ 'SUPPLY_MONITOR.ALL_OR_NOTHING' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-item>

          <div *dxTemplate="let data of 'templatePrebidForAll'">
            <dx-check-box [(value)]="prebidForAll" (onValueChanged)="prebidForAllChanged()"></dx-check-box>
          </div>

          <div *dxTemplate="let d of 'templateFormPrebidPrice'">
            <dx-number-box *ngIf="prebidForAll"
                           [(value)]="prebid.price"></dx-number-box>
          </div>

          <div *dxTemplate="let d of 'templateFormPrebidSubbuyer'">
            <dx-select-box *ngIf="prebidForAll"
                           [dataSource]="subbuyers"
                           valueExpr="subNumber"
                           [displayExpr]="fieldName"
                           [(value)]="prebid.subNumber"
                           showClearButton="true">
            </dx-select-box>
          </div>

          <div *dxTemplate="let data of 'templateFormAllOrNothing'">
            <dx-check-box *ngIf="prebidForAll"
                          [(value)]="prebid.allOrNothing"></dx-check-box>
          </div>

        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" *ngIf="prebidsEnabled">
          {{ 'SHARED.SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();" *ngIf="prebidsEnabled">{{ 'SHARED.CANCEL' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="close(false);" *ngIf="!prebidsEnabled">{{ 'SHARED.CLOSE' | translate }}</button>
      </div>
    </dx-scroll-view>
  </dx-popup>
  <dx-popup #imagePopUp [width]="'auto'" [height]="'auto'" [showTitle]="true" title="{{ 'SHARED.SHOW_LARGER_IMAGE' | translate }}" [dragEnabled]="false" [showCloseButton]="true" [(visible)]="popUpShowed" (onHiding)="closePopUp()">
    <img (load)="openPopUp();" [src]="largeImageSrc" />
    <div class="image-caption">{{ imageCaption }}</div>
  </dx-popup>
</div>
