import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular';

// general services

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { ZoneService } from '../shared/services/zone.service';

// models
import { Zone } from '../shared/models/zone';
import { Buyer } from '../../shared/models/buyer';
import { Location } from '../shared/models/location';


@Component({
  selector: 'zone-component',
  templateUrl: 'zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent extends ItemDetailsComponent<Zone> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  auctionClusterId: number;
  buyers: Array<Buyer> = [];
  locations: Array<Location> = [];
  selectedBuyer: number;
  requiredBuyers: Array<number> = [];

  zoneTypes = [
    {value: 1, name: 'ZONES.DEDICATED'}, 
    {value: 2, name: 'ZONES.SHARED'}
  ];

  constructor(
    protected injector: Injector,
    private dataService: ZoneService,
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Zone();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(zones: Array<Zone>, zoneId: number, locations: Array<Location>, buyers: Array<Buyer>, auctionClusterId: number) {

    this.allItems = zones;
    this.buyers = buyers;
    this.locations = locations;
    this.auctionClusterId = auctionClusterId;

    const zone = zones.find(f => f.zoneId === zoneId);
    if (zone && zone.buyers.length === 1) {
      this.selectedBuyer = zone.buyers[0];
    }

    if (zoneId !== null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getZone(this.auctionClusterId, zoneId)
        .subscribe((res: Zone) => {
          this.model = res;
          timer(0).subscribe(() => {
            this.requiredBuyers = this.model.buyers;
          });
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Zone();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }
    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get(item.name).subscribe((res: string) => {
        label  = res;
    });

      return label;
    }
  }

  save() {
    if (this.model.type === 1) {
      this.model.buyers = [];
      this.model.buyers.push(this.selectedBuyer);
    } else {
      this.model.buyers = this.requiredBuyers;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Zone();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Zone();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnZoneSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnZoneSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnZoneSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
