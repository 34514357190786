import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ChangeUserPassword } from '../models/change-user-password';
import { User } from '../models/user';

import { ApplicationSettings } from '../models/application-settings';
import { AuthService } from '../services/auth.service';
import { WebApiService } from '../services/web-api.service';
import { Preferences, UpdatePreferences } from '../models/preferences';
import { VerifyMyPassword } from '../models/verify-my-password';
import * as moment from 'moment';

@Injectable()
export class UserService {

  private adminApiPath: string;
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private authService: AuthService, private webApiService: WebApiService) {
    this.adminApiPath = this.appSettings.adminApi;
    this.apiPath = this.appSettings.adminApi + 'core/user';
  }

  getCurrentUser(): Observable<User> {
    return this.webApiService.getSingle(this.apiPath + "/simple");
  }

  getUser(): Observable<User> {
    return this.webApiService.getSingle(this.apiPath);
  }

  edit(user: User) {
    return this.webApiService.editSingle<User>(this.apiPath, user);
  }

  delete(user: User) {
    return this.webApiService.delete(this.apiPath, user.userId);
  }

  getPreferences(): Observable<Preferences> {
    if (this.authService && this.authService.isRefreshTokenValid()) {
      return this.webApiService.getSingle(`${this.apiPath}/current/preferences`);
    }
    return of(new Preferences(null));
  }

  updatePreferences(updatePreferences: UpdatePreferences): Observable<any> {
    return this.webApiService.put<UpdatePreferences>(`${this.apiPath}/current/preferences`, updatePreferences);
  }
 
  changeUserPassword(changeUserPassword: ChangeUserPassword, auctionClusterId: number, isCurrentPasswordRequired: boolean): Observable<any> {
    if (changeUserPassword.userId === parseInt(this.authService.currentUserId) && isCurrentPasswordRequired) {
      return this.webApiService.put<ChangeUserPassword>(`${this.adminApiPath}auth/change-my-password`, changeUserPassword);
    } else if (auctionClusterId) {
      return this.webApiService.put<ChangeUserPassword>(`${this.adminApiPath}auth/auctioncluster/${auctionClusterId}/change-password`, changeUserPassword);
    } else {
      return this.webApiService.put<ChangeUserPassword>(`${this.adminApiPath}auth/change-password`, changeUserPassword);
    }
  }

  verifyMyPassword(password: VerifyMyPassword): Observable<boolean> {
    return this.webApiService.save<VerifyMyPassword>(`${this.adminApiPath}auth/verify-my-password`, password);
  }

  resetMFASecret(userId: number, auctionClusterId: number, mfaResetTime: Date): Observable<boolean> {
    const mfaResetTimeFormatted =  moment(mfaResetTime).local().format('YYYY-MM-DD HH:mm:ss');
    if (userId === parseInt(this.authService.currentUserId)) {
      return this.webApiService.save(`${this.apiPath}/resetmfa/${userId}/${mfaResetTimeFormatted}`, null);
    } else {
      return this.webApiService.save(`${this.adminApiPath}auctionclusteruser/${auctionClusterId}/user/resetmfa/${userId}/${mfaResetTimeFormatted}`, null);
    }
  }

}
