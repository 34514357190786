import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { AuctionClusterComponent } from './auction-cluster.component';
import notify from 'devextreme/ui/notify';

// services
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { BuyerService } from '../../shared/services/buyer.service';
import { UserService } from '../../platform/shared/services/user.service';
import { CurrencyService } from '../../shared/services/currency.service';
import { MFAService } from '../../shared/services/mfa.service';

// models
import { AuctionCluster } from '../shared/models/auction-cluster';
import { Buyer } from '../../shared/models/buyer';
import { User } from '../../platform/shared/models/user';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Currency } from '../../shared/models/currency';
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../shared/models/language';
import { saveFile } from '../../shared/helpers/file-download-helper';
import { MFAType } from '../../../app/shared/models/mfaType';
import { TimeZoneInfo } from '../../shared/models/timeZoneInfo';

@Component({
  selector: 'auction-clusters-component',
  templateUrl: 'auction-clusters.component.html',
  styleUrls: ['./auction-clusters.component.scss']
})
export class AuctionsClusterComponent extends FullListComponent<AuctionCluster, AuctionClusterComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterComponent;

  buyers: Array<Buyer> = [];
  users: Array<User> = [];
  clusterToDisable: AuctionCluster;
  currencies: Array<Currency> = [];
  languages: Array<Language> = [];
  mfaTypes: Array<MFAType> = [];
  timeZone: Array<TimeZoneInfo> = [];
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private auctionService: AuctionClusterService,
    private userService: UserService,
    private buyerService: BuyerService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    private mfaService: MFAService
  ) {
    super(injector, AuctionCluster);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('SHARED.AUCTION_CLUSTER_MANAGEMENT');
    this.setTranslations('AUCTIONS_CLUSTERS');
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.auctionService.getAuctionClusters(),
      this.userService.getUsers(),
      this.currencyService.getCurrencies(),
      this.languageService.getLanguages(),
      this.buyerService.getBuyers(),
      this.mfaService.getMFATypes(),
      this.auctionService.getTimeZones()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      this.users = result[1];
      this.currencies = result[2];
      this.languages = result[3];
      this.buyers = result[4];
      this.mfaTypes = result[5];
      this.timeZone = result[6];
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });

    this.auctionService.getAuctionClusters().subscribe((auctionClusters) => {
      this.items = auctionClusters;
      this.items.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      this.spinner.hide();
    });
  }

  edit = (e: any) => {
    const auctionClusterId = e.row !== undefined ? e.row.data.auctionClusterId : e.data.auctionClusterId;
    this.detailsComponent.modalTitle = this.translations.EDIT;    
    this.detailsComponent.open(this.items, auctionClusterId, this.buyers, this.users, this.currencies, this.languages, this.mfaTypes, this.timeZone);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.buyers, this.users, this.currencies, this.languages, this.mfaTypes, this.timeZone);
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('name', this.translate.instant('SHARED.NAME'));
    name.required = true;

    schema.fields.push(name
    );

    this.schema = schema;
  }

  deleteSelected() {
    this.spinner.show();
    this.auctionService.delete(this.itemIdToDelete).subscribe((auctionClusters) => {
      this.getData();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  enableDisableCluster = (e: any) => {
    const auctionCluster: AuctionCluster = e.row.data;
    this.clusterToDisable = auctionCluster;
    this.auctionService.enableDisable(this.clusterToDisable).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  exportCluster = (e: any) => {
    this.auctionService.export(e.row.data.auctionClusterId);
  }

  navigateToUsers = (e: any) => {
    this.router.navigate(['/platform/users'], { queryParams: { auctionCluster: e.row.data.auctionClusterId }});
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.auctionClusterId;
    this.confirmation.opened = true;
  }
}
