import { Component, ViewEncapsulation } from '@angular/core';

import { AuthService } from './shared/services/auth.service';
import { LanguagePreferenceService } from './shared/services/language-preference.service';
import { NotificationService } from './shared/services/notification.service';
import { Title } from '@angular/platform-browser';
import { locale } from 'devextreme/localization';
import DataGrid from "devextreme/ui/data_grid";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    './styles/dx.aucxis.kosmos.css',
    './styles/aucxis.style.css',
    '../../node_modules/jquery-ui/themes/base/all.css',
    '../../node_modules/devextreme/dist/css/dx.common.css',
    //'../../node_modules/devextreme/dist/css/dx.light.css', // old devextreme style
    '../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
    '../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
    '../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  constructor(
    languagePreferenceService: LanguagePreferenceService,
    titleService: Title,
    notificationService: NotificationService
  ) {
    languagePreferenceService.configure();
    // devexpress localization
    locale(localStorage.getItem('last-selected-language-code'));

    if (window.location.href.includes('efice')) {
      titleService.setTitle("Efice");
    }

    DataGrid.defaultOptions({
      options: {
        scrolling: {
          showScrollbar: "always",
          useNative: true
        },
        paging: {
          pageSize: 20
        }
      }
    });


    // JVH 2022-08-03: noticed a bug where the clientside storage for the user datagrid was set with a pagesize of 0
    // as a result, the grid tries to display 1000+ users, while the upper limit should be 200 and the default should be 20
    // this will fix this. Alternate solution is clearing localstorage
    var storage_users = localStorage.getItem('storage_users');
    if (storage_users != null && storage_users != '') {
      var userGrid = JSON.parse(storage_users);
      if (userGrid != null && userGrid.pageSize == 0) {
        userGrid.pageSize = 20;
        localStorage.setItem('storage_users', JSON.stringify(userGrid));
      }
    }
  }
} 
