import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from './web-api.service';
import { ApplicationSettings } from '../models/application-settings';
import { AuctionClusterRole } from '../models/auction-cluster-role';

@Injectable()
export class AuctionClusterRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctionclusterrole';
  }

  getAuctionClusterRoles(): Observable<Array<AuctionClusterRole>> {
    return this.webApiService.getList(this.apiPath);
  }

  getAuctionClusterRole(auctionClusterRoleId: number): Observable<AuctionClusterRole> {
    return this.webApiService.get(this.apiPath, auctionClusterRoleId);
  }

  save(auctionClusterRole: AuctionClusterRole): Observable<any> {
    return this.webApiService.save<AuctionClusterRole>(this.apiPath, auctionClusterRole);
  }

  edit(auctionClusterRole: AuctionClusterRole): Observable<any> {
    return this.webApiService.edit<AuctionClusterRole>(this.apiPath, auctionClusterRole.auctionClusterRoleId, auctionClusterRole);
  }

  delete(auctionClusterRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, auctionClusterRoleId);
  }
}
