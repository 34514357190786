import { AuctionCluster } from "../../platform/shared/models/auction-cluster";

export class User {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  isDeleted: boolean;
  isBlocked: boolean;
  platformRoleId: number;
  rolesString: string;
  existingBuyerVat: string;
  existingSupplierVat: string;
  existingAuctionClusterId: number;
  languageId: number;
  shoppingListSettings: string;
  auctionClusterRoles: Array<UserAuctionClusterRole> = [];
  buyerRoles: Array<UserBuyerRole> = [];
  supplierRoles: Array<UserSupplierRole> = [];
  supplyMail: boolean;
  supplyForecastMail: boolean;
  telNumber: string;
  sendMailTransactionSummary: boolean;
  sendSmsTransactionInfo: boolean;
  sendSmsTransactionSummary: boolean;
  platformRole: any;
  isSystemUser: boolean;
  mfaEnabled: boolean;
}

export class UserAuctionClusterRole {
  auctionClusterId: number;
  auctionCluster: AuctionCluster
  auctionId: number;
  auctionClusterRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
}

export class UserBuyerRole {
  buyerId: number;
  buyerRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
}

export class UserSupplierRole {
  supplierId: number;
  supplierRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
}
