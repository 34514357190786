import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

import { Report } from '../../../shared/models/report';

import{ IReportService } from '../../../shared/interfaces/report';

@Injectable()
export class SupplierReportService implements IReportService {

  private apiPath: string;
  private supplierId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  setId(id: number){
    this.supplierId = id;
  }

  getReports(supplierId: number, langCode: string): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${supplierId}/reports?langCode=${langCode}`);
  }

  save(report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + `supplierlevel/${this.supplierId}/reports`, report);
  }

  edit(report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + `supplierlevel/${this.supplierId}/reports`, report);
  }

  delete(supplierId: number, reportId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + `supplierlevel/${supplierId}/reports`, reportId);
  }

  //IReportservice

  getReport( reportId): Observable<Report> {
    return this.webApiService.getSingle(this.apiPath + `supplierlevel/${this.supplierId}/reports/${reportId}`);
  }

  saveReport(report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + `supplierlevel/${this.supplierId}/reports`, report);
  }

  editReport(report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + `supplierlevel/${this.supplierId}/reports`, report);
  }

  downloadExport(reportId: number, reportRequest: any): Observable<any> {
    const url = this.appSettings.reportingApiURL + '/api/v1/supplierlevel/' + this.supplierId + '/report/export?reportId=' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  requestDownload(reportId: number, reportRequest: any) {
    const url = this.apiPath + 'supplierlevel/' + this.supplierId + '/reports/requestdownload/' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  getStoredProcedureValuesForProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/supplierlevel/' + this.supplierId + '/reportfilter?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForNumberProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/supplierlevel/' + this.supplierId + '/reportfilter/number?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForStringProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/supplierlevel/' + this.supplierId + '/reportfilter/string?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForDateProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/supplierlevel/' + this.supplierId + '/reportfilter/date?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }
}
