import { Auction } from '../../auction/shared/models/auction';
import { AuctionClusterRole } from './auction-cluster-role';
import { UserAuctionClusterRole } from '../../platform/shared/models/user';

export class UserAuctionClusterItem extends UserAuctionClusterRole {

  filteredAuctions: Array<Auction> = [];
  filteredAuctionClusterRoles: Array<AuctionClusterRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}
