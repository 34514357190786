import { Injector, Component, OnInit, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import DataGrid from 'devextreme/ui/data_grid';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { WebApiService } from '../../shared/services/web-api.service';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { PrebidOnProductItemComponent } from './prebid-on-product-item.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';
import { ProductService } from '../shared/services/product.service';
import { SupplierService } from '../shared/services/supplier.service';

// models
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Language } from '../../shared/models/language';
import { ProductPropertyTypes, GridProperty, ProductProperty } from '../../auction/shared/models/product';
import { MasterData, MasterDataValue, MasterDataListField } from '../shared/models/master-data';
import { Supplier } from '../../shared/models/supplier';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { LookupTable, LookupTableField } from '../../shared/models/lookup-table';
import { ApplicationSettings } from '../../shared/models/application-settings';

export class MasterDataDropdown {
  value: any;
  label: string;
}

@Component({
  selector: 'prebid-on-product-component',
  templateUrl: 'prebid-on-product.component.html',
  styleUrls: ['./prebid-on-product.component.scss']
})
export class PrebidOnProductComponent extends FullListComponent<Lot, PrebidOnProductItemComponent> implements OnInit, OnDestroy {
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  lookupTableService: LookupTableService;
  masterDataService: MasterDataService;
  productService: ProductService;

  languages: Array<Language> = [];
  auctionClusterId: number;
  secondRestrictionMasterDataId: number;
  buyerSecondRestrictionMasterDataRowIds: Array<number>;
  productId: number;
  // prebidOnProduct: PrebidOnProduct = new PrebidOnProduct();
  prebidPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  comboBoxes: Array<any> = [];
  suppliers: Array<Supplier> = [];
  firstLoad = true;
  visible = false;
  isEditMode = false;
  errorMessage = null;
  JSON: any = JSON;
  model: Lot = new Lot();
  lookupTables: Array<LookupTable> = [];
  lang = 'nl-be';
  dataGridInstance: DataGrid;
  secondRestrictionLotPropertyFilled = false;
  productProperties: Array<ProductProperty> = [];
  prebidOnProductProperties: Array<GridProperty> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridColumns = [];
  gridData = [];

  @ViewChild('buyerPrebidOnProductForm') formComponent: DxFormComponent;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private languageService: LanguageService,
    private dataService: PrebidOnProductService,
    private supplierService: SupplierService,
    private translateService: TranslateService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Lot);
    this.getServices(route, appSettings, webApiService);

    this.lang = this.translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PREBID_ON_PRODUCT');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.lookupTableService = ServiceFactory.getLookupTableService(route, appSettings, webApiService) as LookupTableService;
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService) as MasterDataService;
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  open(auctionClusterId: number, productId: number, secondRestrictionMasterDataId: number, buyerSecondRestrictionMasterDataRowIds: Array<number>) {
    this.auctionClusterId = auctionClusterId;
    this.secondRestrictionMasterDataId = secondRestrictionMasterDataId;
    this.buyerSecondRestrictionMasterDataRowIds = buyerSecondRestrictionMasterDataRowIds;
    if (productId !== this.productId) {
      this.firstLoad = true;
    }
    this.productId = productId;

    this.visible = true;

    this.getData();
  }

  getData() {
    if (!this.auctionClusterId || !this.productId) {
      return;
    }
    this.spinner.show();
    forkJoin(
      this.dataService.getProductLots(this.id, this.auctionClusterId, this.productId),
      this.languageService.getLanguages(),
      this.productService.getProductProperties(this.productId),
      this.productService.getPrebidOnProductProperties(this.auctionClusterId, this.productId)
    ).subscribe(async (result: Array<any>) => {
      this.items = result[0];
      this.languages = result[1];
      // tslint:disable-next-line:no-magic-numbers
      this.productProperties = result[2];
      this.prebidOnProductProperties = result[3];
      if (this.firstLoad) {
        this.lookupTableService.getLookupTablesForReports(null, this.productId).subscribe(lt => {
          this.lookupTables = lt;
        });
        this.getMasterDataListsAndSuppliers();
        this.firstLoad = false;
      } else {
        await this.parseData();
      }
      this.generateForm();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  initModelOnNew() {
    this.model = new Lot();
    this.parseModel();
    this.isEditMode = false;
  }

  delete(lotId) {
    this.itemIdToDelete = lotId;
    this.deleteSelected();
  }

  edit(lotId) {
    this.isEditMode = true;
    this.spinner.show();
    this.dataService.getLot(this.id, this.auctionClusterId, lotId)
      .subscribe((res: Lot) => {
        this.model = res;
        this.parseModel();
        this.generateForm();

        this.model.lotProperties.filter(l => l.masterDataListId != undefined).forEach(lp => {
          var newValues = [];
          const lookupTableId = this.isProductWithLookupTable(lp.productPropertyId);
          if (lookupTableId > -1) {
            this.lookupTableService.getLookupTableForProductPropertyForReports(null, lp.productPropertyId, this.model)
              .subscribe(lookup => {
                if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
                  lookup.lookupTableRows.forEach(row => {
                    if (row.resultMasterDataListRowId) {
                      newValues.push(row.resultMasterDataListRowId);
                    }
                    else {
                      row.lookupTableValues.forEach(value => {
                        newValues.push(value.masterDataListRowId);
                      });
                    }
                  });
                }
              });
            console.log(lp);
            this.masterDataDropdownsFiltered[lp.masterDataListId.toString()] = newValues;
          }
        });

        this.model.lotProperties = this.model.lotProperties.filter(e => e.propertyTypeId !== undefined);
        this.sortArray(this.model.lotProperties, 'orderNumber', 1);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    return JSON.stringify(emptyTranslation);
  }


  dateChanged(property: any, dateTime: Moment) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    this.model.lotProperties[Number(matches[1])].dateTimeValue = moment(dateTime).toDate();
  }

  dateValue(property: any) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    return this.model.lotProperties[Number(matches[1])].dateTimeValue;
  }

  clearLotPropertyValue(lotProperty: LotProperty) {
    const lp = this.model.lotProperties.find(lp => lp.lotPropertyId === lotProperty.lotPropertyId);
    if (lp) {
      lp.masterDataListRowId = null;
    }
  }

  parseBeforeSave() {
    this.model.lotProperties.forEach(lotProperty => {
      if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE && lotProperty.dateTimeValue) {
        lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue);
      }

      if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
        const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
        if (field) {
          lotProperty.stringValue = field.data;
        }
      }
    });
  }

  onMasterDataValueChanged(e: any, data: any, property: LotProperty) {
    data.component.updateData(data.dataField, e.value);
    this.onPropertyChanged(property);
  }

  save() {
    this.parseBeforeSave();

    if (this.model.lotId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editLot(this.id, this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.initModelOnNew();
          this.errorMessage = null;
          this.spinner.hide();
          this.getData();
        },
          err => {
            if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'RESTRCTED_LOT_PROPERTY_VALUE') {
              this.errorMessage = this.translations.RESTRCTED_LOT_PROPERTY_VALUE;
              let lotPropertyName = this.model.lotProperties.find(_ => _.productPropertyId ===  err.error[0].ProductPropertyId).name;
              this.errorMessage += ' Lot property name: ' + lotPropertyName;
              this.errorService.show(this.errorMessage);
            } else {
              this.errorService.show(err);
            }
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveLotForProduct(this.id, this.auctionClusterId, this.productId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.initModelOnNew();
          this.errorMessage = null;
          this.spinner.hide();
          this.getData();
        },
          err => {
            if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'RESTRCTED_LOT_PROPERTY_VALUE') {
              this.errorMessage = this.translations.RESTRCTED_LOT_PROPERTY_VALUE;
              let lotPropertyName = this.model.lotProperties.find(_ => _.productPropertyId ===  err.error[0].ProductPropertyId).name;
              this.errorMessage += ' Lot property name: ' + lotPropertyName;
              this.errorService.show(this.errorMessage);
            } else {
              this.errorService.show(err);
            }
            this.spinner.hide();
          });
    }
  }

  parseModel() {
    if (this.model.lotProperties.length === 0) {
      this.prebidPropertyFields.forEach(ppf => {
        // check if already added
        const alreadyAddedProp = this.model.lotProperties.find(p => p.productPropertyId === ppf.productPropertyId);
        if (!alreadyAddedProp) {
          const newObj = new LotProperty();
          newObj.orderNumber = ppf.orderNumber;
          newObj.masterDataListFieldId = ppf.masterDataListFieldId;
          newObj.name = ppf.name;
          newObj.propertyTypeId = ppf.propertyTypeId;
          newObj.propertyTypeFormatId = ppf.propertyTypeFormatId;
          newObj.productPropertyId = ppf.productPropertyId;
          newObj.translatable = ppf.translatable;
          newObj.masterDataListId = ppf.masterDataListId;
          if (newObj.translatable) {
            newObj.stringValue = this.createEmtpyObjectForTranslatableFields();
          }
          this.model.lotProperties.push(newObj);
        }
      });
    } else {
      this.model.lotProperties.forEach(lp => {
        const field = this.prebidPropertyFields.find(ppf => ppf.productPropertyId === lp.productPropertyId);
        if (field) {
          lp.orderNumber = field.orderNumber;
          lp.masterDataListFieldId = field.masterDataListFieldId;
          lp.name = field.name;
          lp.propertyTypeId = field.propertyTypeId;
          lp.propertyTypeFormatId = field.propertyTypeFormatId;
          lp.translatable = field.translatable;
          lp.masterDataListId = field.masterDataListId;

          if (lp.propertyTypeId === ProductPropertyTypes.DATE && lp.dateTimeValue) {
            const serverReturnedTime = moment.utc(lp.dateTimeValue);
            lp.dateTimeValue = moment(serverReturnedTime).local();
          }
        }
      });
    }

    this.sortArray(this.model.lotProperties, 'orderNumber', 1);
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.productProperties.length; i += 1) {
      if (this.productProperties[i].productPropertyId === productPropertyId
        && this.productProperties[i].lookupTableId) {
        return this.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  onPropertyChanged(property: LotProperty) {
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      this.masterDataDropdownsFiltered[this.masterDataDropdowns[i].masterDataListId.toString()] = null;
      this.lookupTableService.getLookupTableForProductPropertyForReports(null, this.masterDataDropdowns[i].productPropertyId, this.model)
        .subscribe(lookup => {
          if (lookup) {
            var newValues = [];
            if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
              if (lookup && lookup.lookupTableType == 0) {
                lookup.lookupTableRows.forEach(row => {
                  if (row.resultMasterDataListRowId) {
                    newValues.push(row.resultMasterDataListRowId);
                  }
                  else {
                    row.lookupTableValues.forEach(value => {
                      newValues.push(value.masterDataListRowId);
                    });
                  }
                });
              }
            }
          }
          this.masterDataDropdownsFiltered[this.masterDataDropdowns[i].masterDataListId.toString()] = newValues;
        });
    }
  }

  getSuppliers() {
    this.supplierService.getSuppliers(this.auctionClusterId).subscribe(s => {
      this.suppliers = s;
    });
  }

  getLotPropertyDisplayValue(lotProperties: Array<LotProperty>, propField: any) {
    const lp = lotProperties.find(p => p.productPropertyId === propField.productPropertyId);

    if (lp) {
      return lp.displayValue;
    }

    return '-';
  }

  getMasterDataListsAndSuppliers() {
    const masterDataListIds: Array<number> = [];
    this.productProperties.forEach(property => {
      if (property.masterDataListId) {
        if (masterDataListIds.indexOf(property.masterDataListId) === -1) {
          masterDataListIds.push(property.masterDataListId);
        }
      }
    });

    this.supplierService.getSuppliers(this.auctionClusterId).subscribe(s => {
      this.suppliers = s;

      const observables = [];

      masterDataListIds.forEach(id => {
        observables.push(this.masterDataService.getMasterDataForReports(this.auctionClusterId, id, false)
          .pipe(map((result: MasterData) => result)));
      });

      forkJoin(
        observables
      ).subscribe(async res => {
        for await (var result of res) {
          this.masterDatas.push(result);
          const catalogMasterDataIds: Array<number> = [];
          this.masterDatas.forEach(masterData => {
            masterData.fields.forEach(masterDataListField => {
              if (masterDataListField.useFieldInCatalog) {
                catalogMasterDataIds.push(masterDataListField.masterDataListFieldId);
              }
            });
          });
        };
        await this.parseData();
      });
    });
  }

  getMasterDataFieldName(selection: GridProperty) {
    for (let i = 0; i < this.masterDatas.length; i += 1) {
      for (let j = 0; j < this.masterDatas[i].fields.length; j += 1) {
        if (this.masterDatas[i].fields[j].masterDataListFieldId === selection.masterDataListFieldId) {
          return this.getTranslation(this.masterDatas[i].fields[j].name);
        }
      }
    }
  }

  async parseData() {
    const prebidPropertyFields = [];
    this.sortArray(this.prebidOnProductProperties, 'orderNumber', 1);
    const prebidSelection = this.prebidOnProductProperties;
    prebidSelection.forEach(selection => {
      let fieldObj = null;

      const productProperty = this.productProperties.find(p => p.productPropertyId === selection.productPropertyId);
      if (productProperty) {
        let name = this.getTranslation(productProperty.name);
        if (productProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
          const fieldName = this.getMasterDataFieldName(selection);
          name = `${name} (${fieldName})`;
        }
        fieldObj = {
          ...selection,
          name,
          propertyTypeId: productProperty.propertyTypeId,
          propertyTypeFormatId: productProperty.propertyTypeFormatId,
          translatable: productProperty.translatable,
          masterDataListId: productProperty.masterDataListId
        };
      }

      if (fieldObj) {
        prebidPropertyFields.push(fieldObj);
      }
    });

    this.prebidPropertyFields = prebidPropertyFields;

    for await (var i of this.items) {
      for await (var pp of this.prebidPropertyFields) {
        const lotProperty = i.lotProperties.find(lp => lp.productPropertyId === pp.productPropertyId);
        if (lotProperty) {
          const displayLotProp = JSON.parse(JSON.stringify(lotProperty));
          displayLotProp.displayValue = await this.findPropertyValue(lotProperty, pp);
          if (!i.displayLotProperties) {
            i.displayLotProperties = [];
          }
          i.displayLotProperties.push(displayLotProp);
        }
      };
    };

    this.parseModel();
    this.generateTable();
  }

  async getMasterDataValue(lotProperty: LotProperty, masterdataListId: number): Promise<string> {
    var masterDataListRowId = lotProperty.masterDataListRowId;
    if (masterDataListRowId == undefined || masterdataListId == undefined) {
      return '';
    }
    var result = await this.masterDataService.getMasterDataValueForDropdown(masterdataListId, this.auctionClusterId, this.translateService.currentLang, masterDataListRowId).toPromise();
    if (result.data && result.data.length > 0) {
      return result.data[0].label;
    }
    return '';
  }


  async findPropertyValue(lotProperty: LotProperty, prop: any): Promise<any> {
    if (lotProperty.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, prop.propertyTypeFormatId);
    } else if (lotProperty.decimalValue) {
      return lotProperty.decimalValue;
    } else if (lotProperty.intValue) {
      return lotProperty.intValue;
    } else if (lotProperty.stringValue) {
      if (prop.translatable) {
        return this.getTranslation(lotProperty.stringValue);
      }
      return lotProperty.stringValue;
    } else if (prop.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.masterDataListRowId) {
      // return lotProperty.masterDataListRowId;
      return await this.getMasterDataValue(lotProperty, prop.masterDataListId);
    }

    return '';
  }


  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteLot(this.id, this.auctionClusterId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    this.schema = schema;
  }

  close() {
    this.visible = false;
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = () => {
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    const items = [];
    const columnsArray = [];

    this.prebidPropertyFields.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columnsArray.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columnsArray.includes(columnName));
      }
      columnsArray.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    this.items.forEach(row => {
      let item = {};
      columnsArray.forEach((column, i) => {
        item[column] = row.displayLotProperties[i].displayValue;
      });

      item['__item__'] = row;

      items.push(item);
    });
    this.dataGridInstance.option('dataSource', items);
  }

  generateFields = () => {
    const generatedItems = [];
    this.masterDataDropdowns = [];
    this.model.lotProperties = this.model.lotProperties.filter(e => e.propertyTypeId !== undefined);
    this.sortArray(this.model.lotProperties, 'orderNumber', 1);

    return new Promise((resolve, reject) => {

      this.model.lotProperties.forEach((field, i) => {
        if (field.propertyTypeId === 1 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].intValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1
              }
            }
          );
        } else if (field.propertyTypeId === 2 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].decimalValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0.0',
                min: 0,
                step: 0.1
              }
            }
          );
        } else if (field.propertyTypeId === 3 && !field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name }
            }
          );
        } else if (field.propertyTypeId === 3 && field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name },
              template: 'translatableField'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 11 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'fullDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 12 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 13 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 14 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 15 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 5 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].masterDataListRowId',
              label: { text: field.name },
              name: this.model.lotProperties[i].masterDataListId,
              template: 'masterdataTemplate'
            }
          );
          this.model['lotProperties[' + i + '].masterDataListRowId'] = this.model.lotProperties[i].masterDataListRowId;
          this.masterDataDropdowns.push(this.model.lotProperties[i]);
        } else if (field.propertyTypeId === 7 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].booleanValue',
              editorType: 'dxCheckBox',
              label: { text: field.name }
            }
          );
        }
      });

      const intervalResolve = setInterval(() => {
        if (generatedItems.length >= this.model.lotProperties.length) {
          resolve(generatedItems);
          clearInterval(intervalResolve);
        }
      }, 50);

    });
  }

  onOpenedBoxes = (e: any, index: number) => {
    e.component.option('dataSource', this.comboBoxes[index]);
  }

  generateForm = async () => {
    let items = [];
    let generatedItems = null;
    this.formComponent.instance.option('items', items);
    const intervalLotProperties = setInterval(async () => {
      items = [];
      generatedItems = null;
      if (this.model.lotProperties.length > 0) {
        clearInterval(intervalLotProperties);
        generatedItems = await this.generateFields();
        items.push(
          {
            itemType: 'group',
            caption: '',
            items: generatedItems
          });
        this.formComponent.instance.option('items', items);
        this.spinner.hide();
      }
    }, 50);
  }


  sortArray(array, property, direction) {
    direction = direction || 1;
    array.sort(function compare(a, b) {
      let comparison = 0;
      if (a[property] > b[property]) {
        comparison = 1 * direction;
      } else if (a[property] < b[property]) {
        comparison = -1 * direction;
      }
      return comparison;
    });
    return array; // Chainable
  }

  checkIsSecondRestrictionLotPropertyFilled() {
    this.secondRestrictionLotPropertyFilled = true;
    if (this.secondRestrictionMasterDataId) {
      let secondRestrictionLotProperty = this.model.lotProperties.find(_ => _.masterDataListId === this.secondRestrictionMasterDataId);
      if (!secondRestrictionLotProperty || !secondRestrictionLotProperty.masterDataListRowId) {
        this.secondRestrictionLotPropertyFilled = false;
      } 
    }
  }

  filterMasterDataListBySecondRestriction(masterDataList) {
    let unfilteredValues = masterDataList.values;
    if (this.secondRestrictionMasterDataId) {
      let secondRestrictedProductPropertyId = this.model.lotProperties.find(_ => _.masterDataListId === this.secondRestrictionMasterDataId).productPropertyId;
      if (masterDataList.productPropertyId === secondRestrictedProductPropertyId) {
        return masterDataList.values.filter(item => !this.buyerSecondRestrictionMasterDataRowIds.includes(item.value))
      } 
    } 

    return unfilteredValues;
  }

  add() { }
}
