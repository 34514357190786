import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { MasterDataService } from '../shared/services/master-data.service';
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';

// models
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataValue, FieldType } from '../shared/models/master-data';
import { ProductPropertyType } from '../shared/models/product';

import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'master-data-list-component',
  templateUrl: 'master-data-list.component.html',
  styleUrls: ['./master-data-list.component.scss']
})
export class MasterDataListComponent extends ItemDetailsComponent<MasterData> implements OnInit, OnDestroy {

  @ViewChild('masterDataName') masterDataName;
  @ViewChild('masterDataDescription') masterDataDescription;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild('masterDataListForm', { read: DxFormComponent}) masterDataListForm: DxFormComponent;

  auctionClusterId: number;
  languages: Array<Language> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  productFieldTypes: Array<FieldType> = [];
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: MasterDataService,
    private productPropertyTypeService: ProductPropertyTypeService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new MasterData();
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(masterDataLists: Array<MasterData>, masterDataListId: number, languages: Array<Language>) {

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = masterDataLists;
    this.languages = languages;

    this.productPropertyTypeService.getProductProperties().subscribe(types => {
      this.productPropertyTypes = types;
    });

    this.dataService.getFieldTypes(this.auctionClusterId).subscribe(types => {
      this.productFieldTypes = types;
    });

    if (masterDataListId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getMasterDataListForEdit(masterDataListId, this.auctionClusterId, null)
        .subscribe((res: MasterData) => {
          this.model = res;

          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new MasterData();
      this.isEditMode = false;
      this.isOpened = true;
      this.model.useInOrder = false;

      this.createEmtpyObjectForTranslatableFields();
    }

    // this.masterDataName.elementFocus();
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    this.model.name = this.model.description = JSON.stringify(emptyTranslation);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {

    this.model.name = this.masterDataName.data;
    this.model.description = this.masterDataDescription.data;
    if (!this.model.useInOrder) {
      this.model.orderPriority = null;
    }

    if (this.model.masterDataListId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editMasterDataList(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.addValues(this.model);
          this.model = new MasterData();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.addValues(this.model);
          this.model = new MasterData();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  saveWithoutClose() {
    this.model.name = this.masterDataName.data;
    this.model.description = this.masterDataDescription.data;

    if (!this.model.useInOrder) {
      this.model.orderPriority = null;
    }

    if (this.model.masterDataListId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editMasterDataList(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.addValues(this.model);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.addValues(this.model);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  addValues(model: MasterData) {
    model = this.verificationRowValues(model);
    this.dataService.editMasterDataList(model)
      .subscribe((res: any) => {
        this.model = res;
      },
        error => {
          this.errorMessage = this.errorService.toString(error);
          this.spinner.hide();
        });
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  verificationRowValues(masterData: MasterData) {

    masterData.rows.forEach((row, rowIndex) => {
      const values: Array<MasterDataValue> = new Array();
      const length = row.values.length;
      masterData.fields.forEach((field, index) => {
        const value = new MasterDataValue();
        if (index < length && row.values[index].masterDataListValueId !== undefined) {
          value.masterDataListValueId = row.values[index].masterDataListValueId;
          value.dateTimeValue = row.values[index].dateTimeValue;
          value.decimalValue = row.values[index].decimalValue;
          value.intValue = row.values[index].intValue;
          value.masterDataListFieldId = row.values[index].masterDataListFieldId;
          value.stringValue = row.values[index].stringValue;
          value.imageValue = row.values[index].imageValue;
          value.booleanValue = row.values[index].booleanValue;
          value.urlValue = row.values[index].urlValue;
        } else {
          value.dateTimeValue = null;
          value.decimalValue = null;
          value.intValue = null;
          value.masterDataListFieldId = field.masterDataListFieldId;
          value.masterDataListValueId = 0;
          value.stringValue = '';
          value.imageValue = '';
          value.booleanValue = false;
          value.urlValue = '';
        }
        values.push(value);
      });
      row.values = values;
    });
    return masterData;
  }

  public onCancel() {
    this.masterDataName.resetLanguageButtons();
    this.masterDataDescription.resetLanguageButtons();
    super.onCancel();
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.masterDataName.data);
      this.masterDataListForm.instance.updateData(fieldName, this.masterDataName.data);
    } else if (fieldName === 'description') {
      this.masterDataListForm.instance.updateData(fieldName, this.masterDataDescription.data);
    }
  }

  onFieldDataChanged(e: any) {
    if (e.dataField === 'orderPriority' && !this.model.orderPriority) {
      this.model.orderPriority = 0;
    }

    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnMasterDataListSubmit')[0].removeAttribute('disabled');
      }
      else {
        document.getElementsByName('btnMasterDataListSubmit')[0].setAttribute('disabled', 'disabled');

      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnMasterDataListSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMasterDataListSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
