import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import * as _moment from 'moment';

// general services
import { LanguageService } from '../../services/language.service';

// components
import { ItemListComponent } from '../item-list/item-list.component';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// services
import { PublicDataService } from '../../services/public-data-service';

// models
import { SupplyForecastPublicUrk, SpeciesAmounts, AuctionSupplierAmounts } from '../../models/supply-forecast-public-urk';
import { PublicAuctionCluster } from '../../models/public-auction-cluster';
import { PublicAuction } from '../../models/public-auction';
import { PublicCatalog } from '../../models/public-catalog';
import { FormatPipe } from '../../pipes/format.pipe';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class MasterDataDropdown {
  rowId: number;
  value: string;
}

@Component({
  selector: 'public-supply-forecast-urk-component',
  templateUrl: 'public-supply-forecast-urk.component.html',
  styleUrls: ['./public-supply-forecast-urk.component.scss']
})
export class PublicSupplyForecastURKComponent extends ItemListComponent<SupplyForecastPublicUrk> implements OnInit, OnDestroy {

  auctions: Array<PublicAuction> = [];
  catalogs: Array<PublicCatalog> = [];
  auctionId: number = null;
  date: Date = new Date();
  lang = 'nl-be';
  formatDate: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private dataService: PublicDataService,
    private formatPipe: FormatPipe
  ) {
    super(injector, SupplyForecastPublicUrk);
    this.title.set('CONTENT.SUPPLY_FORECAST');
    this.lang = this.translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.formatDate = formatPipe;
  }

  ngOnInit() {
    this.dataService.getPublicAuctions().subscribe(auctions => {
      this.auctions = auctions;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.dataService.getPublicSupplyForecast(dateFormatted, this.auctionId).subscribe(res => {
      this.model = res;

      // Convert UTC dates to local
      this.model.auctionSupplierAmounts.forEach(sa => {
        if (sa.exitOpenTime)
          sa.exitOpenTime = moment.utc(sa.exitOpenTime).locale(this.lang).local();
        if (sa.saleTime)
          sa.saleTime = moment.utc(sa.saleTime).locale(this.lang).local();
        if (sa.sortTime)
          sa.sortTime = moment.utc(sa.sortTime).locale(this.lang).local();
      });

      this.model.supplierLandingTimes.forEach(sl => {
        if (sl.landingTime) {
          sl.landingTime = moment.utc(sl.landingTime).locale(this.lang).local().format();
        }
      });
    });
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  createDataSchema() {
  }

  timeField = (item: any) => {
    if (item.value === null) {
      return '';
    } else {
      return moment(item.value).format('HH:mm');
    }
  }

  amountField = (item: any) => {
    return this.formatDate.transform(item.value);
  }

  translateField = (item: any) => {
    const label = this.getTranslation(item.value);
    return label;
  }
}
