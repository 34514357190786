import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auction } from '../../../auction/shared/models/auction';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { IAuctionService } from "../../../shared/interfaces/auction";

@Injectable()
export class AuctionService implements IAuctionService{

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  getAuctions(supplierId: number): Observable<Array<Auction>> {
    return this.webApiService.getList(this.apiPath + '/' + supplierId + '/auctioncluster/auction');
  }

   //IAuctionService

   private supplierId: number;

   setId(id: number) {
     this.supplierId = id;
   }
 
   getAuctionsForReports(auctionClusterId: number): Observable<Array<Auction>> 
   {
    return this.webApiService.getList(this.apiPath + '/' + this.supplierId + '/auctioncluster/auction');
   }
}
