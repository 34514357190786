import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// global services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { SupplierUserComponent } from './supplier-user.component';

import notify from 'devextreme/ui/notify';

// models
import { SupplierUser } from '../shared/models/supplier-user';
import { Language } from '../../shared/models/language';

// services
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Supplier } from '../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';
import { SupplierUserService } from '../shared/services/supplier-user.service';
import { SupplierService } from '../shared/services/supplier.service';

class UserFilter {
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
}


@Component({
  selector: 'supplier-users-component',
  templateUrl: 'supplier-users.component.html',
  styleUrls: ['./supplier-users.component.scss']
})
export class SupplierUsersComponent extends FullListComponent<SupplierUser, SupplierUserComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: SupplierUserComponent;

  languages: Array<Language> = [];

  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  userFilter = new UserFilter();

  @ViewChild('disable') disable: ConfirmationComponent;

  constructor(
    protected injector: Injector,
    private dataService: SupplierUserService,
    private languageService: LanguageService,
    private supplierService: SupplierService,
    private supplierRoleService: SupplierRoleService,
  ) {
    super(injector, SupplierUser);
    this.title.set('SHARED.USER_MANAGEMENT');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('USERS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {

    this.spinner.show();
    forkJoin(
      this.dataService.getUsersFiltered(this.id, this.userFilter),
      this.languageService.getLanguages(),
      this.supplierService.getSuppliers(),
      this.supplierRoleService.getSupplierRolesForSupplier(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.suppliers = result[2];
      this.supplierRoles = result[3];

      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:disable:no-magic-numbers

  edit = (e: any) => {
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, userId, this.languages, this.suppliers, this.supplierRoles);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<SupplierUser>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const isActive = new ImportField('isActive', this.translate.instant('SHARED.IS_ACTIVE'));
    isActive.required = true;

    const email = new ImportField('email', this.translate.instant('SHARED.EMAIL'));
    email.required = true;

    const firstname = new ImportField('firstname', this.translate.instant('SHARED.FIRST_NAME'));
    firstname.required = true;

    const lastname = new ImportField('lastname', this.translate.instant('SHARED.LAST_NAME'));
    lastname.required = true;

    schema.fields.push(isActive, email, firstname, lastname,
    );

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }

  add() { }
}
