import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ILookupTableService } from '../../../shared/interfaces/lookup-table';
import { LookupTable } from '../../../shared/models/lookup-table';
import { Lot } from '../../../shared/models/lot';

@Injectable()
export class LookupTableService implements ILookupTableService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(id: number) {
    this.buyerId = id;
  }

  //ILookupTableService

  getLookupTableForProductPropertyForReports(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<LookupTable> {
    return this.webApiService.save(`${this.apiPath}/${this.buyerId}/lookuptable/productproperty/${productPropertyId}`, lot);
  }

  getLookupTablesForReports(auctionClusterId: number, productId: number): Observable<Array<LookupTable>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/lookuptable/product/${productId}`);
  }
}
