import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RequestPasswordReset } from '../shared/models/request-password-reset';
import { AuthService } from '../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent implements OnInit, AfterViewInit {

  @ViewChild('email') email: ElementRef;

  model = new RequestPasswordReset();
  errorMessage: string;
  successMessage: string;

  constructor(private authService: AuthService, private translateService: TranslateService) {}

  ngOnInit() {
    this.model.domain = document.location.protocol + '//' + document.location.host;
  }

  onSubmit() {
    this.authService.requestPasswordReset(this.model).subscribe(_ => {
      this.translateService.get('LOGIN.PASSWORD_REQUEST_SENT', { email: this.model.email }).subscribe((res: string) => {
        this.successMessage = res;
      });
      this.errorMessage = undefined;
      this.model.email = '';
    }, error => {
      this.translateService.get("LOGIN." + error.error.error, { email: this.model.email }).subscribe((res: string) => {
        this.errorMessage = res;
        this.successMessage = undefined;
      });
    });
  }

  ngAfterViewInit() {
    this.email.nativeElement.focus();
  }
}
