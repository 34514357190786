import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';

// models
import { ActivatedRoute } from '@angular/router';
import { Language } from '../../models/language';
import { SimpleListComponent } from '../simple-list/simple-list.component';
import { MasterDataGridSelection } from '../../models/masterdatagridselection';
import { LanguageService } from '../../services/language.service';
import { MasterData } from '../../models/master-data';
import { empty } from 'rxjs';

@Component({
  selector: 'masterdata-grid-component',
  templateUrl: 'masterdata-grid.component.html',
  styleUrls: ['./masterdata-grid.component.scss']
})

export class MasterDataGridComponent extends SimpleListComponent implements OnInit {
  items: Array<MasterDataGridSelection> = [];
  masterData: Array<MasterData> = [];
  currentItem: MasterDataGridSelection;
  isOrderNeeded = true;
  languages: Array<Language> = [];
  fieldTypes: Array<any>;

  @Input('data')
  set data(value: Array<MasterDataGridSelection>) {
    this.items = value;
    this.sortList();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.fieldTypes = [];
    
    for (let i = 0; i < this.systemListTypes.length; i++) {
      let list = this.masterData.find(m => m.systemListType == this.systemListTypes[i]);
      if (list == null) {
        continue;
      }

      list.fields.filter(f => f.fieldType > 0).forEach(item => {
        this.fieldTypes.push({
          id: item.masterDataListFieldId,
          description: `${this.languageService.getTranslatableText(list.description)} - ${this.languageService.getTranslatableText(item.name)}`
        });
      });
    }
  }

  @Input() auctionClusterId: number;
  @Input() systemListTypes: number[];
  @Input() masterDataGridSelectionType: number;
  @Output() dataChanged = new EventEmitter<boolean>();
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  get selectedFields(): Array<MasterDataGridSelection> {
    if (this.items != null) {
      return this.items.filter(i => i.masterDataGridSelectionType == this.masterDataGridSelectionType);
    }
    return [];
  }


  ngOnInit() { // tslint:disable-line:no-empty
  }

  edit(property: MasterDataGridSelection, event: Event) {
    this.currentItem = property;
    this.popup.visible = true;
  }

  getMasterDataFieldValue(fieldId: number): string {
    for (let i = 0; i < this.systemListTypes.length; i++) {
      let list = this.masterData.find(m => m.systemListType == this.systemListTypes[i]);
      if (list != null) {
        let field = list.fields.find(f => f.masterDataListFieldId == fieldId);
        if (field != null) {
          return `${this.languageService.getTranslatableText(list.description)} - ${this.languageService.getTranslatableText(field.name)}`;
        }
      }
    }
    return '';
  }

  setOrderNumbers() {
    this.sortList();
    this.items.forEach((item, i) => {
      item.orderNumber = i;
    });
  }

  add() {
    var emptySelection = new MasterDataGridSelection();
    emptySelection.masterDataGridSelectionType = this.masterDataGridSelectionType;
    emptySelection.auctionClusterId = this.auctionClusterId;
    this.currentItem = emptySelection;
    this.popup.visible = true;
  }

  delete(property: MasterDataGridSelection) {
    this.items.splice(this.items.indexOf(property), 1);
  }

  onSubmit() {
    if (this.selectedFields.find(i => i.masterDataListFieldId == this.currentItem?.masterDataListFieldId) == null) {
      this.items.push(this.currentItem);
    }

    this.setOrderNumbers();
    this.dataChanged.emit();
    this.popup.visible = false;
  }

  onCancel() {
    this.popup.visible = false;
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.selectedFields);
    this.sortList();
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.selectedFields);
    this.sortList();
  }

  sortList() {
    if (this.items != null) {
      this.items.sort((a, b) => {
        return a.orderNumber - b.orderNumber;
      });
    }
  }
}
