import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../../shared/services/web-api.service';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { PlatformPermission } from '../models/platform-permission';

@Injectable()
export class PlatformPermissionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'platformpermission';
  }

  getPlatformPermissions(langCode: string): Observable<Array<PlatformPermission>> {
    return this.webApiService.getList(this.apiPath + `?langCode=${langCode}`);
  }
}