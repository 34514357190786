<div class="non-grouping-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
      <form #detailsForm="ngForm">
        <section class="form-block">
          <div class="form-group">
            <label for="productProperty">{{ 'PRODUCT.PROPERTY_NAME' | translate }}</label>
              <div class="select">
                <select id="productProperty" name="productProperty" [(ngModel)]="model.productPropertyId" (ngModelChange)="onMasterDataChanged()" required>
                  <option></option>
                  <option *ngFor="let property of productProperties" [ngValue]="property.productPropertyId">{{property.productPropertyNameText}} </option>
                </select>
              </div>
          </div>

          <div class="form-group" *ngIf="isMasterData">
            <label for="masterDataField" class="first-child">{{ 'SHARED.MASTER_DATA_FIELD' | translate }}</label>
              <div class="select">
                <select id="masterDataField" name="masterDataField" [(ngModel)]="model.masterDataListFieldId" required>
                  <option></option>
                  <option *ngFor="let field of masterDataListFields" [ngValue]="field.masterDataListFieldId">{{ field.name | translateJson }}</option>
                </select>
              </div>
          </div>

          <div class="form-group">
            <label for="actionOnGrouping" class="first-child">{{ 'SHARED.ACTION_ON_GROUPING' | translate }}</label>
            <div class="radio-group" style="margin-top: 3px;">
              <div *ngFor="let action of groupingActions; let i = index;" style="margin-bottom: 5px;">
                <div *ngIf="!isImage() && ((action.value != 2 && action.value != 7) || (action.value == 2 && isAgregateVisible()))">
                  <input style="cursor:pointer;" type="radio" name="{{'action' + i}}" id="{{'action' + i}}" [(ngModel)]="model.actionOnGrouping" [value]="action.value" [checked]="model.actionOnGrouping === action.value" />
                  <label style="padding:0.35rem; cursor:pointer;" for="{{'action' + i}}">{{ action.name | translate }}</label>
                </div>
                <div *ngIf="((action.value == 0 ||action.value == 4 ||action.value == 7)   && isImage())">
                  <input style="cursor:pointer;" type="radio" name="{{'action' + i}}" id="{{'action' + i}}" [(ngModel)]="model.actionOnGrouping" [value]="action.value" [checked]="model.actionOnGrouping === action.value" />
                  <label style="padding:0.35rem; cursor:pointer;" for="{{'action' + i}}">{{ action.name | translate }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="model.actionOnGrouping === 1" [ngClass]="{ 'clr-error': fixedTextEntry.dirty && fixedTextEntry.invalid }">
            <label for="fixedTextEntry" class="first-child">{{ 'PRODUCT.PROPERTY.FIXED_TEXT_ENTRY' | translate }}</label>
            <div>
              <div class="inner-input">
                <input type="text" id="fixedTextEntry" style="border: none;" name="fixedTextEntry" [(ngModel)]="model.fixedTextEntry" #fixedTextEntry="ngModel" required />
              </div>
              <clr-control-error *ngIf="fixedTextEntry.dirty && fixedTextEntry.invalid">{{ 'PRODUCT.PROPERTY.FIXED_TEXT_ENTRY' | translate }}</clr-control-error>
            </div>
          </div>

          <div class="form-group" *ngIf="model.actionOnGrouping === 2">
            <label class="clr-control-label"></label>
            <div class="radio-group">
              <div *ngFor="let aggregation of aggregationTypes; let i = index;" style="margin-bottom: 5px;">
                <input style="cursor:pointer;" type="radio" name="{{'aggregation' + i}}" id="{{'aggregation' + i}}" [(ngModel)]="model.aggregateType" [value]="aggregation.value" [checked]="model.aggregateType === aggregation.value" />
                <label style="padding:0.35rem; cursor:pointer;" for="{{'aggregation' + i}}">{{ aggregation.name | translate }}</label>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="model.actionOnGrouping === 3 || model.actionOnGrouping === 5" [ngClass]="{ 'clr-error': concatenationSeparator.dirty && concatenationSeparator.invalid }">
            <label for="concatenationSeparator">{{ 'PRODUCT.PROPERTY.CONCATENATION_SEPARATOR' | translate }}</label>
            <div>
              <div class="inner-input">
                <input type="text" id="concatenationSeparator" style="border: none;" name="concatenationSeparator" [(ngModel)]="model.concatenationSeparator" #concatenationSeparator="ngModel" required />
              </div>
              <clr-control-error *ngIf="concatenationSeparator.dirty && concatenationSeparator.invalid">{{ 'PRODUCT.PROPERTY.CONCATENATION_SEPARATOR_VALIDATION' | translate }}</clr-control-error>
              <clr-control-helper>{{ 'PRODUCT.PROPERTY.CONCATENATION_NEW_LINE_HINT' | translate }}</clr-control-helper>
            </div>
          </div>
        </section>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
