import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ApplicationSettings } from '../models/application-settings';
import { Language } from '../models/language';
import { BehaviorSubject, map, Observable, throwError, catchError, of } from 'rxjs';

@Injectable()
export class ConfigService {
  public config: ApplicationSettings;
  private http: HttpClient;

  constructor(backend: HttpBackend) { 
    this.http = new HttpClient(backend);
  }

  load() {
    return new Promise((resolve: (result: any) => void, reject: (reason: any) => void) => {
      const promise = this.http.get(`aucxis.config.json?d=${Date.now()}`).toPromise();
      promise.then((data: ApplicationSettings) => { // tslint:disable-line:no-floating-promises
        this.config = data;
          this.parse();
          resolve(null);
      }).catch(error => reject(error));
    });
  }


  parse(): void {
    const href = window.location.href;

    let defaultUrl = '';
    let defaultClockUrl = '';
    let defaultNotificationUrl = '';
    let defaultReportingApiUrl = '';
    let defaultPrivacyPolicyUrl = '';
    let defaultValidateEmail = true;
    let defaultAuthZeroSettings = null;
    let defaultExpirationTimeMinutes = 15;
    let defaultExpirationTimeDays = 1;
    let defaultCheckLogoutIntervalMinutes = 1;
    let defaultExpirationBufferTimeMinutes = 5;

    this.config.baseURLs.forEach(url => {
      //Parse relative URLs
      url.apiurl = this.parseRelativeURL(url.apiurl);
      url.clockUrl = this.parseRelativeURL(url.clockUrl);
      url.notificationUrl = this.parseRelativeURL(url.notificationUrl);
      url.reportingApiUrl = this.parseRelativeURL(url.reportingApiUrl);

      if (href.indexOf(url.weburl) !== -1) {
        // Save base URL if exists
        this.config.baseURL = url.apiurl;
        this.config.clockURL = url.clockUrl;
        this.config.reportingApiURL = url.reportingApiUrl;
        this.config.privacyPolicyUrl = url.privacyPolicyUrl;
        this.config.validateEmail = url.validateEmail;
        this.config.authZeroSettings = url.authZeroSettings;
        this.config.notificationURL = url.notificationUrl;
        this.config.expirationTimeMinutes = url.expirationTimeMinutes;
        this.config.expirationTimeDays = url.expirationTimeDays;
        this.config.checkLogoutIntervalMinutes = url.checkLogoutIntervalMinutes;
        this.config.expirationBufferTimeMinutes = url.expirationBufferTimeMinutes;
        console.log('Settings active for ' + url.weburl);
      } else if (url.weburl === '*') {
        // Save default URL
        defaultUrl = url.apiurl;
        defaultClockUrl = url.clockUrl;
        defaultReportingApiUrl = url.reportingApiUrl;
        defaultPrivacyPolicyUrl = url.privacyPolicyUrl;
        defaultValidateEmail = url.validateEmail;
        defaultAuthZeroSettings = url.authZeroSettings;
        defaultNotificationUrl = url.notificationUrl;
        defaultExpirationTimeMinutes = url.expirationTimeMinutes;
        defaultExpirationTimeDays = url.expirationTimeDays;
        defaultCheckLogoutIntervalMinutes = url.checkLogoutIntervalMinutes;
        defaultExpirationBufferTimeMinutes = url.expirationBufferTimeMinutes;
      }
    });

    // If no base URL is found, use default URL (*);
    if (this.config.baseURL === '' || this.config.baseURL === undefined) {
      this.config.baseURL = defaultUrl;
    }

    // If no clock URL is found, use default clock URL (*);
    if (this.config.clockURL === '' || this.config.clockURL === undefined) {
      this.config.clockURL = defaultClockUrl;
    }

    // If no reporting URL is found, use default clock URL (*);
    if (this.config.reportingApiURL === '' || this.config.reportingApiURL === undefined) {
      this.config.reportingApiURL = defaultReportingApiUrl;
    }

    // If no privacy policy URL is found, use default clock URL (*);
    if (this.config.privacyPolicyUrl === '' || this.config.privacyPolicyUrl === undefined) {
      this.config.privacyPolicyUrl = defaultPrivacyPolicyUrl;
    }

    // If no privacy policy URL is found, use default clock URL (*);
    if (this.config.validateEmail === undefined) {
      this.config.validateEmail = defaultValidateEmail;
    }

    // If no Auth Zero Settings is found, use Auth Zero Settings (*);
    if (!this.config.authZeroSettings) {
      this.config.authZeroSettings = defaultAuthZeroSettings;
    }

    // If no expirationTimeMinutes is found, use default (*);
    if (this.config.expirationTimeMinutes === 0 || this.config.expirationTimeMinutes === undefined) {
      this.config.expirationTimeMinutes = defaultExpirationTimeMinutes;
    }

    // If no expirationTimeDays is found, use default (*);
    if (this.config.expirationTimeDays === 0 || this.config.expirationTimeDays === undefined) {
      this.config.expirationTimeDays = defaultExpirationTimeDays;
    }

    // If no RefreshAccessBufferMinutes is found, use default (*);
    if (this.config.checkLogoutIntervalMinutes === 0 || this.config.checkLogoutIntervalMinutes === undefined) {
      this.config.checkLogoutIntervalMinutes = defaultCheckLogoutIntervalMinutes;
    }

    if (this.config.expirationBufferTimeMinutes === 0 || this.config.expirationBufferTimeMinutes === undefined) {
      this.config.expirationBufferTimeMinutes = defaultExpirationBufferTimeMinutes;
    }

    // If no notification URL is found, use default (*);
    if (this.config.notificationURL === '' || this.config.notificationURL === undefined) {
      this.config.notificationURL = defaultNotificationUrl;
    }
    // Build full URLs for each service endpoint
    this.config.adminApi = this.config.baseURL + this.config.adminApi;

    // Fetch available languages from the API
    this.getLanguages(this.config.adminApi + 'language');

    console.log('applicationCode: ' + this.config.applicationCode);
    console.log('baseURL: ' + this.config.baseURL);
    console.log('adminApi: ' + this.config.adminApi);
    console.log('notificationURL: ' + this.config.notificationURL);
    console.log('clockURL: ' + this.config.clockURL);
    console.log('reportingApiURL: ' + this.config.reportingApiURL);
    console.log('privacyPolicyUrl: ' + this.config.privacyPolicyUrl);
    console.log('validateEmail: ' + this.config.validateEmail);
  }

  parseRelativeURL(url: string) {
    if(url && url.indexOf("http") == -1) {
      return new URL(url, window.location.href).href;
    }
    return url;
  }

  private getLanguages(path: string) {
    this.http.get(path).subscribe((languages: Array<Language>) => {
      this.config.languages = languages;
    });
  }

  // Get Clock Service URL from Clock UI config
  getClockServiceUrl(clockUIConfig: any): string {
    const href = window.location.href;

    let defaultSocketUrl = '';
    let socketUrl = '';

    clockUIConfig.baseURLs.forEach(url => {
      if (href.indexOf(url.webUrl) !== -1) {
        // Save base URL if exists
        socketUrl = url.socketUrl;
      } else if (url.webUrl === '*') {
        // Save default URL
        defaultSocketUrl = url.socketUrl;
      }
    });

    // Use default Socket URL if Socket URL is missing for currently accessed URL
    if (socketUrl === '' || socketUrl === undefined) {
      socketUrl = defaultSocketUrl;
    }

    return socketUrl;
  }
}
