import { Component, Injector, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// models
import { Catalog, MoveLot, MoveLotProperty } from '../shared/models/catalog';
import { Language } from '../../shared/models/language';
import { Product, GridProperty } from '../shared/models/product';
import { CatalogService } from '../shared/services/catalog.service';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { LanguageService } from '../../shared/services/language.service';

@Component({
    selector: 'move-lots-component',
    templateUrl: 'move-lots.component.html',
    styleUrls: ['./move-lots.component.scss'],
})
export class MoveLotsComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<boolean>();

  catalogId: number;
  auctionId: number;
  languages: Array<Language> = [];
  catalogs: Array<Catalog> = [];
  model: MoveLot;
  product: Product;
  JSON = JSON;
  translations: any;
  destinationCatalog: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  protected dataService: CatalogService;
  protected translate: TranslateService;
  protected errorService: ErrorService;
  protected spinner: SpinnerService;

  constructor(
    injector: Injector,
    private languageService: LanguageService
  ) {
    this.dataService = injector.get(CatalogService);
    this.translate = injector.get(TranslateService);
    this.errorService = injector.get(ErrorService);
    this.spinner = injector.get(SpinnerService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.translate.get('MOVE_LOTS').subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('MOVE_LOTS').subscribe((res: string) => {
        this.translations = res;
      });
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getLabel(prop: GridProperty) {
    const pp = this.product.productProperties.find(p => p.productPropertyId === prop.productPropertyId);

    if (pp) {
      return pp.name;
    }

    return '';
  }

  getType(prop: GridProperty) {
    const pp = this.product.productProperties.find(p => p.productPropertyId === prop.productPropertyId);

    if (pp) {
      return pp.propertyTypeId;
    }

    return 0;
  }

  parseLabelAndOptions() {
    this.product.moveLotsToCatalog.forEach(s => {
      var property = this.product.productProperties.find(pp => pp.productPropertyId == s.productPropertyId);
      s.label = this.getLabel(s);
      s.propertyTypeId = this.getType(s);
      s.masterDataListId = property.masterDataListId;
    });
  }

  clearSelections() {
    this.destinationCatalog = null;
    this.product.moveLotsToCatalog.forEach(s => {
      s.masterDataRowIdSelection = null;
      s.intValueSelection = null;
      s.intValueToSelection = null;
    });
  }

  open(auctionId: number,
      catalogId: number,
      languages: Array<Language>,
      product: Product,
      catalogs: Array<Catalog>) {
    this.auctionId = auctionId;
    this.catalogId = catalogId;
    this.languages = languages;
    this.product = product;
    this.catalogs = catalogs.filter(c => c.catalogId !== catalogId);

    this.model = new MoveLot();

    this.clearSelections();

    this.parseLabelAndOptions();
  }

  parseSelections() {
    this.model.catalogGroupFilterProperty = [];
    this.product.moveLotsToCatalog.forEach(s => {
      console.log('rowidselections', s.masterDataRowIdSelection);
      if (s.masterDataRowIdSelection || s.intValueSelection || s.intValueToSelection) {
        const catalogGroupFilterProperty = new MoveLotProperty();
        catalogGroupFilterProperty.productPropertyId = s.productPropertyId;
        catalogGroupFilterProperty.masterDataListRowIds =  s.masterDataRowIdSelection;
        catalogGroupFilterProperty.intValue = s.intValueSelection;
        catalogGroupFilterProperty.toIntValue = s.intValueToSelection;

        this.model.catalogGroupFilterProperty.push(catalogGroupFilterProperty);
      }
    });
  }

  save() {
    this.parseSelections();

    this.spinner.show();
    this.dataService.moveLots(this.auctionId, this.catalogId, this.destinationCatalog, this.model)
      .subscribe(s => {
        this.spinner.hide();
        this.cancel(true);
      }, e => {
        this.errorService.show(e);
        this.spinner.hide();
      });
  }

  cancel(isMoved: boolean) {
    this.clearSelections();
    this.close.emit(isMoved);
  }
}
