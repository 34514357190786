import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierPermission } from '../models/supplier-permission';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';

@Injectable()
export class SupplierPermissionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierpermission';
  }

  getSupplierPermissions(): Observable<Array<SupplierPermission>> {
    return this.webApiService.getList(this.apiPath);
  }
}
