import { Component, HostListener, Injector, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';
import { LanguageService } from '../../shared/services/language.service';
import { AuctionClusterChannel } from '../../shared/models/auction-cluster-channel';
import { Language } from '../../shared/models/language';
import { Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';
import { ProviderTypeEnum } from '../../shared/models/providerTypeEnum';
import { NotificationManagerService } from '../../shared/services/notification-manager.service';
import notify from 'devextreme/ui/notify';

const ESC_KEYCODE = 27;

@Component({
  selector: 'auction-cluster-channel-component',
  templateUrl: './auction-cluster-channel.component.html',
  styleUrls: ['./auction-cluster-channel.component.scss']
})
export class AuctionClusterChannelComponent extends ItemDetailsComponent<AuctionClusterChannel> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  languages: Array<Language> = [];
  formDirty: boolean = false;
  providers = [ {id: ProviderTypeEnum.Twilio, name: "Twilio"}, {id: ProviderTypeEnum.Unifonic, name: "Unifonic"}];

  @ViewChild('channelName') channelName;
  @ViewChild('editAuctionClusterChannelForm', { read: DxFormComponent}) auctionClusterChannelForm: DxFormComponent;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;

  @Output() onUpdated = new EventEmitter();
  @Output() onCancelled = new EventEmitter();

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private notificationManagerService: NotificationManagerService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterChannel();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onCancel() {
    this.isOpened = false;
    this.channelName.resetLanguageButtons();
    this.errorMessage = null;
    if(this.formDirty) {
      this.onCancelled.emit();
    }
  }

  open(channel: AuctionClusterChannel, languages: Array<Language>) {
    this.isOpened = true;
    this.formDirty = false;
    this.languages = languages;
    this.model = channel;
    if(!this.model.provider) {
      this.model.provider = 0;
    }
    this.createEmtpyObjectForTranslatableFields();
    this.spinner.hide();
  }
  
  save() {
    this.spinner.show();
    this.notificationManagerService.updateAuctionClusterChannel(this.model)
    .subscribe((res: any) => {
      this.model = new AuctionClusterChannel();
      this.close(true);
      this.errorMessage = null;
      this.spinner.hide();
      this.onUpdated.emit();
    },
      error => {
        this.errorService.show(error);
      this.spinner.hide();
    });
    this.spinner.hide();
  }
  
  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });
    if(!this.model.name) {
      this.model.name = JSON.stringify(emptyTranslation);
    }
  }
  
  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }
  
  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup'){
      if (!this.formDirty) this.formDirty = true;
    }
  }
  
  onChangeTranslationField = (e: any, fieldName: string) => {
    if(!this.formDirty) this.formDirty = true;
    if (fieldName === 'name') {
      this.auctionClusterChannelForm.instance.updateData(fieldName, this.channelName.data);
    } 
  }
}
