import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuctionCluster } from '../models/auction-cluster';
import { AuctionClusterImport } from '../models/auction-cluster-import';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { IAuctionClusterService } from '../../../shared/interfaces/auction-cluster';
import { TimeZoneInfo } from '../../../shared/models/timeZoneInfo';

@Injectable()
export class AuctionClusterService implements IAuctionClusterService {

  lastId = 0;
  auctionClusters: Array<AuctionCluster> = [];

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAuctionClusters(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath);
  }

  getAuctionClustersPerLevel(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath);
  }

  getTimeZones(): Observable<Array<TimeZoneInfo>>{
    return this.webApiService.getList(this.apiPath + '/timezones');
  }

  getAuctionCluster(auctionClusterId: number): Observable<AuctionCluster> {
    return this.webApiService.get(this.apiPath, auctionClusterId);
  }

  save(auctionCluster: AuctionCluster): Observable<any> {
    return this.webApiService.save<AuctionCluster>(this.apiPath, auctionCluster);
  }

  edit(auctionCluster: AuctionCluster): Observable<any> {
    return this.webApiService.edit<AuctionCluster>(this.apiPath, auctionCluster.auctionClusterId, auctionCluster);
  }

  delete(auctionClusterId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, auctionClusterId);
  }

  enableDisable(auctionCluster: AuctionCluster): Observable<AuctionCluster> {
    if (auctionCluster.isActive) {
      return this.webApiService.enableDisable(this.apiPath + '/disable', auctionCluster.auctionClusterId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/enable', auctionCluster.auctionClusterId);
    }
  }

  import(auctionCluster: AuctionClusterImport) {
    return this.webApiService.save(this.apiPath + '/import', auctionCluster);
  }

  export(auctionClusterId: number) {
    return this.webApiService.downloadWithGet(this.apiPath + '/export/' + auctionClusterId, 'auctioncluster.json');
  }

  compare(auctionCluster: AuctionClusterImport) {
    return this.webApiService.save(this.apiPath + '/compare', auctionCluster);
  }
}
