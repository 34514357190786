import { Component, OnInit, Injector, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { DxFormComponent } from 'devextreme-angular';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { WebApiService } from '../../shared/services/web-api.service';
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { ShoppingListsService } from '../shared/services/shopping-lists.service';
import { LookupTableService } from '../shared/services/lookup-table.service';

// models
import { Language } from '../../shared/models/language';
import { ShoppingList } from '../shared/models/shopping-list';
import { ProductPropertyTypes } from '../../auction/shared/models/product';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { ApplicationSettings } from '../../shared/models/application-settings';

@Component({
  selector: 'shopping-list-lot-component',
  templateUrl: 'shopping-list-lot.component.html',
  styleUrls: ['./shopping-list-lot.component.scss']
})
export class ShoppingListLotComponent extends ItemDetailsComponent<Lot> implements OnInit, OnDestroy {
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  lookupTableService: LookupTableService;

  buyerId: number;
  catalogId: number;
  languages: Array<Language> = [];
  shoppingList: ShoppingList = new ShoppingList();
  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  comboBoxes: Array<any> = [];
  lang = 'nl-be';

  @ViewChild('shoppingLotForm') formComponent: DxFormComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private dataService: ShoppingListsService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {
    super(injector);
    this.getServices(route, appSettings, webApiService);

    this.lang = this.translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('SHOPPING_LIST');
    this.model = new Lot();
    this.buyerId = +this.route.snapshot.params['id'];
    this.catalogId = +this.route.snapshot.params['catalogId'];
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.lookupTableService = ServiceFactory.getLookupTableService(route, appSettings, webApiService) as LookupTableService;
  }

  open(lot: Lot, languages: Array<Language>,
    shoppingList: ShoppingList, masterDataDropdowns: Array<any>) {
    this.languages = languages;
    this.shoppingList = shoppingList;

    if (lot != null) {
      this.isEditMode = true;
      this.model = lot;
    } else {
      this.model = new Lot();
      this.isEditMode = false;
    }

    this.parseModel();
    this.model.catalogId = this.shoppingList.shoppingListId;
    this.generateForm();
    this.isOpened = true;
  }

  parseModel() {
    if (this.model.lotProperties.length === 0) {
      this.shoppingList.shoppingListProductDefinitionBuyer.forEach(ppf => {
        // check if already added
        const alreadyAddedProp = this.model.lotProperties.find(p => p.productPropertyId === ppf.productPropertyId);
        if (!alreadyAddedProp) {
          const newObj = new LotProperty();
          newObj.orderNumber = ppf.orderNumber;
          newObj.masterDataListFieldId = ppf.masterDataListFieldId;
          newObj.masterDataListId = ppf.masterDataListId;
          newObj.masterDataListRowId = null;
          newObj.name = ppf.name;
          newObj.propertyTypeId = ppf.propertyTypeId;
          newObj.propertyTypeFormatId = ppf.propertyTypeFormatId;
          newObj.productPropertyId = ppf.productPropertyId;
          newObj.translatable = ppf.translatable;
          if (newObj.translatable) {
            newObj.stringValue = this.createEmtpyObjectForTranslatableFields();
          }

          this.model.lotProperties.push(newObj);
        }
      });
    } else {
      this.model.lotProperties.forEach(lp => {
        const field = this.shoppingList.shoppingListProductDefinitionBuyer.find(ppf => ppf.productPropertyId === lp.productPropertyId);

        if (field) {
          lp.orderNumber = field.orderNumber;
          lp.masterDataListFieldId = field.masterDataListFieldId;
          lp.masterDataListId = field.masterDataListId;
          lp.name = field.name;
          lp.propertyTypeId = field.propertyTypeId;
          lp.propertyTypeFormatId = field.propertyTypeFormatId;
          lp.translatable = field.translatable;

          if (lp.propertyTypeId === ProductPropertyTypes.DATE &&  lp.dateTimeValue) {
            const serverReturnedTime = moment.utc(lp.dateTimeValue);
            lp.dateTimeValue = moment(serverReturnedTime).local();
          }
          this.onPropertyChanged(lp);
        }
      });
    }

    this.model.lotProperties.sort((a, b) => a.orderNumber - b.orderNumber );
  }

  onMasterDataValueChanged(e: any, data: any, property: LotProperty) {
    data.component.updateData(data.dataField, e.value);
    this.onPropertyChanged(property);
  }

  onPropertyChanged = (property: LotProperty) => {
    // clear values first
    this.masterDataDropdownsFiltered = []
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      this.lookupTableService.getLookupTableForProductPropertyForReports(null, this.masterDataDropdowns[i].productPropertyId, this.model)
        .subscribe(lookup => {
          if (lookup) {
            var newValues = [];
            if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
              if (lookup && lookup.lookupTableType == 0) {
                lookup.lookupTableRows.forEach(row => {
                  if (row.resultMasterDataListRowId) {
                    newValues.push(row.resultMasterDataListRowId);
                  }
                  else {
                    row.lookupTableValues.forEach(value => {
                      newValues.push(value.masterDataListRowId);
                    });
                  }
                });
              }
            }
          }
          this.masterDataDropdownsFiltered[this.masterDataDropdowns[i].masterDataListId.toString()] = newValues;
        });
    }
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    return JSON.stringify(emptyTranslation);
  } 

  clearLotPropertyValue(lotProperty: LotProperty) {
    const lp = this.model.lotProperties.find(lp => lp.lotPropertyId === lotProperty.lotPropertyId);
    if (lp) {
      lp.masterDataListRowId = null;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  parseBeforeSave() {
    this.model.lotProperties.forEach(lotProperty => {
      if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE &&  lotProperty.dateTimeValue) {
        lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue);
      }

      if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
        const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
        if (field) {
          lotProperty.stringValue = field.data;
        }
      }
    });
  }

  save() {
    this.parseBeforeSave();

    if (this.model.lotId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editLot(this.buyerId, this.shoppingList.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveLot(this.buyerId, this.shoppingList.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  dateChanged(property: any, dateTime: Moment) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    this.model.lotProperties[Number(matches[1])].dateTimeValue = moment(dateTime).toDate();
  }

  dateValue(property: any) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    if (this.model.lotProperties[Number(matches[1])])
      return this.model.lotProperties[Number(matches[1])].dateTimeValue;
    else
      return null;
  }

  public onCancel() {
    super.onCancel();
  }

  generateFields = () => {
    const generatedItems = [];
    this.masterDataDropdowns = [];
    return new Promise((resolve, reject) => {

      this.model.lotProperties.forEach((field, i) => {
        if (field.propertyTypeId === 1 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].intValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1
              }
            }
          );
        } else if (field.propertyTypeId === 2 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].decimalValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0.0',
                min: 0,
                step: 0.1
              }
            }
          );
        } else if (field.propertyTypeId === 3 && !field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name }
            }
          );
        } else if (field.propertyTypeId === 3 && field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name },
              template: 'translatableField'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 11 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'fullDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 12 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 13 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 14 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 15 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 5 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].masterDataListRowId',
              label: { text: field.name },
              name: this.model.lotProperties[i].masterDataListId,
              template: 'masterdataTemplate'
            }
          );
          this.model['lotProperties[' + i + '].masterDataListRowId'] = this.model.lotProperties[i].masterDataListRowId;
          this.masterDataDropdowns.push(this.model.lotProperties[i]);
        } else if (field.propertyTypeId === 7 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].booleanValue',
              editorType: 'dxCheckBox',
              label: { text: field.name }
            }
          );
        }
      });

      const intervalResolve = setInterval(() => {
        if (generatedItems.length >= this.model.lotProperties.length) {
          resolve(generatedItems);
          clearInterval(intervalResolve);
        }
      }, 50);

    });
  }

  onOpenedBoxes = (e: any, index: number) => {
    e.component.option('dataSource', this.comboBoxes[index]);
  }

  generateForm = async () => {
    const items = [];
    let generatedItems;
    this.formComponent.instance.option('items', items);

    if (this.model.lotProperties.length > 0) {
      generatedItems = await this.generateFields();
    }

    generatedItems.push(
      {
        dataField: 'amountWanted',
        editorType: 'dxNumberBox',
        label: { text: this.translations.AMOUNT_WANTED },
        editorOptions: {
          showSpinButtons: true,
          mode: 'number',
          format: '#0',
          min: 0,
          step: 1
        }
      }
    );

    items.push(
      {
        itemType: 'group',
        caption: '',
        items: generatedItems
      });
    this.formComponent.instance.option('items', items);
  }
}
