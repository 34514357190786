import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { PrebidOnProduct } from '../models/prebid';
import { Lot } from '../../../auction/shared/models/lot';

@Injectable()
export class PrebidOnProductService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getPrebids(buyerId: number): Observable<Array<PrebidOnProduct>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/prebidonproduct');
  }

  getPrebid(buyerId: number, prebidId: number): Observable<PrebidOnProduct> {
    return this.webApiService.getSingle(this.apiPath + '/' + buyerId + '/prebidonproduct/' + prebidId);
  }

  save(buyerId: number, prebid: PrebidOnProduct): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/prebidonproduct';
    return this.webApiService.save(url, prebid);
  }

  edit(buyerId: number, prebid: PrebidOnProduct): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/prebidonproduct';
    return this.webApiService.editSingle(url, prebid);
  }

  delete(prebidId: number, buyerId: number): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/prebidonproduct';
    return this.webApiService.delete(url, prebidId);
  }

  getLots(buyerId: number, auctionClusterId: number, catalogId: number) {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/${catalogId}/lot`);
  }

  getProductLots(buyerId: number, auctionClusterId: number, productId: number) {
    return this.webApiService
    .getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/product/${productId}/lot`);
  }

  getLot(buyerId: number, auctionClusterId: number, lotId: number): Observable<Lot> {
    return this.webApiService.getSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/lot/${lotId}`);
  }

  saveLot(buyerId: number, auctionClusterId: number, lot: Lot): Observable<any> {
    const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/lot`;
    return this.webApiService.save(url, lot);
  }

  saveLotForProduct(buyerId: number, auctionClusterId: number, productId, lot: Lot): Observable<any> {
    const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/product/${productId}/lot`;
    return this.webApiService.save(url, lot);
  }

  editLot(buyerId: number, auctionClusterId: number, lot: Lot): Observable<any> {
    const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/lot`;
    return this.webApiService.editSingle(url, lot);
  }

  deleteLot(buyerId: number, auctionClusterId: number, lotId: number): Observable<any> {
    return this.webApiService.delete(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/lot`, lotId);
  }
}
