import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { Language } from '../models/language';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';

const CACHE_SIZE = 1;

@Injectable()
export class LanguageService {
  private cache$: Observable<Array<Language>>;
  private auctionClusterLanguagecache$: Observable<Array<Language>>;
  direction = new EventEmitter<boolean>();

  private auctionClusterId = 0;

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private translateService: TranslateService, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'language';
  }

  getAuctionClusterLanguages(auctionClusterId: number): Observable<Array<Language>> {
    if (!this.auctionClusterLanguagecache$ || this.auctionClusterId !== auctionClusterId) {
      this.auctionClusterLanguagecache$ = this.requestAuctionClusterLanugages(auctionClusterId).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    this.auctionClusterId = auctionClusterId;
    return this.auctionClusterLanguagecache$;
  }

  getLanguages(): Observable<Array<Language>> {
    if (!this.cache$) {
      this.cache$ = this.requestLanugages().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;
  }

  private requestLanugages() {
    return this.webApiService.getList(this.apiPath).pipe(
      map((response: Array<any>) => {
        const sorted = response.sort((a, b) => {
          if (a.isDefault && !b.isDefault) {
            return -1;
          }
          if (b.isDefault && !a.isDefault) {
            return 1;
          }

          return 0;
        });
        return sorted;
      })
    );
  }

  private requestAuctionClusterLanugages(auctionClusterId: number) {
    return this.webApiService.getList(this.appSettings.adminApi + `auctioncluster/${auctionClusterId}/language`).pipe(
      map((response: Array<any>) => {
        const sorted = response.sort((a, b) => {
          if (a.isDefault && !b.isDefault) {
            return -1;
          }
          if (b.isDefault && !a.isDefault) {
            return 1;
          }

          return 0;
        });
        return sorted;
      })
    );
  }

  getCurrentLanguage(languages: Array<Language>) {
    var language = languages.filter(_ => _.code == this.translateService.currentLang);
    if (!language) {
      language = languages.filter(_ => _.code == this.translateService.defaultLang);
    }
    return language[0];
  }

  getTranslatableText(value: string, defaultLanguage: string = null): string {
    if (!this.isJson(value)) {
      return value;
    }

    var translatebleText = '';
    const field = JSON.parse(value);
    if (field && field[this.translateService.currentLang]) {
      translatebleText = field[this.translateService.currentLang];
    } else if(translatebleText === '' && field && field[this.translateService.defaultLang]){
      translatebleText = field[this.translateService.defaultLang];
    } else if(translatebleText === '' && defaultLanguage && field[defaultLanguage]){
      translatebleText = field[defaultLanguage];
    } 
    
    return translatebleText;
  }

  private isJson(value) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    if (typeof (value) === 'string' && value.length === 0){
      return false;
    }

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    if (typeof value === 'object' && value !== null) {
      return true;
    }

    return false;
  }

  checkAllLanguageFieldsUnique(languages: Array<Language>, name1: string, name2: string): boolean {
    return languages.every(language => {
      const obj1 = JSON.parse(name1);
      const obj2 = JSON.parse(name2);
      return obj1[language.code].toLowerCase().trim() !== obj2[language.code].toLowerCase().trim();
    });
  }

  checkAllLanguageFieldsUniqueCanEmpty(languages: Array<Language>, name1: string, name2: string): boolean {
    return languages.every(language => {
      const obj1 = JSON.parse(name1);
      const obj2 = JSON.parse(name2);
      return !obj1[language.code]
        || !obj2[language.code]
        || obj1[language.code] === ''
        || obj2[language.code] === ''
        || obj1[language.code].toLowerCase().trim() !== obj2[language.code].toLowerCase().trim();
    });
  }

  checkAllLanguageNotEmpty(languages: Array<Language>, name1: string): boolean {
    return languages.every(language => {
      const obj1 = JSON.parse(name1);
      return obj1[language.code];
    });
  }

  checkOneLanguageNotEmpty(languages: Array<Language>, name1: string): boolean {
    return languages.some(language => {
      const obj1 = JSON.parse(name1);
      return obj1[language.code];
    });
  }

  checkDefaultLanguageNotEmpty(languages: Array<Language>, name1: string): boolean {
    const defaultLang = languages.find(lang => lang.isDefault);
    const obj1 = JSON.parse(name1);
    return (obj1[defaultLang.code] && obj1[defaultLang.code] !== "") ? true : false;  
  }

  updateStringToAllLanguages(languages: Array<Language>, value: string): string {
    let object = JSON.parse(value);

    languages.forEach(lang => {
      if (!object[lang.code]) {
        object[lang.code] = '';
      }
    });
    return JSON.stringify(object);
  }

  setSameValueToAllLanguages(languages: Array<Language>, translatable: string, value: string): string {
    let object = JSON.parse(translatable);

    languages.forEach(lang => {
        object[lang.code] = value;
    });
    return JSON.stringify(object);
  }
}
