export class ImportSchema {

    fields: Array<ImportField>;
    references = new Map<string, ImportReference>();
    timePeriod: ImportTimePeriod;

    constructor() {
        this.fields = [];
        this.references.clear();
    }
}

export class ImportTimePeriod {
    constructor(public fromDate: ImportField, public toDate: ImportField, public isRequired: boolean) { }
}

export class ImportReference {
    constructor(public primaryKey: string, public uniqueKey: string, public data: Array<any>, public isCircular = false) { }
}

export class ImportField {

    required = false;

    isBoolean = false;
    isDateTime = false;
    isPositiveNumber = false;

    isBadge = false;
    isPassword = false;
    isPinCode = false;
    isEmail = false;
    isPhoneNumber = false;

    isReference = false;
    referenceName: string;
    isList = false;

    constructor(public name: string, public caption: string) { }

    setReference(referenceName: string, isList: boolean) {
        this.isReference = true;
        this.referenceName = referenceName;
        this.isList = isList;
    }
}

export class Match {

    schemaField: ImportField;
    description: string;

    constructor(public fileColumn: string, public filePosition: number) { }
}

export class ImportData {

    records: Array<any>;
    removeItemsNotInFile: boolean;

    constructor() {
        this.records = [];
    }
}

export enum ImportStatus {
    STARTED = 0,
    ADDED,
    UPDATED,
    DELETED,
    SKIPPED,
    FAILED
}
