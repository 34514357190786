import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PriceUnit} from '../models/price-unit';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class PriceUnitService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'priceunit';
  }

  getPriceUnits(): Observable<Array<PriceUnit>> {
    return this.webApiService.getList(this.apiPath);
  }
}
