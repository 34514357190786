import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { forkJoin, Subscription, map } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { Language } from '../../shared/models/language';
// general services
import { LanguageService } from '../../shared/services/language.service';
import { LookupTable, LookupTableField, LookupTableRow } from '../shared/models/lookup-table';
import { MasterData } from '../shared/models/master-data';
import { ProductPropertyType, ProductPropertyTypes, UrlTypes } from '../shared/models/product';

// services
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';
import { LookupTableRowEditorComponent } from './lookup-table-row-editor.component';

@Component({
  selector: 'lookup-table-editor-component',
  templateUrl: 'lookup-table-editor.component.html',
  styleUrls: ['./lookup-table-editor.component.scss']
})
export class LookupTableEditorComponent extends FullListComponent<LookupTableRow, LookupTableRowEditorComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: LookupTableRowEditorComponent;
  @ViewChild('dataGrid') dataGrid: any;
  @ViewChild("editorGrid") editorGrid: DxDataGridComponent;

  customDataSource: CustomStore;
  languages: Array<Language> = [];
  lookupTableId: number;
  auctionClusterId: number;
  item: LookupTable;
  lookupTableRows: Array<LookupTableRow> = [];
  lookupTableFields: Array<LookupTableField> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDataLists: Array<MasterData> = [];
  errorMessage: string;
  total: number;
  loading = true;
  firstLoad = true;
  gridState: any;
  pageSize: number;
  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  imageLoaded: boolean = false;
  largeImageSrc: string = '';

  columns: Array<any> = [];
  fetchedAll: boolean = false;
  columnsCreated: boolean = false;
  fetchedTranslations = {
    'LOOKUP_TABLE.ROWS.PERIOD_FROM': '',
    'LOOKUP_TABLE.ROWS.PERIOD_TO': '',
    'SHARED.DEFAULT_VALUE': '',
    'LOOKUP_TABLE.ROWS.RESULT_COLUMN': '',
    'LOOKUP_TABLE.RESULT_COLUMN': ''
  };

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  dataGridInstance: DataGrid;
  gridColumns = [];
  resultColumnName: string;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private productPropertyTypeService: ProductPropertyTypeService,
    private masterDataService: MasterDataService,
    private dataService: LookupTableService,
    private translateService: TranslateService,
  ) {
    super(injector, LookupTableRow);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.customDataSource = new CustomStore({
      key: 'lookupTableRowId',
      load: (loadOptions) => {

        const observables = [];
        observables.push(this.dataService.loadLookupTablesPageSearchSort(+this.route.snapshot.params['id'], +this.route.snapshot.params['lookupTableId'], this.translateService.currentLang, loadOptions)
          .pipe(map((result: LookupTable) => result)));
        if (this.firstLoad) {
          observables.push(this.dataService.getLookupTableWithoutRows(+this.route.snapshot.params['id'], +this.route.snapshot.params['lookupTableId']));
          observables.push(this.languageService.getLanguages());
          observables.push(this.masterDataService.getMasterDataLists(+this.route.snapshot.params['id']));
          observables.push(this.productPropertyTypeService.getProductProperties());
        }

        return new Promise((resolve, reject) => {
          forkJoin(observables).subscribe((results: Array<any>) => {
            if (this.firstLoad) {
              this.item = <LookupTable>results[1];
              this.lookupTableFields = this.item.lookupTableFields;
              this.title.set('LOOKUP_TABLE.TITLE');
              this.title.add(this.languageService.getTranslatableText(this.item.name));
              this.languages = <Language[]>results[2];
              this.masterDataLists = <MasterData[]>results[3]; // tslint:disable-line
              this.productPropertyTypes = <ProductPropertyType[]>results[4]; // tslint:disable-line

              this.generateTable(this.item);
            }

            resolve({
              data: results[0]['data'],
              totalCount: results[0]['totalCount']
            });
          });
        });
      }
    });
  }

  ngOnInit() {
    this.lookupTableId = +this.route.snapshot.params['lookupTableId'];
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('LOOKUP_TABLE');

    //this.getTranslations();

    setTimeout(_ => {
      // this.getTranslations();
      //this.getData();
    }, 100);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getTranslations() {
    Object.keys(this.fetchedTranslations).forEach(item => {
      this.translateService.get(item).subscribe((res: string) => {
        this.fetchedTranslations[item] = res;
      });
    });
  }

  getData(from?: number, pageSize?: number, sort?: any, filters?: Array<any>) {


  }

  manualRefresh() {
    this.reloadGrid();
  }

  reloadGrid() {
    if (!this.dataGridInstance)
      return;

    this.dataGridInstance.getDataSource().reload();
  }

  refresh(state: any) {
  }

  getMasterDataName(masterDataListId: number) {
    const md = this.masterDataLists.filter(l => {
      return l.masterDataListId === masterDataListId;
    });

    if (md.length > 0) {
      return this.languageService.getTranslatableText(md[0].name);
    }

    return '-';
  }

  getCellDateValue(date?: Date) {
    if (date) {
      const localDate = new Date(date);
      return localDate.toLocaleString(this.translate.currentLang);
    }

    return '';
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getResultProperyName(propertyId: number, masterDataListId: number) {
    let propertyName = '';

    if (masterDataListId) {
      const md = this.masterDataLists.find(mdl => mdl.masterDataListId === masterDataListId);

      if (md) {
        return this.getTranslation(md.name);
      }
    }

    this.productPropertyTypes.forEach(p => {
      if (p.propertyTypeId === propertyId) {
        propertyName = p.name;
      }
    });

    return propertyName;
  }

  getPropertyCellValue(row: LookupTableRow) {
    switch (this.item.resultPropertyTypeId) {
      case ProductPropertyTypes.NUMBER:
        return row.resultIntValue;
      case ProductPropertyTypes.DECIMAL:
        return row.resultDecimalValue;
      case ProductPropertyTypes.TEXT:
        return row.resultStringValue;
      case ProductPropertyTypes.DATE:
        return row.resultDateTimeValue;
      case ProductPropertyTypes.BOOLEAN:
        return row.resultBooleanValue ? 'true' : 'false';
      case ProductPropertyTypes.URL:
        return row.resultUrlValue;
      default:
        return '-';
    }
  }

  edit = (lookupTableRowId: any) => {
    this.detailsComponent.modalTitle = this.translations.ROWS.EDIT;

    if (!this.popUpOpened) {
      this.detailsComponent.open(true, this.item, parseInt(lookupTableRowId), this.masterDataLists, this.productPropertyTypes, this.languages);
    }

  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ROWS.ADD_NEW;
    this.detailsComponent.open(false, this.item, null, this.masterDataLists, this.productPropertyTypes, this.languages);
  }


  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteRow(this.id, this.itemIdToDelete)
      .subscribe((res: any) => {
        this.manualRefresh();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  delete = (e: any) => {
    this.itemIdToDelete = parseInt(e.row.data.lookupTableRowId);
    this.confirmation.opened = true;
  }

  onSave() {
    this.spinner.show();
    this.dataService.edit(this.item)
      .subscribe((res: any) => {
        this.router.navigate(['/auction/lookuptables/' + this.id]);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  onCancel() {
    this.router.navigate(['/auction/lookuptables/' + this.id]);
  }

  createDataSchema() {
    this.reloadGrid();
  }


  generateTable = (lookupTable: LookupTable) => {
    if (!this.columnsCreated) {
      this.gridColumns = [];
      let gridCurrentColums = this.dataGridInstance.getVisibleColumns();

      let existingColumnIndex = gridCurrentColums.length;

      if (this.translations == undefined) {
        return;
      }
      
      this.firstLoad = false;

      let columnIndex = 0;

      this.lookupTableFields.forEach((field: LookupTableField, i: number) => {
        let columnName = this.getMasterDataName(field.masterDataListId);
        let caption = columnName;
        caption += field.isResult ? ' (' + this.translations.RESULT_COLUMN + ')' : '';

        if (this.gridColumns.includes(columnName)) {
          let j = 0;
          do {
            columnName = this.getMasterDataName(field.masterDataListId) + j;
            j++;
          } while (this.gridColumns.includes(columnName));
        }

        this.gridColumns.push({ columnName, row: field });

        const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
        if (!isColumn) {
          columnIndex = existingColumnIndex + i;
          this.dataGridInstance.addColumn({
            dataField: columnName,
            caption: caption,
            visibleIndex: columnIndex,
            allowEditing: false,
            encodeHtml: false,
            editorOptions: {
              showClearButton: true
            }
          });
        }
      });

      const additionalColumns = [];

      if (!this.columnsCreated) {
        this.resultColumnName = this.getResultProperyName(this.item.resultPropertyTypeId, this.item.resultMasterDataListId) + ' (' + this.translations.ROWS.RESULT_COLUMN + ')';
        if (this.item.resultPropertyTypeId || this.item.resultMasterDataListId) {
          if (this.item.resultPropertyTypeId === ProductPropertyTypes.URL && this.item.resultPropertyFormatId === UrlTypes.IMAGE_URL) {
            additionalColumns.push({ caption: this.resultColumnName, name: "resultName", image: true});
          } else {
            additionalColumns.push({ caption: this.resultColumnName, name: "resultName" });
          }
        }
        if (this.item && this.item.hasPeriodFilter) additionalColumns.push({ caption: this.translations.ROWS.PERIOD_FROM, name: "periodFrom" });
        if (this.item && this.item.hasPeriodFilter) additionalColumns.push({ caption: this.translations.ROWS.PERIOD_TO, name: "periodTo" });
        if (this.item.lookupTableType === 0) additionalColumns.push({ caption: this.translations.ROWS.DEFAULT_VALUE, name: "defaultValue" });
      }

      additionalColumns.forEach((ac, index) => {
        this.gridColumns.push({ columnName: ac, row: {} });
        this.dataGridInstance.addColumn({
          dataField: ac.name,
          caption: ac.caption,
          cellTemplate: ac.image ? 'imageCellTemplate' : '',
          visible: true,
          allowEditing: false,
          encodeHtml: false,
          visibleIndex: columnIndex + index + 1,
          editorOptions: {
            showClearButton: true
          }
        });
      });

      this.gridColumns.push({ columnName: 'id', row: {} });
      this.dataGridInstance.addColumn({
        caption: 'id',
        visible: false,
        allowEditing: false
      });
    }
    this.columnsCreated = true;
  }

  updateDefaultValue() {
    let gridCurrentColumns = this.dataGridInstance.getVisibleColumns();

    this.lookupTableRows.forEach((row: LookupTableRow, i) => {
      this.gridColumns.forEach((column, j) => {
        if (this.item.lookupTableType === 0 && !row.isDefault) {
          if (this.item.enableUpdate || this.item.enableDelete) {
            let test = this.dataGridInstance.cellValue(i, gridCurrentColumns.length - 2);
            this.dataGridInstance.cellValue(i, gridCurrentColumns.length - 2, null);
            this.dataGridInstance.cellValue(i, 'default', null);
            test = this.dataGridInstance.cellValue(i, gridCurrentColumns.length - 2);
          } else {
            let test = this.dataGridInstance.cellValue(i, gridCurrentColumns.length - 2);
            this.dataGridInstance.cellValue(i, gridCurrentColumns.length - 1, null);
            this.dataGridInstance.cellValue(i, 'default', null);
            test = this.dataGridInstance.cellValue(i, 'default');
          }

        }
      });
    });
  }

  getPropertyCellValueSafe(row: LookupTableRow) {
    let res = this.getPropertyCellValue(row);

    if (typeof res === 'string' || res instanceof String) {
      res = this.stripHTML(res as string);
    }

    return res;
  }

  getCellDateValueSafe(period: Date) {
    let res = this.getCellDateValue(period);

    if (typeof res === 'string') {
      res = this.stripHTML(res as string);
    }

    return res;
  }

  stripHTML(text: string) {
    let processed = text.replace(/<.*?>/gm, '');
    processed = processed.replace(/"/g, "'");
    return processed;
  }

  showLargerImage(imgSrc: string) {
    this.spinner.show();
    this.popUpOpened = true;
    this.largeImageSrc = imgSrc;

    if (this.imageLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.imageLoaded = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
  }
  
  openEditClick = (e: any) => {
    if (!this.item.enableUpdate)
      return;

    this.edit(e.data.lookupTableRowId);
  }

  editClick = (e: any) => {
    this.edit(e.row.data.lookupTableRowId);
  }

  isEditEnabled = () => {
    return (this.item && this.item.enableUpdate);
  }

  isDeleteEnabled = () => {
    return (this.item && this.item.enableDelete);
  }
}
