import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { GridProperty, Product, ProductProperty } from '../../../auction/shared/models/product';
import { SupplierProduct } from '../models/supplier-product';
import { IProductService } from '../../../shared/interfaces/product';

@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;

  private supplierId: number;

  setId(supplierId: number)
  {
    this.supplierId = supplierId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  getProduct(supplierId: number, auctionClusterId: number, productId: number): Observable<Product> {
    return this.webApiService.getSingle(`${this.apiPath}/${supplierId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }

  getProductsClocks(supplierId: number): Observable<Array<SupplierProduct>> {
    return this.webApiService.getList(this.apiPath + '/' + supplierId + '/product/clock');
  }

  //IProductService

  getProductForReports(auctionClusterId: number, productId: number): Observable<Product> {
    return this.webApiService.getSingle(`${this.apiPath}/${this.supplierId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }

  getProductProperties(productId: number): Observable<Array<ProductProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/productproperties`);
  }

  getReportProperties(productId: number, reportDesignId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/reportproperties/${reportDesignId}`);
  }
}
