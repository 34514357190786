import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { Cookies } from '../../../shared/constants/cookies';
import { ICatalogService } from '../../../shared/interfaces/catalog';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { SupplierPublish } from '../../catalogs/publish-lot.component';
import { Catalog, GroupFilter, MoveLot } from '../models/catalog';
import { CheckCleanupResult } from '../models/checkcleanupresult';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable()
export class CatalogService implements ICatalogService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAllSupplyCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/catalog');
  }

  getAuctionCatalogs(auctionId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/catalog');
  }

  getAuctionCatalog(auctionId: number, catalogId: number): Observable<Catalog> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/catalog', catalogId);
  }

  getAllSupplyAndForecastCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/catalog/all');
  }

  getAllForecastCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/catalog/forecast');
  }

  getAuctionForecastCatalog(auctionId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/catalog/forecast');
  }

  save(auctionId: number, catalog: Catalog): Observable<any> {
    return this.webApiService.save<Catalog>(this.apiPath + '/auction/' + auctionId + '/catalog', catalog);
  }

  edit(auctionId: number, catalog: Catalog): Observable<any> {
    return this.webApiService.edit<Catalog>(this.apiPath + '/auction/' + auctionId + '/catalog', catalog.catalogId, catalog);
  }

  delete(auctionId: number, catalogId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/catalog', catalogId);
  }

  copy(auctionId: number, catalogId: number): Observable<any> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/copy/catalog', catalogId);
  }

  publishSupplierLots(auctionId: number, catalogId: number, suppliers: SupplierPublish): Observable<any> {
    return this.webApiService.editSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/publishsupplier', suppliers);
  }

  publishSupplyLotsAll(auctionId: number, catalogId: number): Observable<any> {
    return this.webApiService.editSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/publishsupplyall', {});
  }

  publishSupplyLotsToday(auctionId: number, catalogId: number): Observable<any> {
    return this.webApiService.editSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/publishsupplytoday', {});
  }

  getGroupFilters(auctionId:number, catalogId: number): Observable<Array<GroupFilter>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/groupfilters');
  }

  getGroupFilter(auctionId:number, catalogId: number, groupFilterId: number): Observable<GroupFilter> {
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/groupfilter/' + groupFilterId);
  }

  getGroupFilterPreselected(auctionId:number, catalogId: number, lotIds: Array<number>): Observable<GroupFilter> {
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/groupfilterpreselected/', { 'ids': lotIds });
  }

  deleteGroupFilter(auctionId:number, catalogId: number, groupFilterId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/catalog', catalogId + '/groupfilter/' + groupFilterId);
  }

  saveGroupFilter(auctionId: number, catalogId: number, filter: GroupFilter): Observable<any> {
    return this.webApiService.save<GroupFilter>(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/groupfilter', filter);
  }

  editGroupFilter(auctionId: number, catalogId: number, filter: GroupFilter): Observable<any> {
    return this.webApiService.editSingle<GroupFilter>(this.apiPath + '/auction/'
    + auctionId + '/catalog/' + catalogId + '/groupfilter', filter);
  }

  moveLots(auctionId: number, catalogId: number, destinationCatalogId: number, moveLot: MoveLot): Observable<any> {
    return this.webApiService.editSingle<MoveLot>(this.apiPath + '/auction/'
    + auctionId + '/catalog/' + catalogId + '/movelots/' + destinationCatalogId, moveLot);
  }

  cleanupCatalog(auctionId: number, catalogId: number, cleanupDate: Date): Observable<any> {
    const dateFormatted = moment(cleanupDate).local().format('YYYY-MM-DD');
    return this.webApiService
    .save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/cleanup?date=' + dateFormatted, null);
  }

  checkCleanup(auctionId: number, catalogId: number, cleanupDate): Observable<CheckCleanupResult> {
    const dateFormatted = moment(cleanupDate).local().format('YYYY-MM-DD');
    return this.webApiService
    .save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/checkcleanup?date=' + dateFormatted, null);
  }

  emptyTransactionBuffer(auctionId: number, catalogId: number, cleanupDate): Observable<boolean> {
    const dateFormatted = moment(cleanupDate).local().format('YYYY-MM-DD');
    return this.webApiService
      .save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/emptytransactionbuffers?date=' + dateFormatted, null);
  }

  resetCatalog(auctionId: number, catalogId: number, resetDate: Date): Observable<any> {
    const dateFormatted = moment(resetDate).local().format('YYYY-MM-DD');
    return this.webApiService
      .save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/reset?date=' + dateFormatted, null);
  }

  //ICatalogService
  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/catalog/all');
  }

  setSupplyEditorMode(cookieService: any) {
    let isTouchMode = false;

    let supplyEditorMode = cookieService.get(Cookies.SUPPLY_EDITOR_MODE);
    if (supplyEditorMode === 'touch') {
      isTouchMode = true;
    } else {
      isTouchMode = false;
      if (!supplyEditorMode)
        cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
    }

    return isTouchMode;
  }
}
