import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { AuctionClusterReportingRole } from '../../../shared/models/auction-cluster-reporting-role';

import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class AuctionClusterReportingRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster/';
  }

  getAuctionClusterReportingRoles(auctionClusterId: number, langCode: string): Observable<Array<AuctionClusterReportingRole>> {
    return this.webApiService.getList(this.apiPath + `${auctionClusterId}/auctionclusterreportingrole?langCode=${langCode}`);
  }

  getAuctionClusterReportingRole(auctionClusterId: number, userId: number): Observable<AuctionClusterReportingRole> {
    return this.webApiService.get(this.apiPath + `${auctionClusterId}/auctionclusterreportingrole`, userId);
  }

  save(auctionClusterId: number, auctionClusterReportingRole: AuctionClusterReportingRole): Observable<any> {
    return this.webApiService.save<AuctionClusterReportingRole>(this.apiPath + `${auctionClusterId}/auctionclusterreportingrole`, auctionClusterReportingRole);
  }

  edit(auctionClusterReportingRole: AuctionClusterReportingRole): Observable<any> {
    return this.webApiService.edit<AuctionClusterReportingRole>(this.apiPath + auctionClusterReportingRole.auctionClusterId
      + '/auctionclusterreportingrole', auctionClusterReportingRole.auctionClusterReportingRoleId, auctionClusterReportingRole);
  }

  delete(auctionClusterId: number, auctionClusterReportingRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + `${auctionClusterId}/auctionclusterreportingrole`, auctionClusterReportingRoleId);
  }

  checkIfRoleHasUsers(auctionClusterId: number, roleId: number): Observable<Array<number>> {
    return this.webApiService.getList(this.apiPath + `${auctionClusterId}/auctionclusterreportingrole/${roleId}/checkusers`);
  }
}
