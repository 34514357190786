import { Product } from '../../auction/shared/models/product';

export class ReportFilterModel {
  productPropertyTypeId: number;
  productPropertyId: number;
  dateRangeType: number;
  key: string;
  value: string;
  entityId: number;
}

export class ReportDesign {
  reportDesignId: number;
  auctionClusterId: number;
  productId: number;
  exportType: number;
  refreshIntervalSeconds: number;
  name: string;
  sysName: string;
  reportLevel: number;
  systemReportDesignType: number;
  data: string;
  productName: string;
  isCustomReportDesign: boolean;
}

export class ReportDesignWithPrefix extends ReportDesign {
  prefix: string;
}

export class Report {
  reportId: number;
  userId: number;
  reportDesignId: number;
  description: string;
  data: string;
  systemDefault: boolean;
  reportDesign: ReportDesign = new ReportDesign();
  productName: string;
  filterLabel: string;
  name: string;
  reportLevel: number;
}

export enum DateRanges {
  TODAY = 0,
  YESTERDAY = 1,
  LAST_WEEK = 2,
  RANGE = 3,
  TOMORROW = 4,
  DATE = 5
}

export enum SystemReportDesignTypes {
  TransactionMonitorReport = 1,
  LineTimeReport = 2,
  AuctionClusterSupplyMonitorFilter = 3,
  BuyerSupplyMonitorFilter = 4,
  BuyerSupplyMatrixFilter = 5
}

export enum ReportLevels {
  Platform = 0,
  AuctionCluster = 1,
  Buyer = 2,
  Supplier = 3,
  Public = 4
}

export enum ExportTypes {
  None = 0,
  CSV = 1,
  PDF = 2,
  XLSX = 3,
  TXT = 4
}
