import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { MenuTypes } from '../../shared/models/additional-menu-item';
import { AdditionalMenuItem } from '../../shared/models/additional-menu-item';
import { Language } from '../../shared/models/language';

// services
import { LanguageService } from '../../shared/services/language.service';
import { AdditionalMenuItemsService } from '../shared/services/additional-menu-items.service';

enum MenuOptions {
  ADD_TO_MAIN_MENU = 1,
  ADD_AS_SUBMENU_OF_EXISTING_MENU = 2
}

@Component({
  selector: 'additional-menu-item-component',
  templateUrl: 'additional-menu-item.component.html',
  styleUrls: ['./additional-menu-item.component.scss']
})

export class AdditionalMenuItemComponent extends ItemDetailsComponent<AdditionalMenuItem> implements OnInit {

  menuOptions: any = MenuOptions;
  languages: Array<Language> = [];
  accesses = [];
  menuTypes: any = MenuTypes;
  parentMenuItems: Array<AdditionalMenuItem> = [];
  menuOption: number;
  emptyMenuItemName: string;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('menuItemNameField') menuItemNameField;
  @ViewChild('menuItemHtmlEditorField') menuItemHtmlEditorField;
  @ViewChild('additionalMenuItemForm', { read: DxFormComponent}) additionalMenuItemForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: AdditionalMenuItemsService,
    private languageService: LanguageService
  ) {
    super(injector);
    this.menuTypes = Object.keys(this.menuTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.menuTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.menuOptions = Object.keys(this.menuOptions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.menuOptions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AdditionalMenuItem();
    this.getMenuItemsData();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(menuItems: Array<AdditionalMenuItem>, id: number, languages: Array<Language>, accesses: Array<any>) {
    this.getMenuItemsData();
    this.allItems = menuItems;
    this.languages = languages;
    this.accesses = accesses;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getMenuItem(id)
        .subscribe((res: AdditionalMenuItem) => {
          this.model = res;
          if (!this.model.content) {
            this.model.content = JSON.stringify(emptyTranslation);
          }
          this.isOpened = true;
          this.setDefaultData();
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new AdditionalMenuItem();
      this.isEditMode = false;
      this.isOpened = true;
      this.model.menuOption = 1;
      this.model.orderNumber = this.allItems[this.allItems.length - 1].orderNumber + 1;
    }
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    this.model.menuItemName = JSON.stringify(emptyTranslation);
    this.emptyMenuItemName = JSON.stringify(emptyTranslation);
    this.model.content = JSON.stringify(emptyTranslation);
  }

  save() {
    this.changeDependentValues();

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AdditionalMenuItem();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new AdditionalMenuItem();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  changeMenuType(type: number) {
    this.model.menuType = type;
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = 'ADDITIONAL_MENU_ITEM.' + item.name;
      this.translate.get(label).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnAdditionalMenuSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnAdditionalMenuSubmit')[0].removeAttribute('disabled');
      }

    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnAdditionalMenuSubmit')[0].removeAttribute('disabled');
      }
      if (this.model.menuItemName !== this.emptyMenuItemName) {
        document.getElementsByName('btnAdditionalMenuSubmit')[0].removeAttribute('disabled');
      }

    }

    if (e.dataField === "menuOption") {
      if (this.parentMenuItems.length !== 0 && !this.model.submenuId) {
        this.model.submenuId = this.parentMenuItems[0].externalAppId;
      } else if (this.parentMenuItems.length === 0){
        this.model.menuOption = 1;
      }
    }
    if ((this.model.menuType === 1 && !this.model.applicationUrl) || this.model.menuItemName === this.emptyMenuItemName) {
      document.getElementsByName('btnAdditionalMenuSubmit')[0].setAttribute('disabled', 'disabled');
    }
  }

  getMenuItemsData() {
    this.dataService.getMenuItems().subscribe((result: AdditionalMenuItem[]) => {
       this.parentMenuItems = result.filter(_ => !_.submenuId && _.menuType === 3);
    }, error => {
      this.errorService.show(error);
    });
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'menuItemName') {
      this.additionalMenuItemForm.instance.updateData(fieldName, this.menuItemNameField.data);
    } else if (fieldName === 'content') {
      this.additionalMenuItemForm.instance.updateData(fieldName, this.menuItemHtmlEditorField.data);
    }
  }

  setDefaultData() {
    if (!this.parentMenuItems.find(_ => _.externalAppId === this.model.submenuId)){
      this.model.menuOption = 1;
    }
    else {
      this.model.menuOption = 2;
    }
  }

  changeDependentValues() {
    // Add sysname if it's not already defined
    if (!this.model.sysName || this.model.sysName === '') {
      this.model.sysName = this.model.name.replace(/\s/g, "").toUpperCase();
    }
    if (this.menuItemNameField) {
      this.model.menuItemName = this.menuItemNameField.data;
    }
    if (this.model.menuOption === 1) {
      this.model.submenuId = null;
    } else {
      this.model.setAsStartPage = false;
    }
    if (this.model.menuType === 1) {
      this.model.content = null;
      this.model.openInNewTab = true;
    } else if (this.model.menuType === 2) {
      this.model.applicationUrl = null;
      this.model.content = this.menuItemHtmlEditorField.data;
      this.model.openInNewTab = false;
    } else if (this.model.menuType === 3) {
      this.model.applicationUrl = null;
      this.model.content = null;
      this.model.openInNewTab = false;
      this.model.setAsStartPage = false;
      this.model.menuOption = 1;
      this.model.submenuId = null;
    }
  }
}
