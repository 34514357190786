import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DxDateBoxComponent } from 'devextreme-angular';

import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { TranslateService } from '@ngx-translate/core';

const NOON = 12;

@Component({
  selector: 'date-component',
  templateUrl: './date.component.html',
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})
export class DateComponent {
  @ViewChild('dateInput') dateInput: DxDateBoxComponent;

  //get element() {
  //  return this.dateInput.element.nativeElement.children[0].children[1].children[0].children[0]
  //}

  time: Moment;
  nameString: string;
  tabIndexValue: string;
  requiredValue: boolean;
  disabledValue: boolean;
  minValue: Date;

  constructor(private translateService: TranslateService, private dateTimeAdapter: DateTimeAdapter<any>) {
    dateTimeAdapter.setLocale(translateService.currentLang);
  }

  get instance() {
    return this.dateInput.instance;
  }

  @Input('data')
  set times(value: any) {
    if (value) {
      this.time = value;
    }
  }

  @Input('name')
  set nameStringSetter(value: string) {
    if (value) {
      this.nameString = value;
    }
  }

  @Input('tabIndex')
  set tabIndexSetter(value: string) {
    this.tabIndexValue = value;
  }

  @Input('required')
  set requiredSetter(value: boolean) {
    this.requiredValue = value;
  }

  @Input('disabled')
  set disabledSetter(value: boolean) {
    this.disabledValue = value;
  }

  @Input('min')
  set minSetter(value: Date) {
    if (value) {
      this.minValue = value;
    }
  }

  @Output() modelChanged = new EventEmitter<any>();
  @Output() onblur = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onInitialized = new EventEmitter<any>();

  changeModelState() {
    //
  }

  onblurEvent() {
    if (this.time && !(this.time instanceof Date) && typeof this.time !== 'string') {
      let date = this.time.toDate();
      date.setHours(NOON);
      this.time = moment.utc(date);
    }
    this.modelChanged.emit(this.time);
    this.onblur.emit();
  }

  onFocusIn() {
    this.onFocus.emit();
  }

  pickerClosed() {
    if (this.time && !(this.time instanceof Date) && typeof this.time !== 'string') {
      let date = this.time.toDate();
      date.setHours(NOON);
      this.time = moment.utc(date);
    }
    this.modelChanged.emit(this.time);
  }

  focus() {
    this.dateInput.instance.focus();
  }
}
