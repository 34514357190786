import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { IAuctionClusterService } from '../../../shared/interfaces/auction-cluster';
import { AuctionCluster } from '../../../platform/shared/models/auction-cluster';

@Injectable()
export class AuctionClusterService implements IAuctionClusterService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(id: number) {
    this.buyerId = id;
  }

  getAuctionClustersPerLevelWithoutImage(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/auctioncluster/withoutImage`);
  }

  //IAuctionClusterService
  
  getAuctionClustersPerLevel(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/auctioncluster`);
  }
}
