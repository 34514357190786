import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductPropertyTypes } from '../../auction/shared/models/product';
import { ReportFilterModel, Report, DateRanges } from '../../shared/models/report';

import { LanguageService } from '../../shared/services/language.service';

import { ApplicationSettings } from '../../shared/models/application-settings';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';

import { TokenService } from '../../shared/services/token.service';

// general services
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';
import { PublicDataService } from '../../shared/services/public-data-service';
import { PublicAuction } from '../../shared/models/public-auction';
import { PublicCatalog } from '../../shared/models/public-catalog';
import { MasterData } from '../../auction/shared/models/master-data';
import { ReportComponent } from '../../shared/components/reporting/report.component';
import { WebApiService } from '../../shared/services/web-api.service';
import { AuthService } from '../../shared/services/auth.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const NOON = 12;

@Component({
  selector: 'report-editor.component',
  templateUrl: 'report-editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['report-editor.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})

export class ReportEditorComponent extends ReportComponent {
  @ViewChild('filterForm') filterForm: NgForm;

  constructor(
    appSettings: ApplicationSettings,
    route: ActivatedRoute,
    titleService: TitleService,
    languageService: LanguageService,
    router: Router,
    errorService: ErrorService,
    dataService: PublicDataService,
    spinner: SpinnerService,
    tokenService: TokenService,
    translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    webApiService: WebApiService,
    authenticationService: AuthService) {
    super(appSettings, route, titleService, languageService, router, errorService, null, spinner, translateService, dateTimeAdapter, webApiService, authenticationService);

    this.hostUrl = this.appSettings.reportingApiURL;
    dateTimeAdapter.setLocale(translateService.currentLang);
    route.params.subscribe(val => {
      this.init();
    });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getPlainText(value: string) {
    if (value) {
      return value.replace(/<[^>]*>/g, '');
    }
    else {
      return '';
    }
  }

  init() {
    super.init();
  }

  SpecificHandlingAfterFetchingServices(): void {
  }

  getFilterForServerFixedFiltering(filterForServer) {

  }

  reportDocumentReady(e: any) {
    e.sender.GetPreviewModel().reportPreview.zoom(-1);
  }
}
