
export class User {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  isDeleted: boolean;
  isPending: boolean;
  isBlocked: boolean;
  platformRoleId: number;
  rolesString: string;
  existingBuyerVat: string;
  existingSupplierVat: string;
  languageId: number;
  sendAccountInfo: boolean;
  telNumber: string;
  isSystemUser: boolean;

  auctionClusterRoles: Array<UserAuctionClusterRole> = [];
  userAuctionClusterReportingRoles: Array<UserAuctionClusterReportingRole> = [];
  buyerRoles: Array<UserBuyerRole> = [];
  supplierRoles: Array<UserSupplierRole> = [];

  domain: string;

  constructor() {
  }
}

export class UserAuctionClusterRole {
  auctionClusterId: number;
  auctionId: number;
  auctionClusterRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
}

export class UserAuctionClusterReportingRole {
  userAuctionClusterReportingRoleId: number;
  auctionClusterId: number;
  auctionClusterReportingRoleId: number;
}

export class BuyerRole {
  buyerRoleId: number;
  buyerId: number;
  name: string;
  description: string;
  permissions: Array<number> = [];
}

export class UserBuyerRole {
  buyerId: number;
  buyerRoleId: number;
  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
  buyerRole: BuyerRole;
  availableBuyerRoles : Array<BuyerRole> = [];
}

export class SupplierRole {
  supplierRoleId: number;
  supplierId: number;
  name: string;
  description: string;
  permissions: Array<number> = [];
}

export class UserSupplierRole {
  supplierId: number;
  supplierRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';

  supplierRole: SupplierRole;

  availableSupplierRoles: Array<SupplierRole> = [];
}

export class Longtab {
  text: string;
}
