import { Component, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../shared/services/language.service';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { LocationService } from '../shared/services/location.service';
import { Location } from '../shared/models/location';
import { TokenService } from '../../shared/services/token.service';
import { AuthService } from '../../shared/services/auth.service';
import { WebApiService } from '../../shared/services/web-api.service';
import { ReportComponent } from '../../shared/components/reporting/report.component';
import { BuyerService } from '../../shared/services/buyer.service';
import { Subscription } from 'rxjs';
// general services
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const NOON = 12;

@Component({
  selector: 'report-editor.component',
  templateUrl: 'report-editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['report-editor.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})

export class ReportEditorComponent extends ReportComponent implements OnDestroy {
  @ViewChild('filterForm') filterForm: NgForm;

  supplierId: number;
  lang = 'nl-be';

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;


  constructor(
    appSettings: ApplicationSettings,
    route: ActivatedRoute,
    //private productService: ProductService,
    titleService: TitleService,
    languageService: LanguageService,
    router: Router,
    errorService: ErrorService,
    buyerService: BuyerService,
    private locationService: LocationService,
    spinner: SpinnerService,
    private tokenService: TokenService,
    translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    authenticationService: AuthService,
    webApiService: WebApiService) {
    super(appSettings, route, titleService, languageService, router, errorService, buyerService, spinner, translateService, dateTimeAdapter, webApiService, authenticationService);
    route.params.subscribe(val => {
      this.init();
    });
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  init()
  {
    this.supplierId = +this.route.snapshot.params['id'];

    super.init();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }


  SpecificHandlingAfterFetchingServices() : void {
  }

  getFilterForServerFixedFiltering(filterForServer) {

  }

  navigate(report){
    this.router.navigate(['/supplier/reports/' + this.supplierId + '/ac/' + this.auctionClusterId + '/report/' + report.reportId]);
  }
}
