import { Component, OnInit, Injector, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _moment from 'moment';
import notify from 'devextreme/ui/notify';
import { Subscription, forkJoin } from 'rxjs';
import DataGrid from 'devextreme/ui/data_grid';
import { TranslateService } from '@ngx-translate/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// services
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ProductService } from '../shared/services/product.service';

// models
import { Product, GridProperty, LotEditorProperty, ProductPropertyTypes, DecimalTypes, AllowMultilpleValuesWithingFieldDependency, DateTypes } from '../shared/models/product';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Language } from '../../shared/models/language';
import { Lot, LotProperty } from '../shared/models/lot';
import { LotComponent } from './lot.component';
import { LotService } from '../shared/services/lot.service';
import { MasterData, MasterDataValue, MasterDataListField } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { CalculationsField } from '../shared/models/calculation';
import { DateTimeService } from '../../shared/services/datetime.service';
import { DateTimeTypes } from '../../shared/models/dateTimeTypes';

import { Moment } from 'moment';
import { MasterDataDropdown } from './supply-lots.component';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'lot-multi-edit-component',
  templateUrl: 'lot-multi-edit.component.html',
  styleUrls: ['./lot-multi-edit.component.scss']
})
export class LotMultiEditComponent extends FullListComponent<Lot, LotComponent> implements OnInit, OnDestroy {
  
  @Output() edited = new EventEmitter();

  @ViewChild('name') name: any;

  catalogId: number;
  auctionId: number;
  lot: Lot = new Lot();
  editableLot: Lot = new Lot();
  productPropertyIds: Array<number> = [];
  editorSelection: Array<LotEditorProperty> = [];
  gridSelection: Array<GridProperty> = [];
  isOpened = false;
  supplyProperties: Array<GridProperty> = [];
  masterDatas: Array<MasterData> = [];
  translatableFields: any;

  languages: Array<Language> = [];
  product: Product;
  cardTitle: string;
  errorMessage: string;
  isEditMode = false;
  selections: {};
  JSON = JSON;
  dataGridInstance: DataGrid;
  selectedRows: Array<number> = [];
  gridItems = [];
  allMode: string;
  checkBoxesMode: string;
  saveEnabled: boolean = false;
  runEnabled: boolean = true;
  itemsToChange:number = 0;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridColumns = [];

  lang = 'nl-be';

  constructor(
    protected injector: Injector,
    private dataService: LotService,
    private dateTimeService: DateTimeService,
    private auctionService: AuctionClusterAuctionService,
    private productService: ProductService,
    private masterDataService: MasterDataService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    super(injector, Lot);
    this.lang = translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.setTranslations('LOTS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {
    if (!this.auctionId || !this.catalogId || !this.lot || this.productPropertyIds.length === 0) {
      return;
    }
    this.spinner.show();

    // Reset state
    this.items = [];
    this.editableLot = new Lot();
    this.generateTable(this.editableLot);

    forkJoin(
      this.dataService.getMultiEdit(this.auctionId, this.catalogId, this.lot.lotId, this.productPropertyIds),
      this.dataService.getLot(this.auctionId, this.lot.lotId)
    ).subscribe(async results => {
      this.items = results[0].lots;
      await this.parseItems();

      this.editableLot = results[1];

      await this.parseLotProperties();

      this.editableLot.lotPropertyGroups.forEach(g => {
        g.lotPropertyGroupRows.forEach(r => {
          r.lotProperties.forEach(lp => {
            if (this.productPropertyIds.indexOf(lp.productPropertyId) > -1) {
              let lotProperty = JSON.parse(JSON.stringify(lp));
              // Reset values
              lotProperty.dateTimeValue = null;
              lotProperty.decimalValue = null;
              lotProperty.intValue = null;
              lotProperty.booleanValue = null;
              lotProperty.masterDataListRowId = null;
              this.editableLot.lotProperties.push(lotProperty);
            }
          });
        });
      });

      this.generateTable(this.editableLot);
      //this.generateTableData();

      this.checkIfSaveEnabled();

      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  async parseLotProperties(isEdit: boolean = false) {
    let editorProperties = this.product.supplyDataEditorProperties;
    if (this.isForecastCatalog) {
      editorProperties = this.product.supplyForecastDataEditorProperties;
    }
    for await (const group of this.editableLot.lotPropertyGroups) {
      if (!group.isHidden) {
        for await (const row of group.lotPropertyGroupRows) {
          for await (const lotProperty of row.lotProperties) {
            const productProperty = this.product.productProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (productProperty) {
              lotProperty.name = productProperty.name;
              lotProperty.productPropertyId = productProperty.productPropertyId;
              lotProperty.propertyTypeId = productProperty.propertyTypeId;
              lotProperty.name = productProperty.name;
              lotProperty.minValue = productProperty.minValue;
              lotProperty.maxValue = productProperty.maxValue;
              lotProperty.maxLength = productProperty.maxLength;
              lotProperty.translatable = productProperty.translatable;
              lotProperty.propertyTypeFormatId = productProperty.propertyTypeFormatId;
              lotProperty.manuallyDisabled = false;
              lotProperty.systemProductPropertyType = productProperty.systemProductPropertyType;
              lotProperty.selected = true;
              lotProperty.masterDataListId = productProperty.masterDataListId
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.BOOLEAN && !lotProperty.booleanValue) {
              lotProperty.booleanValue = false;
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE && lotProperty.dateTimeValue) {
              const serverReturnedTime = moment.utc(lotProperty.dateTimeValue);
              lotProperty.dateTimeValue = moment(serverReturnedTime).locale(this.translateService.currentLang);
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
              // Create empty JSON object for translation fields
              const emptyTranslation = {};
              this.languages.forEach(lang => {
                emptyTranslation[lang.code] = '';
              });

              lotProperty.stringValue = lotProperty.stringValue ? lotProperty.stringValue : JSON.stringify(emptyTranslation);
            }

            const editorProperty = editorProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (editorProperty) {
              lotProperty.width = editorProperty.width;
              lotProperty.productPropertyEditorSelectionDetails = editorProperty.productPropertyEditorSelectionDetails;
              if (editorProperty.name) {
                const nameTranslatable = JSON.parse(editorProperty.name);
                if (nameTranslatable) {
                  this.languages.forEach(lang => {
                    if (nameTranslatable[lang.code]) {
                      lotProperty.name = editorProperty.name;
                    }
                  });
                }
              }

              if (lotProperty.productPropertyEditorSelectionDetails
                && lotProperty.productPropertyEditorSelectionDetails.allowMultipleValuesWithinFieldDependency
                === AllowMultilpleValuesWithingFieldDependency.NO_SET_READ_ONLY
                && this.getPropValue(lotProperty) !== null) {
                lotProperty.readOnlyOnceSelected = true;
              }

              if (!group.name) {
                const productGroup = this.product.productPropertyGroups.find(g => {
                  return g.productPropertyGroupId === editorProperty.productPropertyGroupId;
                });

                if (productGroup) {
                  group.name = this.getTranslation(productGroup.name);
                  group.collapse = productGroup.collapse;
                }
              }
            }

            lotProperty.displayValue = await this.findPropertyValue(lotProperty);
          };
        };
      }
    };
  }

  getPropValue(prop: LotProperty) {
    switch (prop.propertyTypeId) {
      case ProductPropertyTypes.NUMBER:
        return prop.intValue;
      case ProductPropertyTypes.TEXT:
        return prop.stringValue;
      case ProductPropertyTypes.DECIMAL:
        return prop.decimalValue;
      case ProductPropertyTypes.DATE:
        return this.dateTimeService.getDateStringByFormatAnyUtc(prop.dateTimeValue, prop.propertyTypeFormatId);
      case ProductPropertyTypes.BOOLEAN:
        return prop.booleanValue;
      default:
        return prop.masterDataListRowId;
    }
  }

  dateChanged(property: LotProperty, dateTime: Moment) {

    if (moment(property.dateTimeValue).isSame(dateTime)) {
      return;
    }
    property.dateTimeValue = moment(dateTime).toDate();

    if (property.propertyTypeFormatId === DateTimeTypes.LONG_DATE || property.propertyTypeFormatId === DateTimeTypes.SHORT_DATE) {

      var date = new Date(property.dateTimeValue.getFullYear(), property.dateTimeValue.getMonth(), property.dateTimeValue.getDate(), 12, 0);
      property.dateTimeValue = date;
    }
    property.changed = true;
  }

  setLotPropertyValue(lotProperty: LotProperty, value: any) {
    switch (lotProperty.propertyTypeId) {
      case ProductPropertyTypes.MASTER_DATA_VALUE:
        lotProperty.masterDataListRowId = value;
        break;
      case ProductPropertyTypes.NUMBER:
        lotProperty.intValue = value;
        break;
      case ProductPropertyTypes.DECIMAL:
        lotProperty.decimalValue = value;
        break;
      case ProductPropertyTypes.TEXT:
        lotProperty.stringValue = value;
        break;
      case ProductPropertyTypes.DATE:
        lotProperty.dateTimeValue = value;
        break;
      case ProductPropertyTypes.BOOLEAN:
        lotProperty.booleanValue = value;
        break;
      default:
        break;
    }
  }

  init() {
    this.getData();
  }

  selectAll() {
    this.items.forEach(i => {
      i.selected = true;
    });
  }

  deselectAll() {
    this.items.forEach(i => {
      i.selected = false;
    });
  }

  open(auctionId: number,
    catalogId: number,
    languages: Array<Language>,
    product: Product,
    masterDatas: Array<MasterData>,
    lot: Lot,
    editorSelection,
    gridSelection,
    supplyProperties,
    translatableFields) {

    this.errorMessage = '';

    this.auctionId = auctionId;
    this.catalogId = catalogId;
    this.languages = languages;
    this.product = product;
    this.lot = lot;
    this.masterDatas = masterDatas;
    this.editorSelection = editorSelection;
    this.gridSelection = gridSelection;
    this.supplyProperties = supplyProperties;
    this.translatableFields = translatableFields;
    this.editableLot = new Lot();

    this.productPropertyIds = [];
    this.editorSelection.forEach(p => {
      if (p.productPropertyEditorSelectionDetails.activeInMultiEdit) {
        this.productPropertyIds.push(p.productPropertyId);
      }
    });

    this.init();
    this.isOpened = true;

    // this.parseLabelAndOptions();
  }

  async parseItems() {
    for await (const item of this.items) {
      for await (const sp of this.supplyProperties) {
        const lotProp = item.lotProperties.find(p => p.productPropertyId === sp.productPropertyId);
        if (lotProp) {
          const newLotProp = JSON.parse(JSON.stringify(lotProp));
          newLotProp.orderNumber = sp.orderNumber;
          newLotProp.propertyTypeId = sp.propertyTypeId;
          newLotProp.translatable = sp.translatable;
          newLotProp.masterDataListId = sp.masterDataListId;
          newLotProp.displayValue = await this.findPropertyValue(newLotProp);
          if (!item.displayLotProperties) {
            item.displayLotProperties = [];
          }
          item.displayLotProperties.push(newLotProp);
        }
      };
      item.displayLotProperties.sort((a, b) => a.orderNumber - b.orderNumber);
      item.selected = true;
    };
  }

  getTranslatableValue(lotProperty: LotProperty) {
    const tr = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
    if (tr) {
      return tr.data;
    }

    return null;
  }

  getTranslation(value: string) {
    if (!value) {
      return '';
    }
    if (!this.isJSONString(value)) {
      return value && value.replace ? value.replace(/"/g, "'") : value;
    }
    return this.languageService.getTranslatableText(value);
  }

  async findPropertyValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false): Promise<string> {
    if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE) {
      const dateFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, dateFormatId);
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      const decimalFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return lotProperty.decimalValue != null ? this.format(lotProperty.decimalValue, lotProperty.propertyTypeId, decimalFormatId) : '';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return lotProperty.intValue != null ? this.format(lotProperty.intValue, lotProperty.propertyTypeId) : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT) {
      if (lotProperty.translatable) {
        const trValue = this.getTranslatableValue(lotProperty);
        if (trValue) {
          return this.getTranslation(trValue);
        } else {
          return '';
        }
      }
      return lotProperty.stringValue;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      var masterDataValue = await this.getMasterDataValue(lotProperty, calculationField, returnFullTranslatable);
      return this.getTranslation(masterDataValue);
    }

    return '';
  }

  async getMasterDataValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false): Promise<string> {
    var masterDataListRowId = lotProperty.masterDataListRowId;
    if (masterDataListRowId == undefined || lotProperty.masterDataListId == undefined) {
      return '';
    }
    var result = await this.masterDataService.getMasterDataValueForDropdown(lotProperty.masterDataListId, this.product.auctionClusterId, this.translateService.currentLang, masterDataListRowId, [lotProperty.masterDataListFieldId]).toPromise();
    if (result.data && result.data.length > 0) {
      return result.data[0].label;
    }
    return '';
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField, returnFullTranslatable = false): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypes.DATE) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataListField.propertyTypeFormatId);
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      return masterDataValue.decimalValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return masterDataValue.intValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.TEXT) {
      if (returnFullTranslatable) {
        return masterDataValue.stringValue;
      }
      if (masterDataListField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.IMAGE) {
      return `<img style="max-width: 50px; max-height: 50px;" src=${masterDataValue.imageValue} />`;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    }
  }

  save() {
    //
  }

  private getSelectedProductProperties() {
    const productProperties: Array<number> = [];

    this.editableLot.lotProperties.forEach(lp => {
      // Check if value is defined
      if (this.checkIfValueIsSet(lp.booleanValue) || this.checkIfValueIsSet(lp.dateTimeValue) ||
        this.checkIfValueIsSet(lp.decimalValue) || this.checkIfValueIsSet(lp.intValue) ||
        this.checkIfValueIsSet(lp.masterDataListRowId) || this.checkIfValueIsSet(lp.stringValue)) {
        productProperties.push(lp.productPropertyId);
      }
    });

    return productProperties;
  }

  private getSelectedItems() {
    const lotIds: Array<number> = [];

    this.items.forEach(i => {
      if (i.selected) {
        lotIds.push(i.lotId);
      }
    });

    return lotIds;
  }

  onMultiEdit() {
    this.editableLot.lotPropertyGroups = [];

    const productProperties = this.getSelectedProductProperties();
    if (productProperties.length === 0)
      return;

    const lotIds = this.getSelectedItems();
    if (lotIds.length === 0)
      return;

    this.spinner.show();
    this.dataService.applyMultiEdit(this.auctionId, this.catalogId, this.editableLot.lotId, this.editableLot, productProperties, lotIds).subscribe(() => {
      this.spinner.hide();
      this.edited.emit();
      this.isOpened = false;
    }, err => {
      this.errorService.show(err);
      this.spinner.hide();
    });
  }

  private checkIfValueIsSet(value: any) {
    return value !== null && value !== undefined;
  }

  onCancel() {
    this.isOpened = false;
  }

  add() {
    //
  }

  deleteSelected() {
    //
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.schema = schema;
  }

  selectedPropertiesChanged() {
    const productProperties = [];
    this.editableLot.lotProperties.forEach(lp => {
      if (lp.selected) {
        productProperties.push(lp.productPropertyId);
      }
    });

    this.spinner.show();
    this.dataService.getMultiEdit(this.auctionId, this.catalogId, this.lot.lotId, productProperties).subscribe(async l => {
      this.items = l.lots;
      await this.parseItems();
      this.spinner.hide();

      this.generateTable(this.editableLot);
      this.runEnabled = false;
      this.itemsToChange = this.items.length;

    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
      this.runEnabled = true;
    });
  }

  clearGrid(){
    this.itemsToChange = 0;
    this.runEnabled = true;
    this.dataGridInstance.option('dataSource', []);
  }

  checkIfSaveEnabled() {
    const productProperties = this.getSelectedProductProperties();
    const lotIds = this.getSelectedItems();

    this.saveEnabled = (productProperties.length !== 0) && (lotIds.length !== 0);
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    this.supplyProperties.forEach((row: any, i: number) => {
      let columnName = row.productPropertyName;
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      let gridCurrentColums = this.dataGridInstance.getVisibleColumns();
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: columnName,//this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          format: this.getformatDevX(row.propertyTypeId, row.propertyTypeFormatId),
          //precision: this.getPrecision(row.propertyTypeId, row.propertyTypeFormatId),
          //lang: this.translateService.currentLang,
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    let lotIdColumn = this.dataGridInstance.columnOption('__lotId__');

    if(!lotIdColumn) {
      // add unique column for differentiating rows
      this.dataGridInstance.addColumn({
      dataField: '__lotId__',
      caption: '__lotId__',
      visibleIndex: 0,
      allowEditing: false,
      dataType: 'number',
      visible: false,
      encodeHtml: false,
      editorOptions: {
        showClearButton: false
      }
    });    
    }

    this.generateTableData();
  }


  generateTableData() {
    this.gridItems = [];
    this.items.forEach((row, i) => {
      const data = row.displayLotProperties;
      const gridItem = new Object();
      this.gridColumns.forEach((column, j) => {
        if (data[j] && data[j].propertyTypeId === 4) {
          gridItem[column] = data[j].dateTimeValue ? data[j].dateTimeValue : '';
        } else if (data[j]) {
          gridItem[column] = data[j].displayValue ? data[j].displayValue : '';
        }
        else {
          gridItem[column] = '';
        }
      });
    
      gridItem['__lotId__'] = row.lotId;
      this.gridItems.push(gridItem);
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (this.gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', this.gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', this.gridItems);
      
    }

    this.dataGridInstance.selectAll();
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  selectionChangedHandler = (e: any) => {
    this.items.forEach(i => {
      i.selected = false;
    });

    this.selectedRows.forEach(row => {
      const index = this.gridItems.findIndex(data => data === row);
      if (this.items[index])
        this.items[index].selected = true;
    });

    this.itemsToChange = this.selectedRows.length;
    this.checkIfSaveEnabled();
  }

  getformatDevX(propertyTypeId: number, propertyTypeFormatId: number) {
    if (propertyTypeId == ProductPropertyTypes.DECIMAL) {
      var decimalPoints = this.getPrecision(propertyTypeId, propertyTypeFormatId);
      
      return {
        precision: decimalPoints,
        type: 'decimal',
        formatter: (value) => {
          let numberFormatter = new Intl.NumberFormat(this.lang, { useGrouping: true, minimumFractionDigits: decimalPoints, maximumFractionDigits: decimalPoints });
          return numberFormatter.format(value);
        },
        parser: (value) => {
          return parseFloat(value);
        }
      };
    }
    
    return undefined;
  }

  getPrecision(propertyTypeId: number, propertyTypeFormatId: number): number {
    if (propertyTypeId == ProductPropertyTypes.DECIMAL) {
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_0digit)
        return 0;
      else if (propertyTypeFormatId == DecimalTypes.DECIMAL_1digit)
        return 1;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_2digit)
        return 2;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_3digit)
        return 3;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_4digit)
        return 4;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_5digit)
        return 5;
    }

    return undefined;
  }

  edit(itemId: number, event: Event) { }
}
