import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { CalculationsFieldComponent } from './calculations-field.component';

// models
import { ProductPropertyTypes } from '../shared/models/product';
import { CalculationsField } from '../shared/models/calculation';

@Component({
  selector: 'calculations-fields-component',
  templateUrl: 'calculations-fields.component.html',
  styleUrls: ['./calculations-fields.component.scss']
})
export class CalculationsFieldsComponent extends SimpleListComponent implements OnInit, OnDestroy {

  editingIndex: number;
  items: Array<CalculationsField> = [];
  propertyTypes: any = ProductPropertyTypes;
  auctionClusterId: number;
  productId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('data')
  set data(value: Array<CalculationsField>) {
    this.items = value;
  }  
  @Input('productid')
  set productid(value: number) {
    this.productId = value;
  }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDataChanged = new EventEmitter<Array<CalculationsField>>();

  @ViewChild(CalculationsFieldComponent) detailsComponent: CalculationsFieldComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.propertyTypes = Object.keys(this.propertyTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.propertyTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() { // tslint:disable-line:no-empty     
    this.setTranslations("SHARED");
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  edit(property: CalculationsField, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    const clone = { ...property };
    this.detailsComponent.open(this.productId, clone);
  }

  add() {
    this.detailsComponent.open(this.productId, null);
  }

  onProductPropertyAdded(property: CalculationsField) {
    this.items.push(property);
    this.onDataChanged.emit(this.items);
  }

  onProductPropertyUpdated(property: CalculationsField) {
    this.items[this.editingIndex] = property;
    this.onDataChanged.emit(this.items);
  }

  delete(property: CalculationsField) {
    this.items.splice(this.items.indexOf(property), 1);
    this.onDataChanged.emit();
  }

  translateSelectBoxes = (item: any) => {
    if (item) {      
      const propertyType = this.propertyTypes.find(f => f.value === item.value);
      if (propertyType){
        return this.translations[propertyType.name];
      }
    }
  }

  editItem = (e: any) => {
    this.edit(e.row.data, new Event('click'));
  }

  openEditClick = (e: any) => {
    this.edit(e.data, new Event('click'));
  }

  deleteItem = (e: any) => {
    this.delete(e.row.data);
  }
}
