import { Component, ViewChild, OnInit, OnDestroy, Injector, Input, EventEmitter, Output } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { LookupTableFieldComponent } from './lookup-table-field.component';

// models
import { ProductPropertyType, UrlTypes} from '../shared/models/product';
import { Language } from '../../shared/models/language';
import { LookupTableField } from '../shared/models/lookup-table';
import { MasterData } from '../shared/models/master-data';

import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'lookup-table-fields-component',
  templateUrl: 'lookup-table-fields.component.html',
  styleUrls: ['./lookup-table-fields.component.scss']
})
export class LookupTableFieldsComponent extends SimpleListComponent implements OnInit, OnDestroy {

  items: Array<LookupTableField> = [];
  languages: Array<Language> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  propertyFormats: Array<UrlTypes> = [];
  masterDataLists: Array<MasterData> = [];
  isAnyRowSet: boolean;
  lookupTableType: number;
  editingIndex: number;
  pageSize = 50;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('data')
  set data(value: Array<LookupTableField>) {
      this.items = value;
  }
  get data(): Array<LookupTableField> {
    return this.items;
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Input('isAnyRowSet')
  set isAnyRowSetFlag(value: boolean) {
    this.isAnyRowSet = value;
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
  }

  @Input('productPropertyFormats')
  set propertyFormat(value: Array<UrlTypes>) {
    this.propertyFormats = value;
  }

  @Input('masterDataLists')
  set masterDataList(value: Array<MasterData>) {
    this.masterDataLists = value;
  }

  @Input('lookupTableType')
  set lookupTableTypeSetter(value: number) {
    this.lookupTableType = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();
  @ViewChild(LookupTableFieldComponent) detailsComponent: LookupTableFieldComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    //
    this.setTranslations('LOOKUP_TABLE');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getUniqueMasterDataLists(selected: number = null) {
    const uniqueMasterDataLists = [];

    for (let i = 0; i < this.masterDataLists.length; i += 1) {
      let masterDataUsed = false;
      for (let j = 0; j < this.items.length; j += 1) {
        if (this.masterDataLists[i].masterDataListId === this.items[j].masterDataListId) {
          if (selected != null) {
            if (this.masterDataLists[i].masterDataListId !== selected) {
              masterDataUsed = true;
            }
           } else {
            masterDataUsed = true;
          }
        }
      }
      if (!masterDataUsed) {
        uniqueMasterDataLists.push(this.masterDataLists[i]);
      }
    }

    return uniqueMasterDataLists;
  }

  edit = (e: any) => {
    const lookupTableField = e.row.data;

    if (this.isAnyRowSet) {
      return;
    }
    this.editingIndex = this.items.indexOf(lookupTableField);

    this.detailsComponent.modalTitle = this.translations.FIELD.EDIT;
    const clone = { ...lookupTableField };
    this.detailsComponent.open(lookupTableField.lookupTableFieldId, this.lookupTableType, this.items, clone,
      this.languages, this.propertyTypes, this.propertyFormats, this.getUniqueMasterDataLists(lookupTableField.masterDataListId));
  }

  openEditClick = (e: any) => {
    if (e.column.type !== 'buttons') {
      const lookupTableField = e.data;

      if (this.isAnyRowSet) {
        return;
      }
      this.editingIndex = this.items.indexOf(lookupTableField);
  
      this.detailsComponent.modalTitle = this.translations.FIELD.EDIT;
      const clone = { ...lookupTableField };
      this.detailsComponent.open(lookupTableField.lookupTableFieldId, this.lookupTableType, this.items, clone,
        this.languages, this.propertyTypes, this.propertyFormats, this.getUniqueMasterDataLists(lookupTableField.masterDataListId));
    }
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.FIELD.ADD_NEW;
    this.detailsComponent.open(null, this.lookupTableType, this.items, new LookupTableField(), this.languages,
      this.propertyTypes, this.propertyFormats, this.getUniqueMasterDataLists());
  }

  onLookupTableFieldAdded(lookupTableField: LookupTableField) {
    this.items.push(lookupTableField);
    this.dataChanged.emit();
  }

  onLookupTableFieldEdited(lookupTableField: LookupTableField) {
    this.items[this.editingIndex] = lookupTableField;
    this.dataChanged.emit();
  }

  delete = (e: any) => {
    const lookupTableField = e.row.data;
    this.items.splice(this.items.indexOf(lookupTableField), 1);
    this.dataChanged.emit();
  }

  isButtonVisible = (e: any) => {
    if (!this.isAnyRowSet) {
      return true;
    } else {
      return false;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getMasterDataListName(masterDataListId: number) {
    const translatableNames = this.masterDataLists.filter(md => md.masterDataListId === masterDataListId);

    if (translatableNames.length > 0) {
      return this.getTranslation(translatableNames[0].name);
    }

    return '-';
  }

  getPropertyTypeName(propertyTypeId: number) {
    const property = this.propertyTypes.filter(pt => pt.propertyTypeId === propertyTypeId);

    if (property.length > 0) {
      return property[0].name;
    }

    return '-';
  }
}
