import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { SupplyMatrixLot } from '../models/supply-matrix-lot';
import { Catalog } from '../../../auction/shared/models/catalog';
import { BuyerClock } from '../models/buyer-level-clocks';
import { BuyerLevelSubbuyer } from '../models/buyer-level-subbuyer';
import { GenericLot, GenericLots, BuyerActionOnLotGroup } from '../../../shared/models/generic-lot';


@Injectable()
export class SupplyMatrixService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getLots(buyerId: number, auctionClusterId: number, catalogId: number, date: string, clockId?: number, langCode?: string): Observable<Array<SupplyMatrixLot>> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/supplymatrix`;
    if (clockId || langCode) {
      url += '?';
      if (clockId) {
        url += `clockId=${clockId}`;

        if (langCode) {
          url += `&langCode=${langCode}`;
        }
      } else if (langCode) {
        url += `langCode=${langCode}`;
      }
    }
    return this.webApiService.getList(url);
  }

  getLotsNew(buyerId: number, auctionClusterId: number, catalogId: number, date: string, clockId?: number, langCode?: string, genericFilters: string = null): Observable<any> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/supplymatrixnew`;
    if (genericFilters != null) {
      url += `?genericFilters=${genericFilters}`;
    }if (clockId || langCode) {
      if (genericFilters != null) {
        url += '&';
      }
      else {
        url += '?';
      }
      if (clockId) {
        url += `clockId=${clockId}`;

        if (langCode) {
          url += `&langCode=${langCode}`;
        }
      } else if (langCode) {
        url += `langCode=${langCode}`;
      }
    }

    return this.webApiService.getList(url);
  }

  getUngroupedLots(buyerId: number, auctionClusterId: number, catalogId: number, date: string, lotIds: Array<number>): Observable<GenericLots> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/ungroupedlots`;
    return this.webApiService.save(url, { 'ids': lotIds });
  }

  getLotsForPrebids(buyerId: number, auctionClusterId: number, catalogId: number, date: string, lotIds: Array<number>, clockId?: number)
    : Observable<GenericLot> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/lot/matrixprebid`;
    if (clockId) {
      url += `?clockId=${clockId}`;
    }
    return this.webApiService.save(url, { 'ids': lotIds });
  }

  getClocks(buyerId: number, auctionClusterId: number, catalogId: number): Observable<Array<BuyerClock>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/clock`);
  }

  setPrebid(buyerId: number, auctionClusterId: number, prebid: BuyerActionOnLotGroup): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  editPrebid(buyerId: number, auctionClusterId: number, prebid: BuyerActionOnLotGroup): Observable<any> {
    return this.webApiService.editSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  deletePrebid(buyerId: number, auctionClusterId: number, prebid: BuyerActionOnLotGroup): Observable<any> {
    return this.webApiService.deleteSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  getSubbuyers(buyerId: number, auctionClusterId: number): Observable<Array<BuyerLevelSubbuyer>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/subbuyerbuyer`);
  }

  saveMark(buyerId: number, prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/lot/mark`, prebid);
  }

  deleteMark(buyerId: number,prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.deleteSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/lot/mark`, prebid);
  }
}
