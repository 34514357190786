import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ReportDownloadComponent } from "../../shared/components/reporting/report-download.component";
import { ApplicationSettings } from "../../shared/models/application-settings";
import { AuthService } from "../../shared/services/auth.service";
import { ErrorService } from "../../shared/services/error.service";
import { TitleService } from "../../shared/services/title.service";
import { WebApiService } from "../../shared/services/web-api.service";

@Component({
  selector: 'report-export-component',
  templateUrl: 'report-export.component.html',
  styleUrls: ['report-export.component.scss']
})
export class ReportExportComponent extends ReportDownloadComponent implements OnInit {
  
  constructor(
    translate: TranslateService,
    titleService: TitleService,    
    route: ActivatedRoute,
    appSettings: ApplicationSettings,
    webApiService: WebApiService,
    errorService: ErrorService,
    authenticationService: AuthService) {
    super(translate, titleService, route, appSettings, webApiService, errorService, authenticationService)
    route.params.subscribe(val => {
      this.init();
    });
  }
  
    init() {
      super.init();
    }
}
