import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomAdminSettings } from '../models/custom-admin-settings';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class CustomAdminService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'customadmin';
  }

  getSettings(): Observable<CustomAdminSettings> {
    const url = this.apiPath + '/settings';
    return this.webApiService.getSingle(url);
  }
}
