import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { TicketPrinterComponent } from './ticket-printer.component';

// services
import { TicketPrinterService } from '../shared/services/ticket-printer.service';
import { ClockService } from '../../shared/services/clock.service';
import { LocationService } from '../shared/services/location.service';
import { CatalogService } from '../shared/services/catalog.service';

// models
import { Language } from '../../shared/models/language';
import { PrinterStatus, TicketPrinter } from '../shared/models/ticket-printer';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Clock } from '../../shared/models/clock';
import { Catalog } from '../shared/models/catalog';
import { Location } from '../shared/models/location';

@Component({
  selector: 'ticket-printers-component',
  templateUrl: 'ticket-printers.component.html',
  styleUrls: ['./ticket-printers.component.scss']
})
export class TicketPrintersComponent extends FullListComponent<TicketPrinter, TicketPrinterComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: TicketPrinterComponent;

  languages: Array<Language> = [];
  locations: Array<Location> = [];
  clocks: Array<Clock> = [];
  catalogs: Array<Catalog> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  ticketTypes = [];
  printerStatussen = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: TicketPrinterService,
    private clockService: ClockService,
    private locationService: LocationService,
    private catalogService: CatalogService,
  ) {
    super(injector, TicketPrinter);
    this.title.set('TICKET_PRINTER.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.translate.get(['SUPPLY_TICKET', 'TRANSACTION_TICKET']).subscribe((res: string) => {
      this.ticketTypes = [
        { value: 1, name: res['SUPPLY_TICKET'] },
        { value: 2, name: res['TRANSACTION_TICKET'] }
      ];
    });
    this.translate.get([
        'TICKET_PRINTER.DISCONNECTED',
        'TICKET_PRINTER.CONNECTED',
        'TICKET_PRINTER.UNKNOWN',
        'TICKET_PRINTER.OUTOFPAPER',
        'TICKET_PRINTER.PRINTHEADOPEN',
        'TICKET_PRINTER.PRINTHEADERROR',
        'TICKET_PRINTER.SENSORERROR',
        'TICKET_PRINTER.CUTTERERROR',
        'TICKET_PRINTER.OUTOFMEMORY',
        'TICKET_PRINTER.NETWORKERROR',
        'TICKET_PRINTER.RIBBONERROR',
        'TICKET_PRINTER.RESTART'
      ]).subscribe((res: string) => {
      this.printerStatussen = [
        { value: 0, name: res['TICKET_PRINTER.DISCONNECTED'] },
        { value: 1, name: res['TICKET_PRINTER.CONNECTED'] },
        { value: 2, name: res['TICKET_PRINTER.UNKNOWN'] },
        { value: 3, name: res['TICKET_PRINTER.OUTOFPAPER'] },
        { value: 4, name: res['TICKET_PRINTER.PRINTHEADOPEN'] },
        { value: 5, name: res['TICKET_PRINTER.PRINTHEADERROR'] },
        { value: 6, name: res['TICKET_PRINTER.SENSORERROR'] },
        { value: 7, name: res['TICKET_PRINTER.CUTTERERROR'] },
        { value: 8, name: res['TICKET_PRINTER.OUTOFMEMORY'] },
        { value: 9, name: res['TICKET_PRINTER.NETWORKERROR'] },
        { value: 10, name: res['TICKET_PRINTER.RIBBONERROR'] },
        { value: 11, name: res['TICKET_PRINTER.RESTART'] }
      ];
    });
  }

  ngOnInit() {
    this.setTranslations("TICKET_PRINTER");
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getTicketPrinters(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.clockService.getAllClusterClocks(this.id),
      this.locationService.getLocations(this.id),
      this.catalogService.getAllSupplyAndForecastCatalogs(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.items = this.items.sort((a, b)=> (a.name> b.name) ? 1 : ((b.name> a.name) ? -1 : 0));
      this.languages = result[1];
      this.clocks = result[2];
      this.locations = result[3];
      this.catalogs = result[4];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    const ticketPrinterId = e.row !== undefined ? e.row.data.ticketPrinterId : e.data.ticketPrinterId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, ticketPrinterId, this.clocks, this.catalogs, this.locations);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.clocks, this.catalogs, this.locations);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<TicketPrinter>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const maskName = new ImportField('TicketPrinterName', this.translations.NAME);
    maskName.required = true;

    schema.fields.push(maskName);

    this.schema = schema;
  }

  translateField = (item: any) => {
    if (item && this.isJson(item.value)) {
      const label = this.getTranslation(item.value);
      return label;
    } else {
      return item.value;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  isJson(value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }
    if (typeof value === 'object' && value !== null) {
      return true;
    }
    return false;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.ticketPrinterId;
    this.confirmation.opened = true;
  }

  translateStatus = (status: any) => {
    if (status || status == 0) {
      const label = this.printerStatussen[status].name;
      return label;
    }
  }

  isDisconnected(status) {
    return status == PrinterStatus.DISCONNECTED;
  }

  isConnected(status) {
    return status == PrinterStatus.CONNECTED;
  }

  isError(status) {
    return status > PrinterStatus.CONNECTED;
  }
}
