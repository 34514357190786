<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}"
               icon="add"
               (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
               icon="print"
               (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #auctionClusterGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onRowClick)="edit($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_auctionclusters"></dxo-state-storing>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataField="image" caption="{{ 'AUCTIONS_CLUSTERS.LOGO' | translate }}" cellTemplate="cellImageTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'cellImageTemplate'">
      <img src="{{cell.value}}" />
    </div>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" class="no-print">
      <dxi-button hint="{{ 'SHARED.GO_TO_USER_OVERVIEW_FOR_THIS_CLUSTER' | translate }}" icon="user" [onClick]="navigateToUsers"></dxi-button>
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button hint="{{ 'AUCTIONS_CLUSTERS.DEACTIVATE_CLUSTER' | translate }}" icon="remove" [onClick]="enableDisableCluster" [visible]="calculateDisabledDisplayValue"></dxi-button>
      <dxi-button hint="{{ 'AUCTIONS_CLUSTERS.ACTIVATE_CLUSTER' | translate }}" icon="check" [onClick]="enableDisableCluster" [visible]="calculateDisabledDisplayValue"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
      <dxi-button hint="{{ 'SHARED.EXPORT' | translate }}" icon="export" [onClick]="exportCluster"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<auction-cluster-component #details
                           (onClosed)="onDetailsComponentClosed($event)"></auction-cluster-component>
<confirmation-component #confirmation title="{{ 'AUCTIONS_CLUSTERS.DELETE_CLUSTER' | translate }}"
                        message="{{ 'AUCTIONS_CLUSTERS.DELETE_CLUSTER_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
