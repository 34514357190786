import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { ContentComponent } from '../../shared/content/content.component';
import { AuctionClusterService } from '../../platform/shared/services/auction-cluster.service';
import { LanguageService } from '../../shared/services/language.service';
import { Subscription } from 'rxjs';

import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

@Component({
  selector: 'platform-content-component',
  templateUrl: './platform-content.component.html',
  styleUrls: ['./platform-content.component.scss']
})

export class PlatformContentComponent extends ContentComponent implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    private auctionClusterService: AuctionClusterService,
    languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  sidebarLogo = null;

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.auctionClusterService.getAuctionClusters().subscribe(acs => {
      if (acs && acs.length > 0) {
        localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }

      const acImage = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');

      if (acImage) {
        this.sidebarLogo = acImage;
      }
    });

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.PLATFORM) {
                if (item.submenuId !== null) {
                  this.subMenuItems.push(item);
                } else this.menuItems.push(item);
              }
            })
          }
        })

        this.menuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })

        this.subMenuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
