import { Injectable, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ShoppingList } from '../models/shopping-list';
import { Lot } from '../../../auction/shared/models/lot';
import { ConfigService } from '../../../shared/services/config.service';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../../../shared/constants/cookies';
import { UserType } from '../../../shared/models/clock';
import { TokenService } from '../../../shared/services/token.service';

// signalr
import { HubConnection, HubConnectionBuilder, LogLevel, JsonHubProtocol, HttpTransportType } from '@aspnet/signalr';
import { ShoppingListUpdated } from '../models/shopping-list-updated';

@Injectable()
export class ShoppingListsService {

  private apiPath: string;
  private hubConnection: HubConnection;
  private reconnectionTimeoutHandler = null;
  private isAutheticated = false;
  private buyerId = 0;

  @Output('shoppingListUpdated') shoppingListUpdated: EventEmitter<ShoppingListUpdated> = new EventEmitter();

  constructor(
    private appSettings: ApplicationSettings,
    private webApiService: WebApiService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private tokenService: TokenService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  init(buyerId: number) {

    this.buyerId = buyerId;

    this.webApiService.getSingle(`${this.getUrlWithoutTrailingSlash(this.appSettings.clockURL)}/aucxis.config.json?d=${Date.now()}`).subscribe(result => {
      const socketUrl = this.configService.getClockServiceUrl(result);
      // SignalR connection
      this.createHubConnection(socketUrl);
      this.startConnection();
    });
  }

  stop() {
    if (this.hubConnection) {
      clearTimeout(this.reconnectionTimeoutHandler);
      this.hubConnection.onclose(null);
      this.hubConnection.stop();
    }
  }

  getShoppingLists(buyerId: number): Observable<Array<ShoppingList>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/shoppinglist');
  }

  getShoppingList(buyerId: number, shoppingListId: number): Observable<ShoppingList> {
    return this.webApiService.getSingle(this.apiPath + '/' + buyerId + '/shoppinglist/' + shoppingListId);
  }

  save(buyerId: number, shoppingList: ShoppingList): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/shoppinglist';
    return this.webApiService.save(url, shoppingList);
  }

  edit(buyerId: number, shoppingList: ShoppingList): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/shoppinglist';
    return this.webApiService.editSingle(url, shoppingList);
  }

  delete(shoppingListId: number, buyerId: number): Observable<any> {
    const url = this.apiPath + '/' + buyerId + '/shoppinglist';
    return this.webApiService.delete(url, shoppingListId);
  }

  getLots(buyerId: number, auctionClusterId: number, shoppingListId: number) {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/shoppinglist/${shoppingListId}/lot`);
  }

  // getShoppingListLots(buyerId: number, auctionClusterId: number, productId: number) {
  //   return this.webApiService
  //   .getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/product/${productId}/lot`);
  // }

  getLot(buyerId: number, auctionClusterId: number, lotId: number): Observable<Lot> {
    return this.webApiService.getSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/shoppinglist/lot/${lotId}`);
  }

  saveLot(buyerId: number, auctionClusterId: number, lot: Lot): Observable<any> {
    const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/shoppinglist/lot`;
    return this.webApiService.save(url, lot);
  }

  // saveLotForProduct(buyerId: number, auctionClusterId: number, productId, lot: Lot): Observable<any> {
  //   const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/prebidonproduct/product/${productId}/lot`;
  //   return this.webApiService.save(url, lot);
  // }

  editLot(buyerId: number, auctionClusterId: number, lot: Lot): Observable<any> {
    const url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/shoppinglist/lot`;
    return this.webApiService.editSingle(url, lot);
  }

  deleteLot(buyerId: number, auctionClusterId: number, lotId: number): Observable<any> {
    return this.webApiService.delete(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/shoppinglist/lot`, lotId);
  }

  // Strips trailing slash "/" from given url
  private getUrlWithoutTrailingSlash(url: string) {
    return url.replace(/\/+$/, "");
  }

  // Create and build Hub connection
  private createHubConnection(socketUrl: string) {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const url = `${socketUrl}auction?userId=${userId}&type=${UserType.ADMINISTRATOR}&clockId=0`;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, { transport: HttpTransportType.WebSockets, skipNegotiation: true, })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .build();

    this.hubConnection.onclose(() => {
      this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(), 5000);
      console.log('Reconnecting in 5 sec...');
    });

    // Register method handlers
    this.hubConnection.on('UserAuthorizationResponse', (response: any) => {
      this.isAutheticated = response.isAuthenticationSuccess;
      this.hubConnection.send('ActorMessage', 'RegisterAdministratorForShoppingList', { buyerId: this.buyerId });
    });
    this.hubConnection.on('ShoppingListUpdated', (response: ShoppingListUpdated) => { this.shoppingListUpdated.emit(response) });
  }

  // Start SignalR connection
  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        // Authenticate after connection is established
        this.authenticate();
      })
      .catch(error => {
        console.log('Error while establishing SignalR connection');
        console.log('Reconnecting in 5 sec...');
        this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(), 5000);
      });
  }

  private authenticate() {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const token = this.tokenService.getToken();

    // Request auth from engine
    this.hubConnection.send('ActorMessage', 'UserAuthorizationRequest', { userId, token });
  }
}
