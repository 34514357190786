import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Catalog } from '../../../auction/shared/models/catalog';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ICatalogService } from '../../../shared/interfaces/catalog';

@Injectable()
export class CatalogService implements ICatalogService {

  private apiPath: string;
  private supplierId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  setId(id: number){
    this.supplierId = id;
  }

  getAllSupplyCatalogs(supplierId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${supplierId}` + '/auctioncluster/auction/catalog');
  }

  getAuctionCatalogs(auctionId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/auction/' + auctionId + '/catalog');
  }

  getAllSupplyAndForecastCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }

  //ICatalogService
  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }
}
