import { Clock } from '../../../shared/models/clock';

export class AuctionClusterBuyer {
  buyerId: number;
  name: string;
  buyerNumber: string;
  buyerNumberNumeric: number; // helperfield
  creditLimit: number;
  backOfficeCreditSpent: number;
  isActive: boolean;
  isActiveOnAuctionCluster: boolean;
  usedOnOtherAuctionCluster: boolean;
  blockedOnCreditLimit = false;
  disableLimitCheck = false;
  currencyId: number;
  accessToAllAuctionsAndClocks: boolean;
  userLicenseCount: number;
  buyerAuctionClocks: Array<AuctionClockPair> = [];
  subbuyers: Array<SubBuyer> = [];
  buyerSecondRestrictionMasterData: Array<BuyerSecondRestrictionMasterData> = [];

  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  vat: string;
  telNumber: string;
  faxNumber: string;
  email: string;
  url: string;
  administratorId: number;
  isPending: boolean;

  // displayable fields
  clocks: string;
}

export class AuctionClockPair {
  auctionId = 0;
  clockId = 0;
  blockedForBuying = false;

  filteredClocks: Array<Clock> = [];
}

export class BuyerSecondRestrictionMasterData {
  buyerId = 0;
  auctionClusterId = 0;
  masterDataListRowId = 0;
  blocked = false;
}

export class SubBuyerSelection {
  buyerId: number;
  orderNumber: number;
}

export class SubBuyer {
  buyerId: number;
  name: string;
  orderNumber: number;
  buyerSubBuyerOverrule: boolean;
}

export class BuyerLevelSubbuyer {
  buyerId: number;
  name: string;
  subNumber: number;
}

export class BuyerWithSubbuyers {
  buyerId: number;
  name: string;
  subbuyers: Array<BuyerLevelSubbuyer> = [];
}

export class BuyerSimple {
  buyerId: number;
  name: string;
  vat: string;
}
