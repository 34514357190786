import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { Subscription } from 'rxjs';

// services
import { ProductPropertyStylingService } from '../../shared/services/product-property-styling.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { ProductPropertyStyling } from '../../shared/models/product-property-styling';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'product-property-styling-component',
  templateUrl: 'product-property-styling.component.html',
  styleUrls: ['./product-property-styling.component.scss']
})
export class ProductPropertyStylingComponent extends ItemDetailsComponent<ProductPropertyStyling> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: ProductPropertyStylingService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new ProductPropertyStyling();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(productPropertyStylings: Array<ProductPropertyStyling>, id: number) {
    this.allItems = productPropertyStylings;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getProductPropertyStyling(id)
        .subscribe((res: ProductPropertyStyling) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.model = new ProductPropertyStyling();
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnProductPropertyStylingSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnProductPropertyStylingSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnProductPropertyStylingSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnProductPropertyStylingSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
