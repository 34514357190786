import { UserAuctionClusterRole, UserBuyerRole, UserSupplierRole } from '../../../platform/shared/models/user';

export class BuyerUser {
  userId: number;
  email: string;
  firstname: string;
  lastname: string;
  isActive: boolean;
  languageId: number;
  buyerRoles: Array<UserBuyerRole> = [];
  isPending: boolean;
  existingBuyerVat: string;
  telNumber: string;

  // displayable fields:
  rolesString: string;
}
