import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../constants/cookies';
import { UserPermissions } from '../models/user-permissions';

const TOKEN_PARTS = 3;
const DATA_TOKEN_INDEX = 1;
const TOKEN_NORMALIZATION_NUMBER = 4;
const TOKEN_0_INDEX = 0;
const TOKEN_2_INDEX = 2;
const TOKEN_3_INDEX = 3;
const TOKEN_PERMISSIONS_KEY = 'app:permissions';
const TOKEN_DISPLAYNAME_KEY = 'sub';
const TOKEN_USERGUID_KEY = 'app:userguid';
const MAX_COOKIE_SIZE = 4000;

@Injectable()
export class TokenService {

  token: any;

  get displayName() {
    return this.token ? this.token[TOKEN_DISPLAYNAME_KEY] : '';
  }

  private get permissions() {
    return this.token ? this.token[TOKEN_PERMISSIONS_KEY] : null;
  }

  get userGuid() {
    if (this.token == null) {
      this.refreshToken();
    }

    return this.token ? this.token[TOKEN_USERGUID_KEY] : '';
  }

  constructor(private cookies: CookieService) { }

  refreshToken() {
    // try to restore the token from the cookies
    const token = this.getToken();
    if (token) {
      this.extractToken(token);
    }
  }

  getToken() {
    const cookies = this.cookies.getAll(); // tslint:disable-line:no-inferred-empty-object-type
    const properties = Object.getOwnPropertyNames(cookies);
    let cookie = '';
    if (properties.length > 0) {
      properties.forEach(property => {
        if (property.startsWith(Cookies.ACCESS_TOKEN_COOKIE)) {
          cookie += cookies[property];
        }
      });
    }
    return cookie;
  }

  setToken(token: string, cookieExpirationDate: Date = null) {
    this.deleteAllTokenCookies();

    const parts = [];
    for (let i = 0; i < token.length; i += MAX_COOKIE_SIZE) {
      const partEnd = (i + MAX_COOKIE_SIZE > token.length) ? token.length - i : MAX_COOKIE_SIZE;
      const part = token.slice(i, i + partEnd);
      parts.push(part);
    }

    for (let i = 0; i < parts.length; i++) {
      const cookieName = Cookies.ACCESS_TOKEN_COOKIE + (i + 1);

      if (!cookieExpirationDate)
        this.cookies.set(cookieName, parts[i], 1, '/');
      else
        this.cookies.set(cookieName, parts[i], cookieExpirationDate, '/');
    }
  }

  deleteToken() {
    this.deleteAllTokenCookies();
    this.token = null;
  }

  private deleteAllTokenCookies() {
    const cookies = this.cookies.getAll(); // tslint:disable-line:no-inferred-empty-object-type
    const properties = Object.getOwnPropertyNames(cookies);
    if (properties.length > 0) {
      properties.forEach(property => {
        if (property.startsWith(Cookies.ACCESS_TOKEN_COOKIE)) {
          this.cookies.delete(property);
        }
      });
    }
  }

  permissionMet(requiredPermission: string, id: number): boolean {

    if (requiredPermission) {

      if (!this.token) {
        this.refreshToken();
      }

      // if the user has this required permission, then she is granted the content
      if (this.token && this.permissions) {
        const permissionObject = JSON.parse(this.permissions);
        let permissionValue;
        if (id) {
          permissionValue = this.getDeepValueWithId(permissionObject, requiredPermission, id);
        } else {
          permissionValue = this.getDeepValue(permissionObject, requiredPermission);
        }
        return (permissionValue && permissionValue === true);
      } else {
        return false;
      }
    } else {
      // no permissions required for this route
      return true;
    }
  }

  getCurrentUserPermissions(): UserPermissions {

    if (!this.token) {
      this.refreshToken();
    }

    if (this.token && this.permissions) {
      return JSON.parse(this.permissions);
    } else {
      return null;
    }
  }

  private extractToken(token: any) {

        const parts = token.split('.');
        if (parts.length !== TOKEN_PARTS) {
            return false;
        } else {
            const decoded = this.urlBase64Decode(parts[DATA_TOKEN_INDEX]);
            if (decoded) {
                this.token = JSON.parse(decoded);
            } else {
                return false;
            }
        }
  }

  private urlBase64Decode(str: string) {

      let output = str.replace(/-/g, '+').replace(/_/g, '/');

      switch (output.length % TOKEN_NORMALIZATION_NUMBER) {
          case TOKEN_0_INDEX: { break; }
          case TOKEN_2_INDEX: { output += '=='; break; }
          case TOKEN_3_INDEX: { output += '='; break; }
          default: {
              return null;
          }
      }

      return decodeURIComponent(window.atob(output));
  }

  public getDeepValue(object: any, path: string) {

      const [level, permission] = path.split('.');
      const permissionValue = Number(permission);
      if (object.hasOwnProperty(level)) {
          const levelObject = object[level];
          if (levelObject.length > 0 && levelObject.indexOf(permissionValue) > -1) {
              return true;
          } else {
              return false;
          }
      } else {
          return false;
      }
  }

  public getDeebValuesWithIds(object: any, path: Array<string>, id: number) {
    let hasPermission = false;

    for (let i = 0; i < path.length; i ++) {
      hasPermission = this.getDeepValueWithId(object, path[i], id) || hasPermission;

      if (hasPermission === true) {
        return true;
      }
    }

    return hasPermission;
  }

  public getDeepValueWithId(object: any, path: string, id: number) {
    if (object.AuctionAuctionClusters.length > 0 ) {
      let hasPermission = false;
      const acIds = [id];
      object.AuctionAuctionClusters.forEach(ac => {
        if (ac.Value === id) {
          acIds.push(ac.Key);
        }
      });
      if (acIds.length > 0) {
        for (let i = 0; i < acIds.length; i ++) {
          hasPermission = this.checkSinglePermissionWithId(object, path, acIds[i]) || hasPermission;
          if (hasPermission) {
            return true;
          }
        }

        return hasPermission;
      } else {
        return this.checkSinglePermissionWithId(object, path, id);
      }
    } else {
      return this.checkSinglePermissionWithId(object, path, id);
    }
  }

  public checkSinglePermissionWithId(object: any, path: string, id: number) {
      const [level, permission] = path.split('.');
      const permissionValue = Number(permission);
      if (object.hasOwnProperty(level)) {
          const levelObject = object[level];
          if (levelObject.length > 0) {
            return levelObject.filter(f => f.Key === id && (f.Value === permissionValue || f.Value.some(value => value === permissionValue))).length > 0;
          } else {
              return false;
          }
      } else {
          return false;
      }
  }

  private isLevelUser(level: Array<any>): boolean {
    return level.length > 0;
  }

  public isPlatformUser() { return this.isLevelUser(this.getCurrentUserPermissions().PlatformPermissions); }

  public isAuctionClusterUser() {
    return this.isLevelUser(this.getCurrentUserPermissions().AuctionClusterPermissions)
        || this.isLevelUser(this.getCurrentUserPermissions().AuctionPermissions);
  }

  public isBuyerUser() { return this.isLevelUser(this.getCurrentUserPermissions().BuyerPermissions); }

  public isSupplierUser() { return this.isLevelUser(this.getCurrentUserPermissions().SupplierPermissions); }
}
