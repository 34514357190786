import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { catchError, from, Observable, throwError } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
        private tokenService: TokenService
    ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if token in cookie and permissions are satisfactory
        const permission = route.data['permission'];

        if (!permission) {
            return true;
        }

        if (typeof(permission) === 'string') {
            return this.checkPermission(permission, route.params, state.url);
        } else if (permission instanceof Array) {
            let hasAccess = false;
            for (let i = 0; i < permission.length; i += 1) {
                if (permission[i] && this.tokenService.permissionMet(permission[i], +route.params.id)) {
                    hasAccess = true;
                    return this.checkPermission(permission[i], route.params, state.url);
                }
            }
            return this.checkPermission(permission[0], route.params, state.url);
        }

        return false;
    }


    private checkPermission(permission: any, params, url: string) {
        let token = this.tokenService.getToken();
        if (token) {
    
            if (permission && !this.tokenService.permissionMet(permission, +params.id)) {
                
                this.authService.logout();
                return false;
            } else {
                return true;
            }

        } else {
            this.authService.redirectUrl = url;
            this.authService.goToStartPage('/core/login');
            return false;
        }
    }
}
