import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { LookupTable, LookupTableField } from '../../../shared/models/lookup-table';
import { Lot } from '../../../shared/models/lot';
import { ILookupTableService } from '../../../shared/interfaces/lookup-table';

@Injectable()
export class LookupTableService implements ILookupTableService {

  lookupTables: Array<LookupTable> = [];
  lookupTableFields: Array<LookupTableField> = [];

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  setId(id: number){}
  
  getLookupTableForProductProperty(productPropertyId: number, lot: Lot): Observable<LookupTable> {
    const url = this.apiPath + '/auctioncluster/lookuptable/productproperty/' + productPropertyId;
    return this.webApiService.save(url, lot);
  }

  getLookupTables(productId: number): Observable<Array<LookupTable>> {
    const url = this.apiPath +  '/auctioncluster/product/' + productId + '/lookuptable';
    return this.webApiService.getList(url);
  }

  //ILookupService

  getLookupTablesForReports(auctionClusterId: number, productId: number): Observable<Array<LookupTable>> {
    const url = this.apiPath +  '/auctioncluster/product/' + productId + '/lookuptable';
    return this.webApiService.getList(url);
  }
  getLookupTableForProductPropertyForReports(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<LookupTable> {
    const url = this.apiPath + '/auctioncluster/lookuptable/productproperty/' + productPropertyId;
    return this.webApiService.save(url, lot);
  }
}
