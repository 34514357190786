import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Zone } from '../models/zone';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class ZoneService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getZones(auctionClusterId: number): Observable<Array<Zone>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/location/zone');
  }

  getZone(auctionClusterId: number, zoneId: number): Observable<Zone> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/location/zone', zoneId);
  }

  getLocationZones(auctionClusterId: number, locationId: number): Observable<Array<Zone>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/location/' + locationId + '/zone');
  }

  save(auctionClusterId: number, zone: Zone): Observable<any> {
    return this.webApiService.save<Zone>(this.apiPath + '/' + auctionClusterId + '/location/zone', zone);
  }

  edit(auctionClusterId: number, zone: Zone): Observable<any> {
    return this.webApiService.edit<Zone>(this.apiPath + '/' + auctionClusterId + '/location/zone', zone.zoneId, zone);
  }

  enableDisable(auctionClusterId: number, zone: Zone): Observable<Zone> {
    return this.webApiService.editSingle(this.apiPath + '/' + auctionClusterId + '/location/zone/' + zone.zoneId + (zone.isActive ? '/disable' : '/enable'), null); // tslint:disable-line:max-line-length
  }

  delete(auctionClusterId: number, zoneId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/location/zone', zoneId);
  }
}
