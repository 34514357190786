import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AdditionalMenuItem } from '../models/additional-menu-item';
import { WebApiService } from './web-api.service';
import { ConfigService } from './config.service';

@Injectable()
export class AdditionalMenuItemsService {
  private apiPath: string;

  constructor(private configService: ConfigService, private webApiService: WebApiService) {
    this.configService.load().then(_ => {
      this.apiPath = this.configService.config.adminApi + 'menu-items/public';
    });
  }

  getPublicMenuItems(): Observable<Array<AdditionalMenuItem>> {
    return this.webApiService.getList(this.apiPath);
  }

  getPublicMenuItem(externalAppId: number): Observable<AdditionalMenuItem> {
    return this.webApiService.get(this.apiPath, externalAppId);
  }
}
