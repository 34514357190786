import { Component } from '@angular/core';
import { SpinnerService } from '../../shared/services/spinner.service';

@Component({
    selector: 'spinner-component',
    templateUrl: 'spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

    constructor(public spinner: SpinnerService) {}
}
