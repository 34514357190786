import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';

// general services
import { TitleService } from '../../shared/services/title.service';
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// services

// models
import { SupplierLevelClocks} from '../shared/models/supplier-level-clocks';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { UserType } from '../../shared/models/clock';
import { SupplierProduct } from '../shared/models/supplier-product';
import { ProductService } from '../shared/services/product.service';

@Component({
  selector: 'supplier-clocks.component',
  templateUrl: 'supplier-clocks.component.html',
  styleUrls: ['./supplier-clocks.component.scss']
})
export class SupplierClocksComponent extends ItemListComponent<SupplierLevelClocks> implements OnInit, OnDestroy {

  products: Array<SupplierProduct> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private titleService: TitleService,
    private appSettings: ApplicationSettings,
    private languageService: LanguageService,
    private productService: ProductService
  ) {
    super(injector, SupplierLevelClocks);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.titleService.set('SUPPLIER.SUPPLIER_CLOCK_OVERVIEW');
    this.setTranslations('SUPPLIER');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    this.productService.getProductsClocks(this.id).subscribe(products => {
      this.products = products;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.errorService.show(error);
    });
  }

  startClock = (e: any) => {

    const auctionClusterSupplierBuyerId = e.row !== undefined ? e.row.data.auctionClusterSupplierBuyerId : e.data.auctionClusterSupplierBuyerId;
    const productId = e.row !== undefined ? e.row.data.productId : e.data.productId;
    const isReadOnly = true;

    const product = this.products.find(p => p.productId === productId);

    if (product) {
      let clocksParams = '';
      product.clocks.forEach(c => {
        clocksParams += `clockId=${c.clockId}&`;
      });
      const lang = this.translate.currentLang;
      let url = `${this.appSettings.clockURL}?buyerId=${auctionClusterSupplierBuyerId}&${clocksParams}lang=${lang}&viewType=${UserType.BUYER}`; // tslint:disable-line:max-line-length
      if (isReadOnly) {
        url += '&readOnly=1';
      }

      window.open(url, '_blank');
    }
  }

  translateField = (item) => {
    if (item) {
      const label = this.getTranslation(item.value);
      return label;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  createDataSchema(): void { } // tslint:disable-line:no-empty
}
