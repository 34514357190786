import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { GalleryService } from '../shared/services/gallery.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { GallerySeat } from '../shared/models/gallery-seat';
import { Auction } from '../shared/models/auction';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { BuyerCompareNumber } from '../../shared/models/buyer';

@Component({
  selector: 'gallery-seat-component',
  templateUrl: 'gallery-seat.component.html',
  styleUrls: ['./gallery-seat.component.scss']
})
export class GallerySeatComponent extends ItemDetailsComponent<GallerySeat> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: ConfirmationComponent;

  auctionId: number;
  auctions: Array<Auction> = [];
  allBuyers: Array<AuctionClusterBuyer> = [];
  buyers: Array<AuctionClusterBuyer> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(
    protected injector: Injector,
    private dataService: GalleryService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new GallerySeat();
    this.model.reserved = false;
    this.setTranslations('GALLERY');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(gallerySeats: Array<GallerySeat>, gallerySeatId: number, auctionId: number, auctions: Array<Auction>, buyers: Array<AuctionClusterBuyer>) {
    this.allItems = gallerySeats;
    this.auctionId = auctionId;
    this.auctions = auctions;
    this.allBuyers = buyers;
    this.filterBuyers(auctionId);


    if (gallerySeatId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getGallerySeat(this.auctionId, gallerySeatId)
        .subscribe((res: GallerySeat) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new GallerySeat();
      this.model.reserved = false;
      this.model.auctionId = auctionId;
      this.isEditMode = false;
      this.isOpened = true;
    }
    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {

    // Check for unique auction-buyer-seat combination
    let existingSeat = this.allItems.find(_ => _.seatNumber === this.model.seatNumber &&
      _.gallerySeatId !== this.model.gallerySeatId &&
      _.auctionId === this.model.auctionId);

    if (existingSeat) {
      this.confirmation.opened = true;
      return;
    }

    //this.model.auctionId = this.auctionId;
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new GallerySeat();
          this.close(true);
          //this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new GallerySeat();
          this.close(true);
          //this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  overrideSeat() {
    this.spinner.show();
    this.confirmation.opened = false;

    // Find seat to delete
    let existingSeat = this.allItems.find(_ => _.seatNumber === this.model.seatNumber &&
      _.gallerySeatId !== this.model.gallerySeatId &&
      _.auctionId === this.model.auctionId);

    // Delete seat
    this.dataService.delete(this.model.auctionId, existingSeat.gallerySeatId)
      .subscribe((seats: Array<GallerySeat>) => {
        // Remove deleted seat from existing collection
        this.allItems = this.allItems.filter(_ => _.gallerySeatId !== existingSeat.gallerySeatId);
        this.spinner.hide();

        // Save current seat after deleting previous
        this.save();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  filterBuyers(auctionId: number) {
    this.buyers = this.allBuyers.filter(b => b.accessToAllAuctionsAndClocks || b.buyerAuctionClocks.some(c => c.auctionId == auctionId));
    this.buyers = this.buyers.sort(BuyerCompareNumber);
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      this.filterBuyers(this.model.auctionId);
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnGallerySeatSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnGallerySeatSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnGallerySeatSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
