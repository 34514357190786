import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// services
import { ExternalPlatformService } from '../shared/services/external-platform.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { ExternalPlatform } from '..//shared/models/external-platform';


@Component({
  selector: 'external-platform-component',
  templateUrl: 'external-platform.component.html',
  styleUrls: ['./external-platform.component.scss']
})
export class ExternalPlatformComponent extends ItemDetailsComponent<ExternalPlatform> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: ExternalPlatformService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new ExternalPlatform();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(externalPlatforms: Array<ExternalPlatform>, id: number) {
    this.allItems = externalPlatforms;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getExternalPlatform(id)
        .subscribe((res: ExternalPlatform) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.model = new ExternalPlatform();
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnExternalPlatformSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnExternalPlatformSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnExternalPlatformSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnExternalPlatformSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
