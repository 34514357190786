import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalPlatform } from '../models/external-platform';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class ExternalPlatformService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'externalplatform';
  }

  getExternalPlatforms(): Observable<Array<ExternalPlatform>> {
    return this.webApiService.getList(this.apiPath);
  }

  getExternalPlatform(externalPlatformId: number): Observable<ExternalPlatform> {
    return this.webApiService.get(this.apiPath, externalPlatformId);
  }

  save(externalPlatform: ExternalPlatform): Observable<any> {
    return this.webApiService.save<ExternalPlatform>(this.apiPath, externalPlatform);
  }

  edit(externalPlatform: ExternalPlatform) {
    return this.webApiService.edit<ExternalPlatform>(this.apiPath, externalPlatform.externalPlatformId, externalPlatform);
  }

  delete(externalPlatformId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, externalPlatformId);
  }
}
