import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// general services
import { LanguageService } from '../../shared/services/language.service';
//import { DateTimeService } from '../../shared/services/datetime.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ReportComponent } from './report.component';

// services
import { AuctionClusterReportService } from '../shared/services/report.service';
//import { ProductService } from '../shared/services/product.service';
//import { MasterDataService } from '../shared/services/master-data.service';

// models
import { Language } from '../../shared/models/language';
import { Report, DateRanges } from '../../shared/models/report';
import { Product, ProductPropertyTypes } from '../shared/models/product';
//import { DateTimeTypes } from '../../shared/models/dateTimeTypes';
import { MasterData } from '../shared/models/master-data';

//Everything in comment is to show column 'Filter' where you see the filters when saved a report but still bugs in there
@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss']
})
export class ReportsComponent extends FullListComponent<Report, ReportComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;

  languages: Array<Language> = [];
  products: Array<Product> = [];
  masterDatas: Array<MasterData> = [];
  reports: Array<Report> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: AuctionClusterReportService,
    //private productService: ProductService,
    //private masterDataService: MasterDataService,
    //private dateTimeService: DateTimeService,
    private translateService: TranslateService
  ) {
    super(injector, Report);
  }

  ngOnInit() {
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.title.set('REPORTS.TITLE');
    this.setTranslations('REPORTS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getReports(this.id, this.translateService.currentLang),
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.items = result[0];
      //this.setReportLabels();
      //this.languages = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  // setReportLabels() {
  //   const productIds = [];
  //   const productObservables = [];

  //   this.items.forEach(i => {
  //     if (productIds.indexOf(i.reportDesign.productId) === -1) {
  //       productIds.push(i.reportDesign.productId);
  //       productObservables.push(this.productService.getProduct(this.id, i.reportDesign.productId));
  //     }
  //   });

  //   forkJoin(productObservables).subscribe(products => {
  //     products.forEach((prod: Product) => {
  //       this.products.push(prod);

  //       const mdIds = [];
  //       const mdObservables = [];

  //       prod.reportProperties.forEach(rp => {
  //         const pp = prod.productProperties.find(p => p.productPropertyId === rp.productPropertyId);

  //         if (pp && pp.masterDataListId) {
  //           if (mdIds.indexOf(pp.masterDataListId) === -1) {
  //             mdIds.push(pp.masterDataListId);
  //             mdObservables.push(this.masterDataService.getMasterDataList(pp.masterDataListId, this.id));
  //           }
  //         }
  //       });

  //       forkJoin(mdObservables).subscribe(lists => {
  //         lists.forEach((mdl: MasterData) => {
  //           this.masterDatas.push(mdl);
  //         });


  //         this.items.forEach(item => {
  //           const product = this.products.find(p => p.productId === item.reportDesign.productId);

  //           if (product) {
  //             item.productName = product.name;
  //           }

  //           const filterLabels = [];
  //           const filterData = JSON.parse(item.data);
  //           if (filterData !== null) {
  //             filterData.forEach(fd => {
  //               const rp = product.reportProperties.find(p => p.productPropertyId === fd.productPropertyId);
  //               const pp = product.productProperties.find(p => p.productPropertyId === fd.productPropertyId);
  //               if (rp && pp) {
  //                 const label = this.languageService.getTranslatableText(pp.name);
  //                 let value = '-';

  //                 if (fd.productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
  //                   const md = this.masterDatas.find(m => m.masterDataListId === pp.masterDataListId);

  //                   if (md) {
  //                     const row = md.rows.find(m => m.masterDataListRowId === fd.value);

  //                     if (row) {
  //                       const mdValue = row.values.find(v => v.masterDataListFieldId === rp.masterDataListFieldId);
  //                       if (mdValue) {
  //                         const mdField = md.fields.find(f => f.masterDataListFieldId === rp.masterDataListFieldId);
  //                         if (mdField) {
  //                           switch (mdField.propertyTypeId) {
  //                             case ProductPropertyTypes.NUMBER:
  //                               if (mdValue.intValue !== null) {
  //                                 value = mdValue.intValue.toString();
  //                               }
  //                               break;
  //                             case ProductPropertyTypes.TEXT:
  //                               if (mdValue.stringValue !== null) {
  //                                 let text = mdValue.stringValue;
  //                                 if (mdField.translatable) {
  //                                   text = this.languageService.getTranslatableText(text);
  //                                 }
  //                                 if (text) {
  //                                   value = text;
  //                                 }
  //                               }
  //                               break;
  //                             case ProductPropertyTypes.DECIMAL:
  //                               if (mdValue.decimalValue !== null) {
  //                                 value = mdValue.decimalValue.toString();
  //                               }
  //                               break;
  //                             case ProductPropertyTypes.DATE:
  //                               if (mdValue.dateTimeValue !== null) {
  //                                 value = this.dateTimeService.getDateStringByFormatAnyUtc(mdValue.dateTimeValue, mdField.propertyTypeFormatId);
  //                               }
  //                               break;
  //                             case ProductPropertyTypes.BOOLEAN:
  //                               if (mdValue.booleanValue !== null) {
  //                                 value = `${mdValue.booleanValue ? 'true' : 'false' }`;
  //                               }
  //                               break;
  //                             default: break;
  //                           }
  //                         }
  //                       }
  //                     }
  //                   }
  //                   filterLabels.push(`${label}: ${value}`);
  //                 } else if (fd.productPropertyTypeId === ProductPropertyTypes.DATE) {
  //                   if (fd.dateRangeType !== null) {
  //                     if (fd.dateRangeType === DateRanges.RANGE) {
  //                       const dateStringFrom = this.dateTimeService.getDateStringByFormatAnyUtc(fd.value.split('|')[0], DateTimeTypes.SHORT_DATE);
  //                       const dateStringTo = this.dateTimeService.getDateStringByFormatAnyUtc(fd.value.split('|')[1], DateTimeTypes.SHORT_DATE);

  //                       value = `${dateStringFrom} - ${dateStringTo}`;
  //                     } else {
  //                       value = this.translations[DateRanges[fd.dateRangeType]];
  //                     }
  //                   }
  //                   filterLabels.push(`${label}: ${value}`);
  //                 }
  //               }
  //             });
  //           }
  //           item.filterLabel = filterLabels.join('; ');
  //         });
  //       });
  //     });
  //   });
  // }

  openReport = (e: any) => {
    const reportId = e.row !== undefined ? e.row.data.reportId : e.data.reportId;
    const auctionClusterId = e.row !== undefined ? e.row.data.reportDesign.auctionClusterId : e.data.reportDesign.auctionClusterId;

    this.router.navigate(['/auction/reports/' + auctionClusterId + '/report/' + reportId]);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe(() => {
        this.getData();
        this.spinner.hide();
      },
      () => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }

  deleteButtonVisible = (e: any) => {
    return !e.row.data.systemDefault;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.reportId;
    this.confirmation.opened = true;
  }

  // getPlainTextField = (e: any) => {
  //   return e.valueText !== '' ? e.valueText.replace(/<[^>]*>/g, ''): '';
  // }

  add() { }

  edit(_itemId: number, _event: Event) { }
}