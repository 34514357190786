import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { MasterData, MasterDataListField, MasterDataValue, MasterDataRow, FieldType } from '../../shared/models/master-data';
import { ProductPropertyTypes } from '../models/product';
import { IMasterDataService } from '../../../shared/interfaces/master-data';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class MasterDataService implements IMasterDataService {
  masterDataLists: Array<MasterData> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  cachedLabels = [];

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  setId(){
    
  }

  getMasterDataLists(auctionClusterId: number): Observable<Array<MasterData>> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist';
    return this.webApiService.getList(url);
  }

  getMasterDataListsWithFields(auctionClusterId: number): Observable<Array<MasterData>> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/fields';
    return this.webApiService.getList(url);
  }
  
  getMasterDataList(masterDataListId: number, auctionClusterId: number): Observable<MasterData> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId;
    return this.webApiService.getList(url);
  }

  getMasterDataListValues(masterDataListId: number, auctionClusterId: number): Observable<MasterData> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/masterdatalistvalues';
    return this.webApiService.getList(url);
  }

  getMasterDataListByIds(masterDataListIds: Array<number>, auctionClusterId: number): Observable<Array<MasterData>> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/list';
    return this.webApiService.save(url, masterDataListIds);
  }

  getMasterDataListByIdsTouchOnly(masterDataListIds: Array<number>, auctionClusterId: number): Observable<Array<MasterData>> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/list?touchOnly=true';
    return this.webApiService.save(url, masterDataListIds);
  }

  /**
   * Returns the field definitions of the requested masterdatalist, but not the actual rows
   * @param masterDataListId
   * @param auctionClusterId
   */
  getMasterDataListForEdit(masterDataListId: number, auctionClusterId: number, langCode: string): Observable<MasterData> {
    return this.webApiService.getList(this.apiPath + `/${auctionClusterId}/masterdatalist/${masterDataListId}/edit?langCode=${langCode}`);
  }

  getMasterDataListPage(masterDataListId: number, auctionClusterId: number, from: number, size: number): Observable<MasterData> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/' + from + '/' + size;
    return this.webApiService.getList(url);
  }

  getMasterDataRowById(masterDataListRowId: number, auctionClusterId: number): Observable<MasterDataRow> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/row';
    return this.webApiService.get(url, masterDataListRowId);
  }

  /**
   * Loads the rows of a given list for a given language, with paging/filtering options
   * @param masterDataListId
   * @param auctionClusterId
   * @param language
   * @param loadOptions
   */
  loadMasterDataListPaged(
    masterDataListId: number,
    auctionClusterId: number,
    language: string,
    loadOptions: any) {
      const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/load/';
      let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
      if (language) {
        queryString += `&language=${language}`;
      }

      var params = this.webApiService.getParamsFromLoadOptions(loadOptions);
      let result = this.webApiService.getWithParams(url + queryString, params);
      return result;
  }

  getMasterDataListForDropdown(masterDataListId: number,
    auctionClusterId: number,
    language: string,
    keys: Array<number>,
    fieldIds: Array<number>,
    loadOptions: any) {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/dropdown';
    let queryString = `?language=${language}`;
    if (keys != null && keys.length > 0) {
      queryString += `&keys=${keys.join(',')}`;
    }
    if (fieldIds != null && fieldIds.length > 0) {
      queryString += `&masterdatalistFieldIds=${fieldIds.join(',')}`;
    }

    var params = this.webApiService.getParamsFromLoadOptions(loadOptions);
    let result = this.webApiService.getWithParams(url + queryString, params);
    return result;
  }

  getMasterDataValueForDropdown(masterDataListId: number,
    auctionClusterId: number,
    language: string,
    key: number,
    fieldIds: Array<number>) {
    if (!this.cachedLabels[key]) {
      const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/dropdown';
      let queryString = `?language=${language}&keys=${key}`;
      if (fieldIds != null && fieldIds.length > 0) {
        queryString += `&masterdatalistFieldIds=${fieldIds.join(',')}`;
      }

      let result = this.webApiService.getSingle(url + queryString);
      result.subscribe(l => this.cachedLabels[key] = l.data);
      return result;
    }
    else {
      // return cached data as observable
      return of(
        {
          data: this.cachedLabels[key]
        });
    }
  }

  getLoadURL(
    masterDataListId: number,
    auctionClusterId: number,
    language: string,
  ) {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/load';
    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }

    return url;
  }

  getMasterDataListPageSearchSort(
    masterDataListId: number,
    auctionClusterId: number,
    from: number,
    size: number,
    language: string,
    sort?: any,
    filter?: Array<any>): Observable<MasterData> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId + '/' + from + '/' + size + '/search';

    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }
    if (sort) {
      if (sort.by) {
        queryString += `&sortPropertySelectionId=${sort.by}&sortAscending=${!!sort.reverse}`;
      }
    }
    const selections = [];
    if (filter) {
      filter.forEach(f => {
        selections.push({
          'key': f.property,
          'value': f.value
        });
      });
    }

    return this.webApiService.save(url + queryString, { selections });
  }

  getFieldTypes(auctionClusterId: number): Observable<Array<FieldType>> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/systemfields';
    return this.webApiService.getList(url);
  }

  addMasterDataListRow(masterDataListId: number, masterData: MasterData): MasterDataRow {
    const fields = masterData.fields;
    const values: Array<MasterDataValue> = new Array();

    fields.forEach(field => {
      const value = new MasterDataValue();
      value.dateTimeValue = null;
      value.decimalValue = null;
      value.intValue = null;
      value.masterDataListFieldId = field.masterDataListFieldId;
      value.stringValue = '';
      value.urlValue = '';
      values.push(value);
    });

    const row = new MasterDataRow();
    row.masterDataListRowId = 0;
    row.values = values;

    return row;
  }

  save(masterData: MasterData): Observable<any> {
    const url = this.apiPath + '/' + masterData.auctionClusterId + '/masterdatalist';
    return this.webApiService.save(url, masterData);
  }

  editMasterDataList(masterData: MasterData): Observable<any> {
    const url = this.apiPath + '/' + masterData.auctionClusterId + '/masterdatalist';
    return this.webApiService.edit(url, masterData.masterDataListId, masterData);
  }

  delete(masterDataListId: number, auctionClusterId: number): Observable<any> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist';
    return this.webApiService.delete(url, masterDataListId);
  }

  saveRow(auctionClusterId: number, masterDataRow: MasterDataRow): Observable<MasterDataRow> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/row';
    return this.webApiService.save(url, masterDataRow);
  }

  editRow(auctionClusterId: number, masterDataRow: MasterDataRow): Observable<MasterDataRow> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/row';
    return this.webApiService.editSingle(url, masterDataRow);
  }

  deleteRow(auctionClusterId: number, masterDataRowId: number): Observable<any> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/row';
    return this.webApiService.delete(url, masterDataRowId);
  }

  getMasterDataValues(propertyTypeId: number, object: MasterDataValue): any {
    let value: any;

    switch (propertyTypeId) {
      case ProductPropertyTypes.NUMBER: value = object.intValue; break;
      case ProductPropertyTypes.DECIMAL: value = object.decimalValue; break;
      case ProductPropertyTypes.DATE: value = object.dateTimeValue; break;
      case ProductPropertyTypes.TEXT: value = object.stringValue; break;
      case ProductPropertyTypes.BOOLEAN: value = object.booleanValue; break;
      case ProductPropertyTypes.COLOR: value = object.stringValue; break;
      case ProductPropertyTypes.URL: value = object.urlValue; break;
      default: break;
    }
    return value;
  }

  getMasterDataForReports(auctionClusterId: number, masterDataListId: number, includeRows: boolean): Observable<MasterData> {
    if (!includeRows) {
      return this.getMasterDataListForEdit(masterDataListId, auctionClusterId, null);
    }
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/' + masterDataListId;
    return this.webApiService.getList(url);
  }

  addMasterDataImage(auctionClusterId: number, formData: FormData): Observable<string> {
    const url = this.apiPath + '/' + auctionClusterId + '/masterdatalist/uploadimage';
    return this.webApiService.uploadImage(url, formData);
  }
}
