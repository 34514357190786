import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as _moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { DateTimeTypes } from '../models/dateTimeTypes';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable()
export class DateTimeService {

  constructor(private translateService: TranslateService) {
  }

  getDateStringByFormat(dateTime: Moment, format: number) {
    switch (format) {
      case DateTimeTypes.FULL_DATE_TIME: // December 5, 2018 12:34 PM
        return dateTime.format('LLL');
      case DateTimeTypes.LONG_DATE: // December 3, 2018
        return dateTime.format('LL');
      case DateTimeTypes.LONG_TIME: // 11:34:37 AM
        return dateTime.format('LTS');
      case DateTimeTypes.SHORT_DATE: // 12/12/2018
        return dateTime.format('L');
      case DateTimeTypes.SHORT_TIME: // 12:34 PM
        return dateTime.format('LT');
      default:
        return '';
    }
  }

  getDateStringByFormatAny(dateTime: any, format: number) {
    if (!dateTime) {
      return '';
    }
    var lang = this.translateService.currentLang;
    var localDateValue = moment(dateTime).locale(lang).local();
    return this.getDateStringByFormat(localDateValue, format);
  }

  getDateStringByFormatAnyUtc(dateTime: any, format: number) {
    if (!dateTime) {
      return '';
    }

    var lang = this.translateService.currentLang; 
    if(format === DateTimeTypes.LONG_TIME
      || format === DateTimeTypes.SHORT_TIME)
    {
        return this.getDateStringByFormat( moment.utc(dateTime).locale(lang), format);
    }

    
    var localDateValue = moment.utc(dateTime).locale(lang).local();
    return this.getDateStringByFormat(localDateValue, format);
  }

}
