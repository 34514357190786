import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';
import { Version } from '../models/version';
import { environment } from '../../../environments/environment';
import { ConfigService } from './config.service';

@Injectable()
export class VersionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService, private configService: ConfigService) {
    this.apiPath = appSettings.adminApi + 'adminapiversion';
  }

  getVersions(): Observable<Version> {
    return Observable.create(observer => {
      forkJoin(
        // Fetch API versions
        this.webApiService.getList(this.apiPath),
        // Fetch Clock UI config
        this.webApiService.getSingle(this.appSettings.clockURL + `aucxis.config.json?d=${Date.now()}`)
      ).subscribe(res => {
        const version = new Version();
        // Admin API version result
        version.adminAPIVersion = res[0].version;
        // Clock Engine version
        version.clockEngineVersion = res[0].clockEngineVersion;

        // Audio Service Url
        const audioServiceUrl = res[0].audioServiceUrl;

        // Clock UI config result
        const clockUIConfig: any = res[1];

        // Append Admin UI version
        version.adminUIVersion = environment.version;
        // Append Clock UI version to result
        version.clockVersion = clockUIConfig.version;

        // Get Clock Service and Clock Engine URLs
        const clockServiceUrl = this.configService.getClockServiceUrl(clockUIConfig);

        forkJoin(
          // Fetch Clock Service version
          this.webApiService.getSingle(clockServiceUrl + 'api/version'),
          // Get Audio Service version
          this.webApiService.getSingle(audioServiceUrl + 'api/version')
        ).subscribe(result => {
          // Append Clock Service version to result
          version.clockServiceVersion = result[0].version;
          // Append Clock Engine version
          version.audioServiceVersion = result[1].version;

          // Call subscribe/resolve
          observer.next(version);
          // Call complete to show we are done with our processing
          observer.complete();
        }, error => {
          observer.error(error);
        });
      },
        error => {
          observer.error(error);
        });
    });
  }
}
